import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, HomeService,RoleService } from '../_services';
import { Subject, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EmbedVideoService } from 'ngx-embed-video';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userForm: FormGroup;
   id: any;
   disp_type:any;
  el_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob: any;
  userdetail$: any;
  education: any;
  certificates: any;
  achivements: any;
  logo: any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  first_preview: any = [];
   image_preview: [];
  image1:File;
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  $status:any;
  $event:any;
  curPage = 1;
  perPage = 10;
  routeSub: any;
  fileToUpload2:File;
  audio:any;
  profile: any;
  imagearray =[];
  images:File;
  band_preview:any;
  image_band: File;
  dropdownSettings: IDropdownSettings;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings1: IDropdownSettings;
  dropdownList1 = [];
  selectedItems1 = [];
  states: any;
  occupation:any;
  mem_image:any;
  iframe_html1:any;
  iframe_html2:any;
  iframe_html3:any;
  ytube1:any;
  ytube2:any;
  ytube3:any;
 vimeo1:any;
 vimeo2:any;
 vimeo3:any;
 fldset = true;
 fldset1 = true;
 width_limit:any;
 height_limit:any;
 

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private homeService: HomeService,
    private roleSer: RoleService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private embedService: EmbedVideoService,

  ) {}

  
  ngOnInit() {

    this.width_limit =`${environment.imgWidth}`;
      this.height_limit=  `${environment.imgHeight}`;
    // const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w.-]*/?';
   const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'; 
  //  const reg1 = '/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/';
    this.userForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      name: ['',[Validators.required]],
      email: ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      band_name: ['',[Validators.required]],
      userID: ['',[Validators.required]],
      band_introduction:['',[Validators.required]],
      url_youtube_sns: ['',[Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)]],
      state: ['',[Validators.required]],
      affiliation_organization:[''],
      member_name: [''],
      profile1: [''],
      video_audio_url: ['',[Validators.required,Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)]],
      occupation: ['',[Validators.required]],
      band_photo: [''],
      remarks: [''],
      audio_file_duration: [''],
      event_remarks:[''],
      user_source_for_judging: [''],
      event_source_for_judging: [{ value: '', disabled: true },[Validators.required]],
      other_url: [''],
      url_sound1: [{ value: '', disabled: true },[,Validators.required,Validators.pattern(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/)]],
      url_sound2: ['',[Validators.pattern(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/)]],
      url_sound3: ['',[Validators.pattern(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/)]],

    });

    // timer(500).subscribe(tm=>{
      this.dropdownSettings= {
        singleSelection: false,
        idField: '_id',
        textField: 'state',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
  
      this.dropdownSettings1= {
        singleSelection: true,
        idField: '_id',
        textField: 'occupation',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
    // });

    
  
  

    this.roleSer.getState().subscribe(data => {
       
      this.states = data.data.state;
  
  });
  this.roleSer.getOccupation().subscribe(data => {
    this.occupation=data.data.occupation;
  });

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];

      this.roleSer.getSingleUser(this.id).subscribe(data => {
  
        this.users$ = data.data.user;
if(this.users$ && this.users$.status == 1){
  
  this.fldset = false;
}else{
  this.fldset = true;
}

if(this.users$ && this.users$.event_accept == 1){

 
  this.fldset1 = false;
  this.userForm.controls['url_sound1'].enable();
  this.userForm.controls['url_sound2'].enable();
  this.userForm.controls['url_sound3'].enable();
  this.userForm.controls['event_source_for_judging'].enable(); 
  this.userForm.controls['event_remarks'].enable();
}else{
  this.fldset1 = true;
  this.userForm.controls['url_sound1'].disable();
  this.userForm.controls['url_sound2'].disable();
  this.userForm.controls['url_sound3'].disable();
  this.userForm.controls['event_source_for_judging'].disable(); 
  this.userForm.controls['event_remarks'].disable();
}


        this.ytube1 = 'https://img.youtube.com/vi/' + this.users$.youtubeid1 + '/0.jpg';
        console.log(this.ytube1)
        this.ytube2 = 'https://img.youtube.com/vi/' + this.users$.youtubeid2 + '/0.jpg';
        this.ytube3 = 'https://img.youtube.com/vi/' + this.users$.youtubeid3 + '/0.jpg';

        this.vimeo1 = 'https://i.vimeocdn.com/video/' + this.users$.vimeoid1;
        this.vimeo2 = 'https://i.vimeocdn.com/video/' + this.users$.vimeoid2;
        this.vimeo3 = 'https://i.vimeocdn.com/video/' + this.users$.vimeoid3;
this.$status = this.users$.status;
this.$event = this.users$.event_reg;

this.image_band = this.band_preview = this.users$.band_photo ? this.users$.band_photo : '';
    if(this.image_band!=null){
      this.band_preview = this.media_domain + '' +this.users$.band_photo;
     }


        this.userForm.patchValue({
          index: 1,
          _id: this.users$._id,
         name: this.users$.name,
         email: this.users$.email,
         band_name: this.users$.band_name,
         userID: this.users$.userID,
         band_introduction: this.users$.band_introduction,
         url_youtube_sns: this.users$.url_youtube_sns,
         affiliation_organization: this.users$.affiliation_organization,
         member_name: this.users$.member_name,
         remarks: this.users$.remarks,
         video_audio_url: this.users$.video_audio_url,
         event_remarks: this.users$.event_remarks,
         url_sound1: this.users$.url_sound1,
      url_sound2: this.users$.url_sound2,
      url_sound3: this.users$.url_sound3,
      user_source_for_judging:this.users$.user_source_for_judging,
      other_url: this.users$.other_url,
      audio_file_duration: this.users$.audio_file_duration,
      event_source_for_judging: this.users$.event_source_for_judging
        });

        this.selectedItems = this.users$.state;
        this.selectedItems1 = this.users$.occupation;
        // this.iframe_html1 = 'https://img.youtube.com/vi/yfYjEkaN-1s/0.jpg';
        //  this.iframe_html1 = this.embedService.embed(this.users$ && this.users$.url_sound1);
        // this.iframe_html2 = this.embedService.embed(this.users$ && this.users$.url_sound2);
        // this.iframe_html3 = this.embedService.embed(this.users$ && this.users$.url_sound3);

    console.log(this.users$)
      }, error => {
        console.log(error);
      })
    });


    

  }

  valuechange1(e,user){
    e.stopPropagation();
     console.log(user);
    
     var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
     var match = e.target.value.match(regExp);
     var regExp1 = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
     var match1 = e.target.value.match(regExp1);
if(match){
  var youtube = match[1];
}else{
  this.users$.url_sound1 = '';
  this.users$.youtubeid1 = '';
}
if(match1){
  var vimeo = match1[2];
}else{
  this.users$.vimeoid1 = '';
  this.users$.url_sound1 = '';
}


     
if(youtube){
  this.vimeo1 = '';
  this.users$.vimeoid1 = '';
  this.users$.url_sound1 = '';
  this.users$.youtubeid1 = '';
  this.users$.youtubeid1 = youtube;
  
  this.ytube1 = 'https://img.youtube.com/vi/' + youtube + '/0.jpg';
}

if(vimeo){
  this.ytube1 = '';
  this.users$.youtubeid1 = '';
  this.users$.url_sound1 = '';
  this.users$.vimeoid1 = vimeo;
  this.vimeo1 = 'https://i.vimeocdn.com/video/' + vimeo;
}

      
  }

  valuechange2(e,user){
    e.stopPropagation();
    console.log(user);
   
    var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    var match = e.target.value.match(regExp);
    var regExp1 = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    var match1 = e.target.value.match(regExp1);
if(match){
 var youtube = match[1];
}else{
  this.users$.url_sound2 = '';
  this.users$.youtubeid2 = '';
}
if(match1){
 var vimeo = match1[2];
}else{
  this.users$.vimeoid2 = '';
  this.users$.url_sound2 = '';
}
    
if(youtube){
 this.vimeo2 = '';
 this.users$.vimeoid2 = '';
 this.users$.url_sound2 = '';
 this.users$.youtubeid2 = '';
 this.users$.youtubeid2 = youtube;
 
 this.ytube2 = 'https://img.youtube.com/vi/' + youtube + '/0.jpg';
}

if(vimeo){
 this.ytube2 = '';
 this.users$.youtubeid2 = '';
 this.users$.url_sound2 = '';
 this.users$.vimeoid2 = vimeo;
 this.vimeo2 = 'https://i.vimeocdn.com/video/' + vimeo;
}

 
  
}


valuechange3(e,user){
  e.stopPropagation();
  console.log(user);
 
  var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  var match = e.target.value.match(regExp);
  var regExp1 = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  var match1 = e.target.value.match(regExp1);
if(match){
var youtube = match[1];
}else{
  this.users$.url_sound3 = '';
  this.users$.youtubeid3 = '';
}
if(match1){
var vimeo = match1[2];
}else{
  this.users$.vimeoid3 = '';
  this.users$.url_sound3 = '';
}
  
if(youtube){
this.vimeo3 = '';
this.users$.vimeoid3 = '';
this.users$.url_sound3 = '';
this.users$.youtubeid3 = '';
this.users$.youtubeid3 = youtube;

this.ytube3 = 'https://img.youtube.com/vi/' + youtube + '/0.jpg';
}

if(vimeo){
this.ytube3 = '';
this.users$.youtubeid3 = '';
this.users$.url_sound3 = '';
this.users$.vimeoid3 = vimeo;
this.vimeo3 = 'https://i.vimeocdn.com/video/' + vimeo;
}


}


   urlClick(e,user,type){
  console.log(type)
  console.log(e.target)
     if(type == 1 || type == 4){
      
      var data = this.userForm.controls['url_sound1'].value;
      if(data){
        window.open(data);
      }
     else if(user.url_sound1){
        window.open(user.url_sound1,'_blank');
      }
     }

     if(type == 2 || type == 5){
      // window.location.href = user.url_sound2;
      var data = this.userForm.controls['url_sound2'].value;
      if(data){
        window.open(data);
      }
      else if(user.url_sound2){
        window.open(user.url_sound2,'_blank');
      }
     
     }

     if(type == 3 || type == 6){
      // window.location.href = user.url_sound3;

      var data = this.userForm.controls['url_sound3'].value;
      if(data){
        window.open(data);
      }
     else if(user.url_sound3){
        window.open(user.url_sound3,'_blank');
      }
    
     }
     
   }

  clearBand(e){
    e.stopPropagation();
    this.image_band = null;
  }

  clear(e,type,user){
    e.stopPropagation();
 
    var that = this;
    alertify.confirm(that.homeService.getTranslate('user_details.are_you_sure_you_want_to_delete_this_image?'), function () {
      that.roleSer.deleteMember(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success(that.homeService.getTranslate('faq.deleted_successfully'))
            
              that.roleSer.getSingleUser(that.id).subscribe(data => {
  
                that.users$ = data.data.user;
              });
          
           
            } else {
              that.toastr.error(data.status.message)
            
            }
          },
          error => {
            that.toastr.error(error)
           
          });
    }
      , function () {
        // that.toastr.error('Delete action cancelled')
      });
  }

  onItemSelect(e){
  }

  onSelectAll(e){
  }

  onDeSelect(e){}

  onItemSelect1(e){
  }

  onSelectAll1(e){
  }

  onDeSelect1(e){}


  get f() { return this.userForm.controls; }

  onSubmit(e,form, formWrap){
    e.stopPropagation();
    this.submitted = true;
    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;

  
    
    var that = this;
    if (that.userForm.invalid) {
      
      that.toastr.error(that.homeService.getTranslate('faq_form.please_enter_all_fields'))
      return;
    }

  
  
  
    if (!that.image_band) {
      that.toastr.error(that.homeService.getTranslate('user_details.band_photo') + ' ' + that.homeService.getTranslate('user_details.is_required'))
      return false;
    }
    if (id != null) {

      alertify.confirm(that.homeService.getTranslate('user_details.are_you_sure_you_want_to_update_this_user?'), function () {
        that.roleSer.update(id,that.f.name.value,that.f.email.value,that.f.band_name.value,
          that.f.video_audio_url.value,that.f.band_introduction.value,that.f.state.value,that.f.occupation.value,
          that.f.url_youtube_sns.value,that.f.affiliation_organization.value,that.f.member_name.value,
          that.image_band,that.f.userID.value,that.f.remarks.value,that.f.url_sound1.value,that.f.url_sound2.value ? that.f.url_sound2.value : '',
      that.f.url_sound3.value ? that.f.url_sound3.value : '',that.f.user_source_for_judging.value,that.f.event_remarks.value,that.f.event_source_for_judging.value,that.f.other_url.value,that.f.audio_file_duration.value,that.imagearray)
          .subscribe(
            data => {
              if (data.status.code == 0) {
             
                formWrap.reset();
               
              
           
               if(that.$status == 1 && that.$event == 1){
                that.router.navigate(['/users']);
               }else{
                that.router.navigate(['/user-verification']);
               }
            
  
              } else {
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
                that.toastr.error(data.status.message)
           
              }
            },
            error => {
              that.toastr.error(error);
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { 
         //   that.toastr.error(that.homeService.getTranslate('news_form.update')+' '+ that.homeService.getTranslate('news_form.action_cancelled'))
          
          
          }).set('labels', { cancel: that.homeService.getTranslate('faq.cancel'), ok: that.homeService.getTranslate('faq.ok') });
      }
  }

  // fileChooseBand(e) {
    
    
  //   var reader = new FileReader();
  //   reader.onload = (e) => {
  
   
  //       this.band_preview = reader.result;
   
  //   };

  //   reader.readAsDataURL(e.target.files[0]);

   
  //     this.image_band = e.target.files[0];
  
  // }

  fileChooseBand(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();
   
    reader.onload = function (e) {
      var img = new Image();
      img.onload = function () {
        console.log(img.width);

        var imgWidth = that.width_limit;
        var imgHeight = that.height_limit;
        if (img.width == imgWidth && img.height == imgHeight) {
         

          that.band_preview=null;
          that.band_preview=img.src;
          that.image_band = files.item(0);
       //   that.band_preview = files.item(0);
        } else {
        
          that.toastr.error(
            "写真サイズは" + that.width_limit + "×" + that.height_limit+"でお願いします。"
          );
          that.band_preview=null;
         that.band_preview="";
         that.image_band = null;
        
          $("#exampleInputEmail").val("");
         
        
        }
      };
      img.src = reader.result as string;
      that.band_preview = files.item(0);
    };

    reader.readAsDataURL(files.item(0));
    that.image_band = files.item(0);
  }

  vewBtn(e,user){
    
    e.stopPropagation();
    $('#user-republic-mdl').modal('show');
    return false;
  }

  closeModal(e){
    e.stopPropagation();
    $('#user-republic-mdl').modal('hide');
  }

  vewBtn1(e,user){
 e.stopPropagation();
    
    $('#user-republic-mdl1').modal('show');
    this.mem_image = this.media_domain + '' +user.profile;

  }

  closeModal1(e){
    e.stopPropagation();
    $('#user-republic-mdl1').modal('hide');
  }


  changeEvent(e,user,type){
    e.stopPropagation();
    var txt = '';
    var that = this;
      if(type == 1){
        that.disp_type = 1;
      
        txt = that.homeService.getTranslate('user_details.are_you_sure_you_want_to_accept_this_event_request?');

       // this.event_reg=1;
       that.userForm.controls['url_sound1'].enable();
       that.userForm.controls['url_sound2'].enable();
       that.userForm.controls['url_sound3'].enable();
       that.userForm.controls['event_source_for_judging'].enable(); 
       that.userForm.controls['event_remarks'].enable();
      }else if(type == -1){
        that.disp_type = -1;
        txt = that.homeService.getTranslate('user_details.are_you_sure_you_want_to_reject_this_event_request?');
        that.userForm.controls['url_sound1'].disable();
        that.userForm.controls['url_sound2'].disable();
        that.userForm.controls['url_sound3'].disable();
        that.userForm.controls['event_source_for_judging'].disable(); 
        that.userForm.controls['event_remarks'].disable();
      }
  
       
      alertify.confirm(txt, function () {
        that.roleSer.event_update(user._id,that.disp_type)
          .subscribe(
            data => {
              if (data.status.code == 0) {
                that.roleSer.getSingleUser(user._id).subscribe(data => {
  
                  that.users$ = data.data.user;
                });
                location.reload();
                if(type == 1){
                 // that.toastr.success('Accepted Successfully');
                  that.roleSer.geteventMail(user._id).subscribe(data=>{
                   
                    if (data.status.code == 0) {
                  //    that.toastr.success('Accepted mail sent to user');
                    }
                  });
                }else{
                //  that.toastr.success('Rejected Successfully');
                  that.roleSer.geteventMail(user._id).subscribe(data=>{
                    if (data.status.code == 0) {
                  //    that.toastr.success('Rejected mail sent to user');
                    }
                  });
                }
      
                that.roleSer.getSingleUser(user._id).subscribe(data => {

                  that.users$ = data.data.user;
               
                  
            }, error => {
              console.error(error);
            });
             
              } else {
                that.toastr.error(data.status.message)
              
              }
            },
            error => {
              that.toastr.error(error)
             
            });
      }
        , function () {
        
        }).set('labels', { cancel: that.homeService.getTranslate('faq.cancel'), ok: that.homeService.getTranslate('faq.ok') });
    }
    uploadImage2(){
      $('#fileinput2').trigger('click'); 
    }
    deleteFile2(){
      var that = this;
      alertify.confirm('Delete Audio','Are you sure want to delete this record', function () {
    that.fileToUpload2 = null;
    $('#fileinput2').val('');
    $('#fileaudioupload').attr('src','');
     }, function () { //alertify.error('Delete action cancelled')   
    });
  }


    handleFileInput2(event: any, files: FileList){

      var that = this;
     var reader = new FileReader();
       if(files.item(0).size >=  4*1024*1024){
        $("#fileinput2").val(null);
         that.toastr.error('Audio file should be less than or equal to 4MB');
         return false;
     }
    console.log(reader)
      reader.onload = function (e) {
       var img = new Image;
       img.onload = function () {
       // console.log(img.width)
           that.audio = null;
           that.audio = img.src;
           that.fileToUpload2 = files.item(0);
          
        console.log(that.fileToUpload2)
        }
        
       };
      // console.log(that.audio)
       // img.src = reader.result as string; 
       that.fileToUpload2 = files.item(0);
     }

  changeStatus(e,user,type){
    e.stopPropagation();
    var txt = '';
    if(type == 1){
      this.disp_type = 1;
      var that = this;
      txt = that.homeService.getTranslate('user_details.are_you_sure_you_want_to_accept_this_user_request?');
    }else if(type == -1){
      this.disp_type = -1;
      var that = this;
        txt = that.homeService.getTranslate('user_details.are_you_sure_you_want_to_reject_this_user_request?');
    }
  
        var that = this;
      alertify.confirm(txt, function () {
        that.roleSer.status_update(user._id,that.disp_type)
          .subscribe(
            data => {
              if (data.status.code == 0) {
                that.roleSer.getSingleUser(user._id).subscribe(data => {
  
                  that.users$ = data.data.user;
                });
                location.reload();
           //     that.toastr.success('Updated Successfully')
                if(type == 1){
              //    that.toastr.success('Accepted Successfully');
                  that.roleSer.getMail(user._id).subscribe(data=>{
                   
                    if (data.status.code == 0) {
                   //   that.toastr.success('Accepted mail sent to user');
                    }
                  });
                }else{
                 // that.toastr.success('Rejected Successfully');
                  that.roleSer.getMail(user._id).subscribe(data=>{
                    if (data.status.code == 0) {
                //      that.toastr.success('Rejected mail sent to user');
                    }
                  });
                }
                that.roleSer.getSingleUser(user._id).subscribe(data => {
   
                  that.users$ = data.data.user;
                 
                  that.dtTrigger.next();
            }, error => {
              console.error(error);
            });
             
              } else {
                that.toastr.error(data.status.message)
              
              }
            },
            error => {
              that.toastr.error(error)
             
            });
      }
        , function () {
        
        }).set('labels', { cancel: that.homeService.getTranslate('faq.cancel'), ok: that.homeService.getTranslate('faq.ok') });
    }
    cancel(){
      this.router.navigate(['/user-verification']);
    }

    fileChoose(e, type) {
      e.stopPropagation();
    
      var reader = new FileReader();
      reader.onload = (e) => {
    
        if (type == 1) {
       //   this.first_preview = reader.result;
          var data = reader.result;
          this.first_preview.push(data);
         console.log(this.first_preview)
        } 
      };
  
      reader.readAsDataURL(e.target.files[0]);
  
      if (type == 1) {
        this.image1 = e.target.files[0];
     this.imagearray.push(this.image1);
     console.log(this.imagearray)
        this.profile = this.image1;
      } 

   
    }

// choose(){
//   this.fileChooseBand('');
// }

uploadImage1(e){
  e.stopPropagation();
 
  $('#exampleInputEmail').trigger('click'); 

}

  

    ngAfterViewInit(){
      this.route.queryParams.subscribe(params => {
        this.id = params['id'];
  
        this.roleSer.getSingleUser(this.id).subscribe(data => {
    
          this.users$ = data.data.user;
      var $audio2 = $('#fileaudioupload');
   
      $audio2.attr('src',this.media_domain+this.users$.audio);
    
      $('#fileinput2').on('change', function(e) {
        var target = e.currentTarget;
         this.fileToUpload2 = target.files[0];
        var reader = new FileReader();
     
        console.log(this.fileToUpload2.size)
      
      
         if(this.fileToUpload2.size >=  4*1024*1024){
          
            
             return false;
         }
      
          if(target.files && this.fileToUpload2)
          {
              var reader = new FileReader();
              reader.onload = function (e) {
                  $audio2.attr('src', reader.result);
                //  $audio.play();
              }
              reader.readAsDataURL(this.fileToUpload2);
          }
         
           
        });
    });

  });
    }
  
 }

