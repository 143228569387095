import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class NewsService {
    constructor(private http: HttpClient) { }



    save(title:any,description:any,image:File){
        const formData: FormData = new FormData();
        // formData.append('date', date);
        formData.append('title', title);
        formData.append('description', description);
        if (image) {
            formData.append('image',image, image.name);
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news`, formData).pipe(map(user => {
            return user;
        }));
    }

    update(id:any,title:any,description:any,image:File){
        const formData: FormData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        if (image && image.name) {
            formData.append('image',image, image.name);
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news/` + id).pipe(map(user => {
            return user;
        }));

    }
    getNews(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news?search=`+search+'&page='+page).pipe(map(user => user));

    }

    getSingleNews(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news/` + id).pipe(map(user => user));

    }

    getUsers(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-user?page=`+ page + '&search=' + search).pipe(map(user => user));

    }




}
