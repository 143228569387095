import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, HomeService } from '../_services';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, formatDate } from '@angular/common';
//import * as alertify from 'alertify.js';//import
import { ViewChild } from '@angular/core';
import{AppsettingsService} from '../_services/appsettings.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;



@Component({
  selector: 'app-edit-terms',
  templateUrl: './edit-terms.component.html',
  styleUrls: ['./edit-terms.component.css']
})
export class EditTermsComponent implements OnInit {
  submitted = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
   //   placeholder: this.homeService.getTranslate('login.text'),
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
       
        'indent',
         'outdent',
        'strikeThrough',
        'subscript',
        'superscript',
     
      
      ],
      [
     
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'textColor',
    'backgroundColor',
      ]
    ]
  };
  termsForm:FormGroup;
  terms:any;
  constructor(
      private formBuilder: FormBuilder,
      private toastr: ToastrService,
      private homeService: HomeService,
      private alertService: AlertService,
      private route: ActivatedRoute,
      private router: Router,
      private AppsettingsService:AppsettingsService,
  ) { }

  ngOnInit() {

    this.AppsettingsService.getAppSettings().subscribe(data => {
      console.log(data.data.terms);
      this.terms=data.data[0];
      this.termsForm.patchValue({
        index: 1,
        _id: this.terms && this.terms._id,
        description:this.terms && this.terms.content,
    });

      

  })

  const nonWhitespaceRegExp: RegExp = new RegExp("^(?!\s|.*\s$).*$");

    this.termsForm = this.formBuilder.group({
      
      description: ['', [Validators.required,Validators.pattern(nonWhitespaceRegExp)]]
      

  });
  }

  get f() {
    return this.termsForm.controls;
}


  onSubmit(form, formWrap) {

    this.submitted = true;
  
    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;
    const that = this;

    if (this.termsForm.invalid) {
      that.toastr.error(that.homeService.getTranslate('faq_form.please_enter_all_fields'))
        return;
    }
    var type="tc";
    
    
    alertify.confirm(that.homeService.getTranslate('terms_&_conditions_form.are_you_sure_you_want_to_update_this_record?'), function() {
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        // that.loading = true;

        that.AppsettingsService.saveTerms(
             
                that.f.description.value,
              type
            )
            .subscribe(
                data => {

                    if (data.status.code == 0) {
                      that.toastr.success(that.homeService.getTranslate('faq_form.successfully_added'));
                      location.href = "/app-settings";

                        // that.temples[that.index_no] = data.data.temples;
                        // console.log(that.temples)
                    
                        document.body.classList.remove('jw-modal-open');

                        // that.loading = false;
                        // that.cancelButton = false;
                        // that.termsForm.setValue({
                        //     index: null,
                        //     _id: null,
                        //     visheshamDate: '',
                        
                        //     deity: '',
                        //     tags: '',
                        //     desireId: '',
                        // })
                        // // location.reload();
                        // location.href = "/vishesham"
                        // formWrap.reset();
                        // that.add_product = false;
                        // location.reload();
                        // this.router.navigate(['/store/category']); 
                    } else {
                        document.body.classList.remove('jw-modal-open');
                        // that.loading = false;
                        that.toastr.error(data.status.message)

                    }
                },
                error => {
                    that.toastr.error(error)

                    document.body.classList.remove('jw-modal-open');
                    // that.loading = false;
                });
    }, function() { //alertify.error('Delete action cancelled')
  // that.toastr.error(that.homeService.getTranslate('faq_form.create_action_cancelled'))
        // location.reload();

      }).set('labels', { cancel: that.homeService.getTranslate('faq.cancel'), ok: that.homeService.getTranslate('faq.ok') });

}
cancel() {
  location.href = "/app-settings";
  //location.reload();
}
}
