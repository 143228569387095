import { Component, OnInit, OnDestroy, ViewChild,AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { RoleService, AlertService, FaqService,HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {IAngularMyDpOptions, IMyDateModel, AngularMyDatePickerDirective} from 'angular-mydatepicker';
declare var jquery: any;
declare var $: any;
declare var alertify: any;



@Component({
  selector: 'app-eventconfig',
  templateUrl: './eventconfig.component.html',
  styleUrls: ['./eventconfig.component.css']
})
export class EventconfigComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dp', { static: false })myDp: AngularMyDatePickerDirective;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy/mm/dd',
    disableUntil: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 2
    }
  };
 
  adminForm: FormGroup;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;
  fileToUpload2: File = null;
  submitted = false;
  //table = false;
  cancelButton = false;
  users$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  $singleUser: any;
  media_domain = `${environment.media_domain}`;
  curPage = 1;
  perPage = 10;
profile: any;
dobdate:any;

id: any;


  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private roleSer: RoleService,
    private faqSer: FaqService,
    private alertService: AlertService,
    private home: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.adminForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      event_date: ['', [Validators.required]]
    
    });


  

//   this.route.queryParams.subscribe(params => {
//     this.id = params['id'];
// if(this.id){
    this.home.getEvent().subscribe(data => {
     this.$singleUser = data.data.event;
console.log(this.$singleUser)
var date_of_birth = this.$singleUser.event_date;

var day = date_of_birth.split('/');
console.log(day)
 this.adminForm.patchValue({event_date: {
   isRange: false,
   singleDate: {
     date: { 
     year: parseInt(day[0]), 
     month: parseInt(day[1]), 
     day: parseInt(day[2]) 
   },
   formatted:this.$singleUser.event_date
   }}});
console.log(this.$singleUser.event_date)
 this.adminForm.patchValue({
  index: 1,
  _id: this.$singleUser._id,
 event_date: this.$singleUser.event_date
});
    })
//   }
// })
  }
 

  toggleCalendar(): void {
  
    this.myDp.toggleCalendar();
  }

  onDateChanged(event: IMyDateModel) {
    console.log('onDateChanged(): ', event);
    console.log(event.singleDate.formatted);
    this.dobdate=event.singleDate.formatted;
    var birth = event.singleDate.date.year;
}
  cancel(){
    this.router.navigate(['/event-config']);
  }


  // convenience getter for easy access to form fields
  get f() { return this.adminForm.controls; }

  onSubmit(form, formWrap) {
    this.submitted = true;
    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;

  
    console.log(this.adminForm.value.event_date)
    if (this.adminForm.invalid) {
      var that = this;
      that.toastr.error(that.home.getTranslate('event_configuration.date') + ' ' + that.home.getTranslate('faq_form.is_required'))
      return;
    }

 
    var that = this;
   
    if (id != null) {
      // 'Are you sure you want to update this FAQ?'
     


      // console.log(that.home.getTranslate('faq_form.cancel')
    alertify.confirm(that.home.getTranslate('event_configuration.are_you_sure_you_want_to_update_this_event_end_date_configuration?'), function () {
      that.home.update(id,
        that.f.event_date.value && that.f.event_date.value.singleDate && that.f.event_date.value.singleDate.formatted,
        )
        .subscribe(
          data => {
            if (data.status.code == 0) {
           
              that.home.getEvent().subscribe(data => {
                that.$singleUser = data.data.event;
              });
              that.toastr.success(data.status.message)

              that.router.navigate(['/event-config']);

            } else {
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.toastr.error(data.status.message)
         
            }
          },
          error => {
            that.toastr.error(error)
        
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
          });
        }, function () { 
         // that.toastr.error(that.home.getTranslate('faq_form.create_action_cancelled'))
        
        
      //  });
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });
    } else {
 
      alertify.confirm(that.home.getTranslate('event_configuration.are_you_sure_you_want_to_create_this_event_end_date_configuration?'), function () {
      that.home.save(that.dobdate)
        .subscribe(
          data => {
            if (data.status.code == 0) {
           //   that.toastr.success(that.home.getTranslate('faq_form.successfully_added'));
               that.router.navigate(['/event-config']);
               that.home.getEvent().subscribe(data => {
                that.$singleUser = data.data.event;
              });

          

              that.submitted = false;
            

            } else {
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
             // that.toastr.error(data.status.message)
         
            }
          },
          error => {
            that.toastr.error(error)
        
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
          });
        }, function () { 
         // that.toastr.error(that.home.getTranslate('faq_form.create_action_cancelled'))
        
        
      //  });
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });
    }
   

  }

  cancelSubmit(event) {
    this.imageExist = '';
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.adminForm.setValue({
      index: null,
      _id: null,
      title: '',
      description: ''
    })
    this.submitted = false;
  }
 
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
