<site-header></site-header>

<div class="wrapper">
  <div class="content-wrapper">

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">{{'dashboard.dashboard' | translate}}</h1>
          </div>

        </div>
      </div>
    </div>

    <section class="content dashboard_box">
      <div class="container-fluid">

        <div class="row">
          <!-- <div class="col-md-1 col-sm-6 col-12"></div> -->

          <!-- <div class="col-md-3 col-sm-6 col-12">
                  <a (click)="news($event)">
                    <div class="info-box">
                      <span class="info-box-icon cmn-icon bg-success"><i class="nav fa fa-list-ul"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-number txt-clr-dashboard">{{news$ ? news$ : 0}}</span>
                        <span class="info-box-text">{{'dashboard.total_news'| translate}}</span>
                      </div>
                   
                    </div>
                  </a>
                  </div> -->

          <!-- <div class="col-md-1 col-sm-6 col-12"></div> -->

          <div class="col-sm-2">
            <a (click)="faq($event)" style="cursor: pointer;">
              <div class="info-box">

                <span class="info-box-icon cmn-icon bg-success"><i class="nav-icon fas fa-question-circle"></i></span>

                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{faq$ ? faq$ : 0}}</span>
                  <span class="info-box-text">{{'dashboard.total_faq'| translate}}</span>
                </div>

              </div>
            </a>
          </div>

          <!-- <div class="col-md-1 col-sm-6 col-12"></div> -->
          <!-- <div class="col-md-3 col-sm-6 col-12">
                    <a (click)="userdata($event)">
                    <div class="info-box">
                      <span class="info-box-icon cmn-icon bg-success"><i class="nav-icon fa fa-users"></i></span>

                      <div class="info-box-content">
                        <span class="info-box-number txt-clr-dashboard">{{user$}}</span>
                        <span class="info-box-text">{{'dashboard.total_users'| translate}}</span>
                      </div>
                   
                    </div>
                  </a>
                  </div> -->

          <div class="col-sm-2">
            <a (click)="contact($event)" style="cursor: pointer;">
              <div class="info-box">
                <span class="info-box-icon cmn-icon bg-success"><i class="nav-icon fas fa-address-book"></i></span>

                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{contact$ ? contact$ : 0}}</span>
                  <span class="info-box-text">{{'dashboard.total_contacts'| translate}}</span>
                </div>
              </div>
            </a>
          </div>


          <div class="col-sm-2">
            <a (click)="blog($event)" style="cursor: pointer;">
              <div class="info-box">
                <span class="info-box-icon cmn-icon bg-success"><i class="nav-icon fas fa-edit"></i></span>
                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{blog$ ? blog$ : 0}}</span>
                  <span class="info-box-text">{{'dashboard.total_blog'| translate}}</span>
                </div>

              </div>
            </a>
          </div>


          <div class="col-sm-2">
            <a (click)="userdata($event)" style="cursor: pointer;">
              <div class="info-box">
                <span class="info-box-icon cmn-icon bg-success"><i class="nav-icon fas fa-users"></i></span>
                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{user$ ? user$ : 0}}</span>
                  <span class="info-box-text">{{'dashboard.total_users'| translate}}</span>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-2">
            <a (click)="chatdata($event)" style="cursor: pointer;">
              <div class="info-box">
                <span class="info-box-icon cmn-icon bg-success"><i class="nav-icon fas fa-comment-alt"></i></span>
                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{msg$ ? msg$ : 0}}</span>
                  <span class="info-box-text">{{'dashboard.total_msg'| translate}}</span>
                </div>
              </div>
            </a>
          </div>
        
         
        </div>

      </div>
    </section>