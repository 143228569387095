import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HomeService } from '../_services';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare var jquery: any;
declare var moment: any;
declare var $: any;

declare var moment: any;
@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  //@ViewChild('gmap') gmapElement: ElementRef;
  stats$: any

  vendorConfig = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '250px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Vendor', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No vendor found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Vendor', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  groundConfig = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '250px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Ground', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No ground found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Ground', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  role = localStorage.getItem(`${environment.appName}` + '_role');
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  logout = localStorage.getItem(`${environment.appName}` + '_time');
  access_token = localStorage.getItem(`${environment.appName}` + '_user');
  logintime = localStorage.getItem(`${environment.appName}` + '_time');
  logouttime = localStorage.getItem(`${environment.appName}` + '_logouttime');
  userId = localStorage.getItem(`${environment.appName}` + '_adminId');
  status: string;

  vendors: any;
  grounds: any;
  searchForm: FormGroup;
  user$: any;
  news$: any;

  faq$: any;
  msg$:any;
  blog$: any;
  orders: any;
  contact$: any;
  products: any;
  ord_amount: any;
  history: any;
  constructor(
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    public translate: TranslateService,
    private router: Router

  ) {
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  ngOnInit() {
    // this.homeService.loginupdateStatus(this.userId, this.logintime, this.logouttime).subscribe(data => {
    //   console.log(data)
    //   if (data.status.code == 0) {
    //     this.homeService.getLogin('','').subscribe(data => {
    //       this.history = data.data.result.docs;
    //     })
    //   }
    // })

    this.homeService.getDashboard().subscribe(data => {

      this.user$ = data.data && data.data.users ? data.data && data.data.users : '';
      this.news$ = data.data && data.data.news ? data.data && data.data.news : '';
      this.faq$ = data.data && data.data.faq ? data.data && data.data.faq : '';
      this.contact$ = data.data && data.data.contact ? data.data && data.data.contact : '';
      this.blog$ = data.data && data.data.blog ? data.data && data.data.blog : '';
      this.msg$ = data.data && data.data.user ? data.data && data.data.user : '';
    }, error => {
      console.error(error);
    });


    var that = this;
    this.searchForm = this.formBuilder.group({
      book: '',
      vendors: '',
      grounds: ''
    });
    //if (localStorage.getItem(`${environment.appName}` + '_role') == "99") {

    // }
  }

  get f() { return this.searchForm.controls; }



  news(e) {
    this.router.navigate(['/news']);
  }

  faq(e) {
    this.router.navigate(['/faq']);
  }

  contact(e) {
    this.router.navigate(['/contact']);
  }

  userdata(e) {
    this.router.navigate(['/users']);
  }
  blog(e) {
    this.router.navigate(['/blog']);
  }
  chatdata(e) {
    this.router.navigate(['/chat']);
  }


  filterDash() {
    var that = this;
    this.homeService.get().subscribe(data => {
      this.stats$ = data.data;
      // this.vendors = data.data.vendors;
    });
  }
  ngAfterViewInit() {
    var that = this;
    // setTimeout(() => {
    //   $('#fini').daterangepicker({
    //     autoUpdateInput: false,
    //     opens: 'left',
    //     locale: {
    //       format: 'DD/MM/YYYY'
    //     },
    //     ranges: {
    //       'Today': [moment(), moment()],
    //       'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    //       'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    //       'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    //       'This Month': [moment().startOf('month'), moment().endOf('month')],
    //       'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    //     }
    //   });
    //   $('#fini').on('apply.daterangepicker', function (ev, picker) {
    //     $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    //     $('.userFilter').attr('onclick', 'location.href="/users?filterDate=' + picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY') + '"')
    //     $('.cmpFilter').attr('onclick', 'location.href="/complaints?filterDate=' + picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY') + '"')
    //     $('.closeFilter').attr('onclick', 'location.href="/closed-complaints?filterDate=' + picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY') + '"')
    //     that.filterDash();
    //   });
    // }, 100);
  }



}
