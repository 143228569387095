import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, HomeService,RoleService,BlogService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { data } from 'jquery';
declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  sel_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob: any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  user_details: any;
  curPage = 1;
  perPage = 10;
  last_page: any;
valueCheck:any;
  total: any;
  from:any;
  to:any;
  searchUser: any;
  apiUrl="";
  blog$ : any;
  limit:any;
  pageCounter:any;
  topage:any;
  page:any;
 // userId: [];
newdata:any;
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private home: HomeService,
    private role: RoleService,
    private blogSer: BlogService
  ) {
  }
  get f() { return this.creditForm.controls; }


  

  ngOnInit() {

    const that = this;


  this.apiUrl = `${environment.apiUrl}`;
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name/Email/Mobile No."
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };


    this.blogSer.getBlog('','').subscribe(data => {
  
      this.blog$ = data.data.blog.docs;
     var i =0;
      this.blog$.forEach(data=>{
     var descrpt = data.description;
   this.blog$[i++].description = $('<span />').html(descrpt.replace(/<[^>]+>/g, '')).text();
    
      })

      this.curPage = data.data.blog.page;
      this.total= data.data.blog.totalDocs;
      this.from = data.data.blog.prevPage;
      this.to = data.data.blog.nextPage;
      this.last_page = data.data.blog.totalPages;
      this.limit = data.data.blog.limit;
      this.page = data.data.blog.page;
      this.pageCounter = data.data.blog.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.blog$.length;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

   
  
    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: '1'
    });


  }

  search(e){
    
      if (e.target.value) {
        this.searchUser = true;
      } else {
        this.searchUser = false;
      }

    this.blogSer.getBlog(e.target.value,1).subscribe(data =>{
      this.blog$ = data.data.blog.docs;
      var i =0;
      this.blog$.forEach(data=>{
     var descrpt = data.description;
   this.blog$[i++].description = $('<span />').html(descrpt.replace(/<[^>]+>/g, '')).text();
    
      })

      this.curPage = data.data.blog.page;
      this.total= data.data.blog.totalDocs;
      this.from = data.data.blog.prevPage;
      this.to = data.data.blog.nextPage;
      this.last_page = data.data.blog.totalPages;
      this.limit = data.data.blog.limit;
      this.page = data.data.blog.page;
      this.pageCounter = data.data.blog.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.blog$.length;
    })
 
  }

  pageChange(e){

    this.blogSer.getBlog('',e).subscribe(data =>{
      this.blog$ = data.data.blog.docs;
      var i =0;
      this.blog$.forEach(data=>{
     var descrpt = data.description;
   this.blog$[i++].description = $('<span />').html(descrpt.replace(/<[^>]+>/g, '')).text();
    
      })

      this.curPage = data.data.blog.page;
      this.total= data.data.blog.totalDocs;
      this.from = data.data.blog.prevPage;
      this.to = data.data.blog.nextPage;
      this.last_page = data.data.blog.totalPages;
      this.limit = data.data.blog.limit;
      this.page = data.data.blog.page;
      this.pageCounter = data.data.blog.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.blog$.length;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
   
  }

  view(e,i){
   
      e.stopPropagation();
      $('.temple_drp_menu').css('display','none');
      $('.temple_drp_menu' + i).toggle();
      $(document).click(function(e){
       if(!$(e.target).closest('.temple_drp_btn, .temple_drp_menu').length){
       $('.temple_drp_menu').hide();
       }
     })
  }
    
  markCheck(e,user){
  console.log(e.target.checked)
  if(e.target.checked){
   this.valueCheck = 1;
   var txt = this.home.getTranslate('blog.are_you_sure_you_want_to_mark_this_blog?');
   var successmsg =  this.home.getTranslate('blog.success');
  }else{
    this.valueCheck = 0;
    var txt = this.home.getTranslate('blog.are_you_sure_you_want_to_unmark_this_blog?');
    var successmsg =  this.home.getTranslate('blog.unsuccess');
  }
  
var that = this;
   alertify.confirm(txt, function () {
    that.blogSer.updatefeatured(user._id,that.valueCheck)
      .subscribe(
        data => {
          if (data.status.code == 0) {
            that.toastr.success(successmsg)
          
            that.blogSer.getBlog('','').subscribe(data => {

              that.blog$ = data.data.blog.docs;
              var i =0;
              that.blog$.forEach(data=>{
             var descrpt = data.description;
             that.blog$[i++].description = $('<span />').html(descrpt.replace(/<[^>]+>/g, '')).text();
            
              })
        
              that.curPage = data.data.blog.page;
              that.total= data.data.blog.totalDocs;
              that.from = data.data.blog.prevPage;
              that.to = data.data.blog.nextPage;
              that.last_page = data.data.blog.totalPages;
              that.limit = data.data.blog.limit;
              that.page = data.data.blog.page;
              that.pageCounter = data.data.blog.pagingCounter;
              that.topage = (that.limit * (that.page - 1)) + that.blog$.length;
              that.dtTrigger.next();
            }, error => {
              console.error(error);
            });
        
         
          } else {
            that.toastr.error(that.home.getTranslate('blog.max_error'))
           
            $('.case'+ user._id).prop('checked',false);

          }
        },
        error => {
          that.toastr.error(error)
         
        });
  }
    , function () {
      // that.toastr.error('Delete action cancelled')
      $('.case'+ user._id).prop('checked',false);
      if(user.featured == 1){   
      $('.case'+ user._id).prop('checked',true);
      }
      // else{
      //   $('.case'+ user._id).prop('checked',true);
      // }
    }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });

   
 

  }
  editblog(e,user){
    this.router.navigate(['/create-blog'], { queryParams: {id: user._id}})
  }

  add(e){
    this.router.navigate(['/create-blog'])
  }

  deleteblog(e,user){
    var that = this;
    alertify.confirm(that.home.getTranslate('blog.are_you_sure_you_want_to_delete_this_blog?'), function () {
      that.blogSer.delete(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success(that.home.getTranslate('faq.deleted_successfully'))
            
              that.blogSer.getBlog('',that.curPage).subscribe(data => {
  
                that.blog$ = data.data.blog.docs;
                var i =0;
                that.blog$.forEach(data=>{
               var descrpt = data.description;
               that.blog$[i++].description = $('<span />').html(descrpt.replace(/<[^>]+>/g, '')).text();
              
                })
                that.curPage = data.data.blog.page;
                that.total= data.data.blog.totalDocs;
                that.from = data.data.blog.prevPage;
                that.to = data.data.blog.nextPage;
                that.last_page = data.data.blog.totalPages;
                that.limit = data.data.blog.limit;
                that.page = data.data.blog.page;
                that.pageCounter = data.data.blog.pagingCounter;
                that.topage = (that.limit * (that.page - 1)) + that.blog$.length;
                that.dtTrigger.next();
              }, error => {
                console.error(error);
              });
          
           
            } else {
              that.toastr.error(data.status.message)
            
            }
          },
          error => {
            that.toastr.error(error)
           
          });
    }
      , function () {
        // that.toastr.error('Delete action cancelled')
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });

  }

  
  
}
