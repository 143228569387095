import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class RoleService {
    constructor(private http: HttpClient) { }



    

    deleteAdmin(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/multipleadmin/deleteAdmin/` + id).pipe(map(user => {
            return user;
        }));

    }
    getState(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/user/get-state`).pipe(map(user => user));
    }

    getOccupation(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/user/get-occupation`).pipe(map(user => user));
    }

 
    getUsers(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/userList?page=`+ page + '&search=' + search).pipe(map(user => user));

    }

    getUsersDetails(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/userList?page=`+ page + '&search=' + search).pipe(map(user => user));

    }

    getSingleUser(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-userSingle/` + id).pipe(map(user => user));

    }


    getMail(id:any): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/sendMail/`+id).pipe(map(user => user));
    }

    geteventMail(id:any): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/sendeventMail/`+id).pipe(map(user => user));
    }

    status_update(id: string, status: any) {
        const formData: FormData = new FormData();
       
       
        formData.append('status', status);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/update_status/`+ id, formData).pipe(map(data => {
            return data;
        }));

    }

   
    update(id: any,name:any,email:any,band_name:any,video_audio_url:any,band_introduction:any,
        state:any,occupation:any,url_youtube_sns:any,affiliation_organization:any,member_name:any,
        band_photo,userID:any,remarks:any,url_sound1:any,url_sound2:any,
        url_sound3:any,user_source_for_judging:any,event_remarks:any,event_source_for_judging:any,other_url:any,audio_file_duration:any,profile){
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('band_name', band_name);
        formData.append('video_audio_url', video_audio_url);
        formData.append('band_introduction', band_introduction);
        formData.append('state', JSON.stringify(state));
        formData.append('occupation', JSON.stringify(occupation));
        formData.append('url_youtube_sns', url_youtube_sns);
        formData.append('affiliation_organization', affiliation_organization);
        formData.append('member_name', member_name);
        if (band_photo && band_photo.name) {
            formData.append('band_photo',band_photo, band_photo.name);
        }
        formData.append('userID', userID);
        formData.append('remarks', remarks);
        formData.append('url_sound1', url_sound1);
        formData.append('url_sound2', url_sound2);
        formData.append('url_sound3', url_sound3);
        formData.append('user_source_for_judging', user_source_for_judging);
        formData.append('event_remarks', event_remarks);
        formData.append('event_source_for_judging', event_source_for_judging);
        formData.append('other_url', other_url);
        formData.append('audio_file_duration',audio_file_duration);
        profile.forEach(res=>{
            formData.append('profile[]',res,res.name);
            });
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user/update_user/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    event_update(id: string, event_accept: any) {
        const formData: FormData = new FormData();
        formData.append('event_accept', event_accept);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/update_status/`+ id, formData).pipe(map(data => {
            return data;
        }));
    }


    deleteMember(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/deleteMember/` + id).pipe(map(user => {
            return user;
        }));
    }

    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/` + id).pipe(map(user => {
            return user;
        }));
    }
}
