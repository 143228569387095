import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, HostListener, Output, EventEmitter, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { RoleService, AlertService, FaqService, HomeService, MessageService, DownloadService } from '../_services';
import { Subject, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { SocketService } from './shared/services/socket.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Action } from './shared/model/action';
import { Event } from './shared/model/event';
import { VideoProcessingService } from '../_services/video-processing-service';
import { Message } from './shared/model/message';
import { NgxLinkifyOptions } from 'ngx-linkifyjs';
import { DOCUMENT } from '@angular/common';
import 'emoji-mart/css/emoji-mart.css';
// import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
// import { DownloadService } from './download.service';
import { Download } from '../_services/download'
// import { TimeAgoPipe } from 'time-ago-pipe';
// import { TimeagoIntl } from 'ngx-timeago';


declare var jquery: any;
declare var $: any;
declare var alertify: any;
// const io = require("socket.io-client");
const options: NgxLinkifyOptions =
{
  attributes: null,
  className: 'linkified',
  defaultProtocol: 'http',
  events: null,
  format: function (value, type) {
    return value;
  },
  formatHref: function (href, type) {
    return href;
  },
  ignoreTags: [],
  nl2br: false,
  tagName: 'a',
  target: {
    url: '_blank'
  },
  validate: true
};


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() messageEvent = new EventEmitter<number>();
  @ViewChild('messageSearchInput', { static: true }) messageSearchInput: ElementRef
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef<HTMLInputElement>;
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef<HTMLInputElement>;
  @ViewChild("fileInput2", { static: false }) fileInput2: ElementRef<HTMLInputElement>;


  userId = localStorage.getItem(`${environment.appName}` + '_adminId');
  parentMessage = { chatcount: 0 };
  singleUserDetails:any;
  lastmsg: any;
  searchUser: any;
  dataURL: any;
  userlist: any;
  current_page = 2;
  curr_page = 2;
  userInfo: any;
  showbox = false;
  media_domain = `${environment.media_domain}`;
  projectData: any;
  adminForm: FormGroup;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;
  fileToUpload2: File = null;
  submitted = false;
  showEmojiPicker: Boolean = false;
  msg_type: any;
  imageDisp: any;
  filedownload:any;
  newchat_id: any;
  cancelButton = false;
  users$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  emojiForm: FormGroup;
  $singleUser: any;
  page_number = 1;
  curPage = 2;
  file_name:any;
  currentPage = 2;
  perPage = 10;
  scrollCheck: any;
  profile: any;
  selectedArray = [];
  files_array = [];
  messageContent: string;
  text: any;
  togged: any;
  chat_server = `${environment.chat_server}`;
  messages$: any[];
  action = Action;
  messages: Message[] = [];
  ioConnection: any;
  nodata = false;
  search_text = '';
  skip = 0;
  chatId: any;
  limit: any;
  showImage:any;
  showVideo:any;
  pageCounter: any;
  topage: any;
  page: any;
  current_date: any;
  total: any;
  to: any;
  chatIds: any;
  from: any;
  urlRegex: any;
  reg: any;
  last_page: any;
  userSingle: any;
  msgCount = 0;
  $counts: any;
  chatclr = false;
  _album = [];
  public thumbnailData: string;
  dataURI: any;
  download$: Observable<Download>
  id = this.route.snapshot.paramMap.get('id');
  
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private roleSer: RoleService,
    private faqSer: FaqService,
    private alertService: AlertService,
    private home: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private elRef: ElementRef,
    private socketService: SocketService,
    private socketServiceadmin: SocketService,
    private msgSer: MessageService,
    private videoService: VideoProcessingService,
    private downloads: DownloadService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
 
     setInterval(() => {
      this.home.getmsgNotification().subscribe(data => {
        this.$counts = data.data.message;
        if (this.$counts != this.msgCount) {
          this.msgSer.getchatUser(this.search_text, 1).subscribe(data => {
         
            data.data.users.docs.forEach(ele =>{
              this.userlist[this.userlist.findIndex(result=>{return result._id === ele._id})]['final_count'] = ele.final_count;
            })
           
          })
        } else {
          this.msgCount = this.$counts;
        }
      })
   }, 2000);

    this.msgSer.getchatUser('', '').subscribe(data => {
      this.userlist = data.data.users.docs;
      this.currentPage = data.data.users.page;
    })

    this.reg = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    this.urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm;
    this.msgSer.getchatUser('', '').subscribe(data => {
      this.userlist = data.data.users.docs;
      this.currentPage = data.data.users.page;
    })

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.messageRefresh('', '');
      this.messageContent = null;

      if (this.id) {

        $(function () {
          if ($('.chat_box').hasScrollBar() == true) {
            this.scrollCheck = 1;
          } else {
            this.scrollCheck = 0;
          }
        });
        $.fn.hasScrollBar = function () {
          return this.get(0) && this.get(0).scrollHeight > this.height();
        }

        this.msgSer.getUserSingle(this.id).subscribe(data => {
          this.userSingle = data.data.name;
          this.singleUserDetails = data.data;
        })

        this.msgSer.getchat(this.id).subscribe(data => {
          this.chatId = data.data && data.data.chat_id ? data.data && data.data.chat_id : data.data && data.data[0].chat_id;

          this.initIoConnection();
          this.msgSer.get_message(this.chatId, '', '').subscribe(data => {

            this.messages$ = data.data && data.data.message && data.data.message.docs && data.data.message.docs.reverse();
            this.messages$.forEach(element => {
              console.log(element)
            
             
              if (element.file_type == 'video') {
                // setTimeout(() => {
                  // let div = document.createElement('div');
                  // div.id = 'content';
                  // div.innerHTML = '<video class="chat_img" controls="" id="video" type="video/mp4" src="https://dktbtl719qsj2.cloudfront.net/development/shironuki/files/5pv91ibkv20l0k5 (19).mp4"></video>';
                  var canvas = <HTMLCanvasElement>document.getElementById('canvas');
                  var video = <HTMLImageElement>document.getElementById('video');
                  var context = canvas && canvas.getContext('2d');
                  context && context.drawImage(video, 0, 0, canvas.width, canvas.height);

                  var dataURI = canvas && canvas.toDataURL('image/jpeg');
                // }, 1000);
              }
              //  <video _ngcontent-gmt-c0="" class="chat_img" controls="" id="video" type="video/mp4" src="https://dktbtl719qsj2.cloudfront.net/development/shironuki/files/5pv91ibkv20l0k5 (19).mp4"></video>
            })
            this.curPage = data.data && data.data.message && data.data.message.page;
            this.total = data.data && data.data.message && data.data.message.totalDocs;
            this.from = data.data && data.data.message && data.data.message.prevPage;
            this.to = data.data && data.data.message && data.data.message.nextPage;
            this.last_page = data.data && data.data.message && data.data.message.totalPages;
            this.limit = data.data && data.data.message && data.data.message.limit;
            this.page = data.data && data.data.message && data.data.message.page;
            this.pageCounter = data.data && data.data.message && data.data.message.pagingCounter;
            this.topage = (this.limit * (this.page - 1)) + (this.messages$ && this.messages$.length);
            this.dtTrigger.next();
          }, error => {
            console.error(error);
          });
          if (!this.scrollCheck) {

            this.msgSer.chatRead(this.chatId).subscribe(data => {

              this.msgSer.getchatUser('', '').subscribe(data => {
                this.userlist = data.data.users.docs;
                this.currentPage = data.data.users.page;
              })

            })
          }



        })
        var span = $('<span>').css('display', 'inline-block')
          .css('word-break', 'break-all').appendTo('body').css('visibility', 'hidden');
        function initSpan(textarea) {
          span.text(textarea.text())
            .width(textarea.width())
            .css('font', textarea.css('font'));
        }
        $('textarea').on({
          input: function () {
            var text = $(this).val();
            span.text(text);
            if (!(text.trim())) {
              $(this).height('18px');
            } else {
              $(this).height(text ? span.height() : '3rem');
            }

            if ($('textarea').val().length > 500) {
              $('textarea').height($(this).height() + 20);
            }
          },
          focus: function () {
            initSpan($(this));
          },
          keypress: function (e) {
            if (e.which == 13) {
              $(this).height('18px');
              e.preventDefault();
            }
          }
        });
      }
    })

 //   this.fileInput2.nativeElement.accept = '.doc,.docx,.pdf,.xlsx,.zip,.json';
   // $('#files').val();
  //  $('#image').val();
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.adminForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });


    this.emojiForm = this.formBuilder.group({
      inputField: [''],
    });


    timer(200).subscribe(tm => {
      $('.chat_box').animate({
        scrollTop: $('.chat_box')[0] && $('.chat_box')[0].scrollHeight + 1000
      }, "slow");
    })




    this.route.queryParams.subscribe(params => {
      if (params['message_id']) {
        this.searchResult('', 0, params['message_id']);
      }
    })
    $('emoji-mart').on('mouseleave', function (e) {
      e.stopPropagation();
      this.showEmojiPicker = false;
      $('emoji-mart').css('display', 'none');
    });

    $('emoji-mart').on('mouseleave', function (e) {
      e.stopPropagation();
      this.showEmojiPicker = false;
      $('emoji-mart').css('display', 'none');
    });

    $(document).on('click', e => {
      e.stopPropagation();
      this.showbox = false;
    })
  }

  file_names(m){
    return m.file_name.length>20?(m.file_name.split('.')[0]).substr(0,20)+'...'+m.file_name.split('.')[1]:m.file_name
  }

  canvasImage(m) {
    var canvas = <HTMLCanvasElement>document.getElementById('canvas');
    var video = <HTMLVideoElement>document.getElementById('video');
    console.log(video)
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    var dataURI = canvas.toDataURL('image/jpeg');

  }
  open(e,msg): void {
    e.stopPropagation();
    // open lightbox
    this._album.push(this.media_domain + msg.file_path);
   // this._albums = this.media_domain + msg.file_path;
   // this._lightbox.open(this._album);
  }

  close(e,user) {
    e.stopPropagation();
    e.preventDefault();
   this.showImage = null;
   
//  this.router.navigate(['/chat'], { queryParams: { id: user.id } });

  timer(200).subscribe(tm => {
    $('.chat_box').animate({
      scrollTop: $('.chat_box')[0] && $('.chat_box')[0].scrollHeight + 1000
    }, "slow");
  })
  //location.reload();
  }
  close1(e,user) {
   e.stopPropagation();
   e.preventDefault();
    this.showVideo = null;
   
  //  this.router.navigate(['/chat'], { queryParams: { id: user.id } });
   timer(200).subscribe(tm => {
    $('.chat_box').animate({
      scrollTop: $('.chat_box')[0] && $('.chat_box')[0].scrollHeight + 1000
    }, "slow");
  })
   // location.reload();
  }


  download(msg) {
    console.log(msg)
    this.download$ = this.downloads.download(msg.file_path, msg.file_name);
    console.log(this.download$)
    return false;
  }

  capture() {
    var canvas = <HTMLCanvasElement>document.getElementById('canvas');
    var video = <HTMLVideoElement>document.getElementById('video');
    canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  }

  uploadFile(e, type) {
    e.stopPropagation();
    $('#files').val();
    $('#image').val();
    if (type == 1) {
      // $('#files').trigger('hide');
      // $('#image').trigger('hide');
      // $('#video').trigger('click');
      this.fileInput.nativeElement.accept = '';
      this.fileInput.nativeElement.accept = 'video/*';
      this.fileInput && this.fileInput.nativeElement.click();
    } 
  }

  uploadFile1(e, type) {
    this.fileInput2.nativeElement.accept ='';
    $('#files').val();
    $('#videos').val();
    e.stopPropagation();
   if (type == 2) {
    this.fileInput1.nativeElement.accept ='';
     this.fileInput1.nativeElement.accept = 'image/*';
    $('#files').trigger('hide');
    $('#image').trigger('click');
      // this.fileInput1 && this.fileInput1.nativeElement.click();
    }
  }

  uploadFile2(e, type) {
    this.fileInput1.nativeElement.accept ='';
    $('#image').val();
    $('#videos').val();
    e.stopPropagation();
   if(type == 3) {
    
    this.fileInput2.nativeElement.accept ='';
     this.fileInput2.nativeElement.accept = '.doc,.docx,.pdf,.xlsx,.zip,.json';
    //   this.fileInput2 && this.fileInput2.nativeElement.click();
    $('#image').trigger('hide');
    $('#files').trigger('click');
    }
  }

  closeModal(e,user) {
    $('#user-republic-mdl').modal('hide');
  }

  fileDownload(msg){
    location.href = this.media_domain + msg.file_path;
  }

  removeFile(e) {
    this.files_array = [];
    this.selectedArray = [];
    $('#grow').attr('placeholder', this.home.getTranslate('chat').caption);
  }

  selectFile(e, type) {
    console.log(e.target.files[0].type)
    e.stopPropagation();
    if (type == 1) {
      this.files_array = [];
      const validImageTypes = ['video/mp4', 'video/mov', 'video/x-matroska', 'video/quicktime', 'video/webm', 'video/x-flv'];
      // if ($.inArray(e.target.files[0].type, validImageTypes) >= 0) {
      // if (e.target.files[0].type.match("video.*")) {
         if ((e.target.files[0].type == 'video/mp4') || (e.target.files[0].type == 'video/mov') || (e.target.files[0].type == 'video/x-matroska') || (e.target.files[0].type == 'video/quicktime') || (e.target.files[0].type == 'video/webm')) {
          if (e.target.files[0].size > 60000000) {
            //  this.notify.error(this.util.getTranslate('vdo_max'));
            $('.undo_btn').css('display', 'none');
            $('[type="file"]').val('');
            var that = this;
            that.toastr.error(that.home.getTranslate('chat.max_videosize'));
            return;
          } else if (e.target.files[0].size < 60000000 && (e.target.files[0].type == 'video/mp4') || (e.target.files[0].type == 'video/quicktime') || (e.target.files[0].type == 'video/x-matroska') || (e.target.files[0].type == 'video/webm')) {
            $('#grow').attr('placeholder', this.home.getTranslate('chat').caption);
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
              this.files_array.push(e.target.files[0]);
              this.selectedArray.push({ 'type': { 'type': 'video', 'video': reader.result } });
              $('[type="file"]').val('');
              $('.img_select').css('display', 'block')
            }
          }else{
            var that = this;
            if ((e.target.files[0].type !== 'video/mp4') || (e.target.files[0].type !== 'video/mov') || (e.target.files[0].type !== 'video/x-matroska') || (e.target.files[0].type !== 'video/quicktime') || (e.target.files[0].type !== 'video/webm')) {
            that.toastr.error(that.home.getTranslate('chat').only_video);
            return false;
            }
          }
        // }
        }else{
          var that = this;
        //  if (!e.target.files[0].type.match("video.*")) {
          that.toastr.error(that.home.getTranslate('chat').only_video);
          return false;
        //  }
        }
      // }
    }
    else{
      var that = this;
     // if (!e.target.files[0].type.match("video.*")) {
      that.toastr.error(that.home.getTranslate('chat').only_video);
      return false;
    //  }
    } 

  }
  selectFile3(e, type) {
    console.log(e.target.files[0].type)
    e.stopPropagation();
    if (type == 3) {
      this.files_array = [];


if((!e.target.files[0].type.match("video.*")) && (!e.target.files[0].type.match("image.*"))){
        if (e.target.files[0].size > 20000000) {

          $('.undo_btn').css('display', 'none');
          $('[type="file"]').val('');
          var that = this;
            that.toastr.error(that.home.getTranslate('chat.max_filesize'));
            return;
        } else if (e.target.files[0].size < 20000000) {
          $('#grow').attr('placeholder', this.home.getTranslate('chat').caption);
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = (_event) => {
            this.files_array.push(e.target.files[0]);
            this.selectedArray.push({ 'type': { 'type': 'file', 'file': reader.result } });
            $('[type="file"]').val('');
            $('.img_select').css('display', 'block')
          }
        }
      }
      else {
        var that = this;
      //  if ((!e.target.files[0].type.match("video.*")) && (!e.target.files[0].type.match("image.*"))) {
        that.toastr.error(that.home.getTranslate('chat').only_file);
        return false;
     //   }
      }
    }
    else {
      var that = this;
     // if ((!e.target.files[0].type.match("video.*")) && (!e.target.files[0].type.match("image.*"))) {
      that.toastr.error(that.home.getTranslate('chat').only_file);
      return false;
   //   }
    }
  }

  selectFile2(e, type) {
console.log(e.target.files[0].type)
    e.stopPropagation();
    if (type == 2) {
      this.files_array = [];
      const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

      if (e.target.files[0].type.match("image.*")) {
        if ((e.target.files[0].type !== 'video/mp4') && (e.target.files[0].type !== 'video/mov') && (e.target.files[0].type !== 'video/mkv') && (e.target.files[0].type !== 'video/quicktime') && (e.target.files[0].type !== 'video/webm') && (e.target.files[0].type !== 'video/x-flv')) {
          if (e.target.files[0].size > 8000000) {
            //  this.notify.error(this.util.getTranslate('max_image'));
            $('.undo_btn').css('display', 'none');
            $('[type="file"]').val('');
            var that = this;
            that.toastr.error(that.home.getTranslate('chat.max_imgsize'));
            return;
          } else {
            $('#grow').attr('placeholder', this.home.getTranslate('chat').caption);
            this.files_array.push(e.target.files[0]);
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);

            reader.onload = (_event) => {

              this.selectedArray.push({ 'type': { 'type': 'image', 'image': reader.result } });

              $('[type="file"]').val('');
            }
          }
        }

      }
      else {
        var that = this;
        if (!e.target.files[0].type.match("image.*")) {
          that.toastr.error(that.home.getTranslate('chat').only_image);
          return false;
        }
      }
    }
    else {
      var that = this;
      if (!e.target.files[0].type.match("image.*")) {
        that.toastr.error(that.home.getTranslate('chat').only_image);
        return false;
      }
    }
  }

  getDate(date) {
    return new Date(date);
  }

  handleCharDelete(e) {
    if (this.text.length > 0) {
      this.text = this.text.substr(0, this.text.length - 2);
    }
  }





  messageRefresh(page, user) {
    this.messageContent = null;
    this.msgSer.get_message(user, page, '').subscribe(data => {
      this.messages$ = data.data && data.data.message && data.data.message.docs && data.data.message.docs.reverse();
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

  }

  private initIoConnection(): void {
    this.socketServiceadmin.initSocketadmin(this.chat_server);
    this.socketServiceadmin.onMessageadmin().subscribe((message: any) => {

    });

    this.socketServiceadmin.onEventadmin(Event.CONNECT)
      .subscribe(() => {
      });

    this.socketServiceadmin.onEventadmin(Event.DISCONNECT)
      .subscribe(() => {
      });

    this.socketService.initSocket(this.chat_server, this.chatId, this.userId);

    this.socketService.onMessage()
      .subscribe((message: any) => {
        var msg = message;
       
        if (!this.chatclr) {
          this.messages$ && this.messages$.push(msg);
        }

     

        $('.chat_box').animate({
          scrollTop: $('.chat_box')[0] && $('.chat_box')[0].scrollHeight + 1000
        }, "slow");
        $('html,body').animate({ scrollTop: document.body.scrollHeight }, "fast");
        this.msgSer.getchatUser('', '').subscribe(data => {
          this.userlist = data.data.users.docs;
          this.currentPage = data.data.users.page;
        })
        //  this.userlist.final_count-=1;
        // var newdata = this.messages$[this.messages$.length - 1];
        // this.lastmsg = newdata;
        //  this.msgSer.chatRead(this.lastmsg.chat_id).subscribe(data => {});
       });


    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {

      });

    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {

        this.messageRefresh('', '');

      });
  }

  findMore(e, type) {
    e.stopPropagation();
    if (type == "up") {
      if (this.nodata == false) {
        this.skip += 1;
      }
    } else {
      if (this.nodata == false) {
        this.skip -= 1;
      }

    }
    this.searchResult(this.search_text, this.skip);
  }

  searchResult(e, skip_value, msg_id = "") {
    var that = this;
    var timer;

    that.search_text = '';
    that.search_text = e;

  }

  public socketSend(message) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let date_ob1 = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    var date_ob = new Date(date_ob1);
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = monthNames[date_ob.getMonth()];
    let year = date_ob.getFullYear();
    let hours = date_ob.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    var time = date + "-" + month + "-" + year + ' ' + hours;
    this.socketService.send(message);
    this.messageContent = null;

    $('.chat_box').animate({
      scrollTop: $('.chat_box')[0] && $('.chat_box')[0].scrollHeight + 1000
    }, "slow");

    $('html,body').animate({ scrollTop: document.body.scrollHeight }, "fast");

    this.messages$ && this.messages$.push(message);
    //  this.messageRefresh('',message.chat_id);
  }
  show(e) {
    e.stopPropagation();
    this.showbox = true;
  }
  dataChec(e) {
    e.stopPropagation();
  }

  dataShare(e) {
    console.log(e)
    e.stopPropagation();
    this.dataSend(e);
  }
  dataSend(e) {
    e.stopPropagation();
    this.projectData = e.target.value;
    var match1 = this.projectData && this.projectData.match(/^https?\:\/\/[^\/\s]+(\/.*)?$/);
    if (!match1) {
      var that = this;
      that.toastr.error(that.home.getTranslate('banner_form.link_pattern'));
      return false;
    }
    this.sendMessage(this.projectData);
    this.showbox = false;
  }


  onScrollUp() {

    this.page_number++;

    if (this.search_text == '') {
      //  this.curPage = 1;
      this.msgSer.get_message(this.chatId, this.current_page++, this.search_text).subscribe(data => {
        if (data.data.message && data.data.message.docs && data.data.message.docs.length) {
          data.data.message.docs.forEach(dt => {
            this.messages$.unshift(dt);
            var newdata = this.messages$[this.messages$.length - 1];
            this.lastmsg = newdata;
            if (this.lastmsg) {
              this.msgSer.chatRead(this.lastmsg.chat_id).subscribe(data => {
                this.msgSer.getchatUser('', '').subscribe(data => {
                  this.userlist = data.data.users.docs;
                  this.currentPage = data.data.users.page;
                })
              })
            }
          });
        }
      }, error => {
      });
    }


  }

  search1(e) {

    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }

    this.msgSer.getchatUser(e.target.value, 1).subscribe(data => {
      this.userlist = data.data.users.docs;
      this.currentPage = data.data.users.page;
      console.log(this.userlist)
    }, error => {
    });
    $('#scroll_card1').animate({
      scrollTop: $('#scroll_card1')[0] && $('#scroll_card1')[0].scrollHeight + 1000
    }, "slow");
  }
  onScrollUp1() {

    this.page_number++;
    if (!this.search_text) {
      this.msgSer.getchatUser(this.search_text, this.page_number).subscribe(data => {
        data.data.users.docs.forEach(dt => {
          // this.userlist.unshift(dt);
          this.userlist.push(dt);
        })
        this.currentPage = data.data.users.page;
      }, error => {
      });
    }
  }
  public sendMessage(message: string): void {
    var new_value = $('#project_upload').val();
    if (new_value) {
      var match1 = new_value.match(/^https?\:\/\/[^\/\s]+(\/.*)?$/);

      if (!match1) {
        var that = this;
        that.toastr.error(that.home.getTranslate('banner_form.link_pattern'));
        return;
      }
      message = new_value;
    }

    if (message) {
      if (!message.trim()) {
        return;
      }
    }
    if ((message == null) && (this.files_array.length == 0)) {
      return;
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let date_ob1 = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    var date_ob = new Date(date_ob1);
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = monthNames[date_ob.getMonth()];
    let year = date_ob.getFullYear();
    let hours = date_ob.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    var time = date + "-" + month + "-" + year + ' ' + hours;
    var that = this;

    if (that.files_array.length) {
      $('#grow').attr('placeholder', this.home.getTranslate('chat').caption);
      that.loading = true;
      that.msgSer.fileUpload(that.files_array[0]).subscribe(data => {
        console.log(data)
        if (data.status.code == 0) {
          var data_info = {
            'message': message ? message : "",
            'userId': that.userId,
            'file_path': data.data.file_path,
            'type': that.projectData ? 'link' : new_value ? 'link' : "message",
            'file_type': data.data.file_type,
            'file_name': data.data.file_name,
            'category': 1,
            'chat_id': that.chatId,
            'time': 'just now',
            'mId': that.userId + '_' + Date.now()
          }
          that.socketSend(data_info);
          data_info.userId = that.userInfo;

          that.projectData = null;
          that.selectedArray = [];
          that.files_array = [];
        }
        that.loading = false;
      }, error => {
        that.loading = false;
      });
    } else {
      $('#grow').attr('placeholder', this.home.getTranslate('chat').caption);
      var data = {
        'message': message,
        'userId': that.userId,
        'chat_id': that.chatId,
        'file_path': '',
        'type': that.projectData ? 'link' : new_value ? 'link' : "message",
        'file_type': '',
        'file_name': '',
        'category': 1,
        'time': 'just now',
        'mId': that.userId + '_' + Date.now()
      }
      that.socketSend(data);
      data.userId = that.userInfo;
      that.projectData = null;
    }
    $('#grow').attr('placeholder', '');
    that.resizeTextArea();

  }


  resizeTextArea() {
    $('textarea').height('');
  }

  search(e) {
    if (e.target.value) {
      $('.chat_search input').addClass("expand_search");
      $('.inc').removeClass("hide_inc");
      $('.inc').addClass("show_inc");
    } else {
      $('.chat_search input').removeClass("expand_search");
      $('.inc').removeClass("show_inc");
      $('.inc').addClass("hide_inc");
    }

    if (!e.target.value) {
      this.messageRefresh(1, '');
      this.search_text = '';
      this.curPage = 1;
      this.current_page = 1;
    } else {

    }

  }


  openImage(e, msg) {
    e.stopPropagation();
    this.showImage = this.media_domain + msg.file_path;
  
  }

  openVideo(e, msg) {
   
    e.stopPropagation();

    this.showVideo = this.media_domain + msg.file_path;
  
  }

  chatUser(e, user) {
    location.href = '/chat?id=' + user._id;
    this.chatclr = true;
    $('.chat_box').animate({
      scrollTop: $('.chat_box')[0] && $('.chat_box')[0].scrollHeight + 1000
    }, "slow");

    setInterval(() => {
      this.msgSer.getchatUser('', '').subscribe(data => {
        this.userlist = data.data.users.docs;
        this.currentPage = data.data.users.page;
      })
    }, 1000);
  }
  openDropDown(e) {
    e.stopPropagation();
    $('.chat_toggle_menu').css('display', 'none');
    $('.chat_toggle_menu').toggle();
  }

  cancel() {
    this.router.navigate(['/faq']);
  }
  get f() { return this.adminForm.controls; }

  cancelSubmit(event) {
    this.imageExist = '';
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.adminForm.setValue({
      index: null,
      _id: null,
      title: '',
      description: ''
    })
    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  draw(video, canvas, img) {
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    var dataURL = canvas.toDataURL();
    img.setAttribute('src', dataURL);
  }

  ngAfterViewInit() {
    this.userInfo = JSON.parse(localStorage.getItem(`${environment.appName}` + '_userInfo'));

    setTimeout(() => {



    


      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }

      jQuery.each(jQuery('textarea[data-autoresize]'), function () {
        var offset = this.offsetHeight - this.clientHeight;

        var resizeTextarea = function (el) {
          jQuery(el).css('height', 'auto').css('height', el.scrollHeight + offset);
        };
        jQuery(this).on('keyup input', function () { resizeTextarea(this); }).removeAttr('data-autoresize');
      });

      // $(document).ready(function () {
      //   $(".option_more").click(function () {
      //     $(".option_view").toggle();
      //   });
      // });
    }, 500);
  }

  openView(e){
    $(".option_view").toggle();
  }
}
