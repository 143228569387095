<site-header></site-header>
<!-- <ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading> -->
<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'terms_&_conditions_form.terms_&_conditions' | translate}}</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <form class="flt tb-wrapper" role="form" [formGroup]="termsForm" #myForm="ngForm"
                        (ngSubmit)="onSubmit(termsForm, myForm)">
               
                        <div class="temple_box flt">
                          
                            <div class="temple_box_cont flt">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="editor_cls flt">
                                            
                                     
                                        
                                        <angular-editor [config]="editorConfig" formControlName="description" placeholder="{{'login.text' | translate}}"></angular-editor>
                                        <div *ngIf="(f.description.touched || submitted) && f.description.errors?.required"
                                                class="invalid-feedback">
                                                <div *ngIf="f.description.errors?.required">{{'faq_form.description' | translate}} {{'faq_form.is_required' | translate}}</div>
                                            </div>
                                            <!-- <div *ngIf="(f.description.touched || submitted) && f.description.errors?.maxlength"
                                                    class="invalid-feedback">
                                            <div *ngIf="f.description.errors?.maxlength">{{'login.max' | translate}}
                                            </div>
                                            </div>  -->
                                            <div *ngIf="(f.description.touched || submitted) && f.description.errors?.pattern"
                                                class="invalid-feedback">
                                                <div *ngIf="f.description.errors?.pattern">Empty space is not allowed
                                                </div>
                                            </div>
                                 </div>
                                 </div>
                                </div>
                               
                            </div>
                        </div>
                      
                        <div class="temple_btn flt">
                          <button type="submit" class="publish">{{'privacy_policy_form.update' | translate}}</button>
                            <button type="button" (click)="cancel()" class="cancel" >{{'privacy_policy_form.cancel' | translate}}</button>
                        </div>
                    </form>
        </div>
        </div>
</div>

</section>
 
