import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, HomeService,RoleService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;
@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.css']
})
export class UserverificationComponent implements OnInit {

  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  sel_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob: any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  user_details: any;
  curPage = 1;
  perPage = 10;
  last_page: any;
  total: any;
  from:any;
  to:any;
  searchUser: any;
  apiUrl="";
  disp_type:any;
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private home: HomeService,
    private roleSer: RoleService,
    public translate: TranslateService
  
  ) {
  }
  get f() { return this.creditForm.controls; }


  

  ngOnInit() {

    const that = this;


  this.apiUrl = `${environment.apiUrl}`;
  

    this.roleSer.getUsers('','').subscribe(data => {
  
      this.users$ = data.data.users.data;
      this.curPage = data.data.users.current_page;
      this.total= data.data.users.total;
      this.from = data.data.users.from;
      this.to = data.data.users.to;
      this.last_page = data.data.users.last_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });


    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: '1'
    });


  }


  search(e){
    
    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }
 
    this.roleSer.getUsers(e.target.value,1).subscribe(data => {
      this.users$ = data.data.users.data;
      this.curPage = data.data.users.current_page;
      this.total= data.data.users.total;
      this.from = data.data.users.from;
      this.to = data.data.users.to;
      this.last_page = data.data.users.last_page;
  })

}

pageChange(e){

  this.roleSer.getUsers('',e).subscribe(data => {
    this.users$ = data.data.users.data;
    this.curPage = data.data.users.current_page;
    this.total= data.data.users.total;
    this.from = data.data.users.from;
    this.to = data.data.users.to;
    this.last_page = data.data.users.last_page;
    this.loading = false;
  }, error => {
    this.loading = false;
  });
}

deleteUser(e,user){
  var that = this;
  alertify.confirm(that.home.getTranslate('users.are_you_sure_you_want_to_delete_this_user?'), function () {
    that.roleSer.delete(user._id)
      .subscribe(
        data => {
          if (data.status.code == 0) {
            that.toastr.success(that.home.getTranslate('faq.deleted_successfully'))
          
            that.roleSer.getUsers('','').subscribe(data => {
              that.users$ = data.data.users.data;
              that.curPage = data.data.users.current_page;
              that.total= data.data.users.total;
              that.from = data.data.users.from;
              that.to = data.data.users.to;
              that.last_page = data.data.users.last_page;
              that.loading = false;
            },  error => {
              console.error(error);
            });
        
         
          } else {
            that.toastr.error(data.status.message)
          
          }
        },
        error => {
          that.toastr.error(error)
         
        });
  }
    , function () {
      // that.toastr.error('Delete action cancelled')
    }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });

}
  view(e,i){
   
      e.stopPropagation();
      $('.temple_drp_menu').css('display','none');
      $('.temple_drp_menu' + i).toggle();
      $(document).click(function(e){
       if(!$(e.target).closest('.temple_drp_btn, .temple_drp_menu').length){
       $('.temple_drp_menu').hide();
       }
     })
  }
    
 
  

  changeStatus(e,user,type){
  var txt = '';
    if(type == 1){
      this.disp_type = 1;
      var that = this;
      txt = that.home.getTranslate('user_details.are_you_sure_you_want_to_accept_this_user_request?');
    }else if(type == -1){
      this.disp_type = -1;
      var that = this;
        txt = that.home.getTranslate('user_details.are_you_sure_you_want_to_reject_this_user_request?');
    }
  
      var that = this;
      alertify.confirm(txt, function () {
      that.roleSer.status_update(user._id,that.disp_type)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.roleSer.getUsers('','').subscribe(data => {
  
                that.users$ = data.data.users.data;
              });
    if(type == 1){
     that.toastr.success(that.home.getTranslate('user_verification.success'));
      that.roleSer.getMail(user._id).subscribe(data=>{
       
        if (data.status.code == 0) {
      //    that.toastr.success('Accepted mail sent to user');
        }
      });
    }else{
      that.toastr.success(that.home.getTranslate('user_verification.error'));
      that.roleSer.getMail(user._id).subscribe(data=>{
        if (data.status.code == 0) {
        //  that.toastr.success('Rejected mail sent to user');
        }
      });
    }
              that.roleSer.getUsers('','').subscribe(data => {
  
                that.users$ = data.data.users.data;
                that.curPage = data.data.users.current_page;
                that.total= data.data.users.total;
                that.from = data.data.users.from;
                that.to = data.data.users.to;
                that.last_page = data.data.users.last_page;
                that.dtTrigger.next();
          }, error => {
            console.error(error);
          });
           
            } else {
              that.toastr.error(data.status.message)
            
            }
          },
          error => {
            that.toastr.error(error)
           
          });
    }
      , function () {
      
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });
  }

  
userDetails(e,user){
  this.router.navigate(['/user-details'], { queryParams: {id: user._id}});
}
  
  
}
