import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { RoleService, AlertService, FaqService,HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
declare var jquery: any;
declare var $: any;
declare var alertify: any;



@Component({
  selector: 'app-createfaq',
  templateUrl: './createfaq.component.html',
  styleUrls: ['./createfaq.component.css']
})
export class CreatefaqComponent implements OnInit, OnDestroy, AfterViewInit {
  adminForm: FormGroup;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;
  fileToUpload2: File = null;
  submitted = false;
  //table = false;
  cancelButton = false;
  users$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  $singleUser: any;
  media_domain = `${environment.media_domain}`;
  curPage = 1;
  perPage = 10;
profile: any;


id: any;


  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private roleSer: RoleService,
    private faqSer: FaqService,
    private alertService: AlertService,
    private home: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.adminForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      title: ['', [Validators.required,Validators.pattern(nonWhitespaceRegExp)]],
      description: ['',[Validators.required,Validators.pattern(nonWhitespaceRegExp)]]
    });

  this.route.queryParams.subscribe(params => {
    this.id = params['id'];
if(this.id){
    this.faqSer.getSingleFaq(this.id).subscribe(data => {
     this.$singleUser = data.data;



 this.adminForm.patchValue({
  index: 1,
  _id: this.$singleUser._id,
 title: this.$singleUser.title,
 description: this.$singleUser.description,
});
    })
  }
})
  }
 

 
  cancel(){
    this.router.navigate(['/faq']);
  }


  // convenience getter for easy access to form fields
  get f() { return this.adminForm.controls; }

  onSubmit(form, formWrap) {
    this.submitted = true;
    let id = form.getRawValue()._id;
   
    let index = form.getRawValue().index;

  
    console.log(this.adminForm)
    if (this.adminForm.invalid) {
      var that = this;
      that.toastr.error(that.home.getTranslate('faq_form.please_enter_all_fields'))
      return;
    }

  
  
    var that = this;
   
    if (that.id != null) {
      // 'Are you sure you want to update this FAQ?'
     


      // console.log(that.home.getTranslate('faq_form.cancel')
    alertify.confirm(that.home.getTranslate('faq_form.are_you_sure_you_want_to_update_this_faq?'), function () {
      that.faqSer.update(that.id,that.f.title.value,that.f.description.value)
        .subscribe(
          data => {
          if(data.status.code == 1){
              that.toastr.error(that.home.getTranslate('faq_form.please_enter_all_fields'))
            }
            if (data.status.code == 0) {
              that.toastr.success(that.home.getTranslate('faq_form.successfully_updated'));
              formWrap.reset();
           


            that.router.navigate(['/faq']);

            }
            else {
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.toastr.error(data.status.message)
         
            }
          },
          error => {
            that.toastr.error(error)
        
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
          });
        }, function () { 
         // that.toastr.error(that.home.getTranslate('faq_form.create_action_cancelled'))
        
        
      //  });
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });
    } else {
 
    alertify.confirm(that.home.getTranslate('faq_form.are_you_sure_you_want_to_create_this_faq?'), function () {
      that.faqSer.save(that.f.title.value,that.f.description.value)
        .subscribe(
          data => {
           
            if (data.status.code == 0) {
              that.toastr.success(that.home.getTranslate('faq_form.successfully_added'));
               that.router.navigate(['/faq']);
            
          

              that.submitted = false;
            

            }else if(data.status.code == 1){
              that.toastr.error(that.home.getTranslate('faq_form.please_enter_all_fields'))
            }
             else {
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.toastr.error(data.status.message)
         
            }
          },
          error => {
            that.toastr.error(error)
        
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
          });
        }, function () { 
         // that.toastr.error(that.home.getTranslate('faq_form.create_action_cancelled'))
        
        
      //  });
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });
    }
   

  }

  cancelSubmit(event) {
    this.imageExist = '';
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.adminForm.setValue({
      index: null,
      _id: null,
      title: '',
      description: ''
    })
    this.submitted = false;
  }
 
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
