<site-header [childMessage]="parentMessage"></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'contact.contact' | translate}}</h1>
                      
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                      
                        <div class="row">
                            <div class="col-sm-4"><h4>{{'contact.list_of_contacts' | translate}}</h4></div>
                            <div class="col-sm-4"></div>
                          <div class="col-sm-4">
                                <div class="page_search">
                                <input (keyup)="search($event)" class="form-control" type="text" name="custid" placeholder="{{'contact.search_for_a_name/email_id' | translate}}" autocomplete="off">
                                </div>
                            </div>  

                             
                        </div>
                       <br>
                       <div class="card">
                        <table cellspacing="0" id="table" class="table table-hover"
                      >
                     
                        <thead>
                            <tr>
                                <th width="10%">{{'contact.sl_no' | translate}}</th>
                                <th width="20%">{{'contact.name' | translate}}</th>
                                <th width="20%">{{'contact.email_id' | translate}}</th>
                                <th width="15%">{{'contact.mobile' | translate}}</th>
                                <th width="20%">{{'contact.created_date' | translate}}</th>
                                <th width="15%">{{'contact.reason' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="users$?.length != 0">
                            <tr *ngFor="let user of users$; let i = index">
                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                               
                                <td *ngIf="user.name;else name" class="cap_cls">{{user.name}}</td>
                                <ng-template #name>
                                    <td>-</td>
                                </ng-template>
                             
                                <td *ngIf="user.email;else email">  <a href="mailto:info@w3docs.com?cc=secondemail@example.com, anotheremail@example.com, &bcc=lastemail@example.com&subject=Mail from our Website&body=Dear W3docs Team">{{user.email}}</a></td>
                                <ng-template #email>
                                    <td>-</td>
                                </ng-template>
                                <td *ngIf="user.mobile_no;else name">{{user.mobile_no}}</td>
                                <ng-template #name>
                                    <td>-</td>
                                </ng-template>
                             
                                <td *ngIf="user.created;else email">{{user.created}} </td>
                                <ng-template #email>
                                    <td>-</td>
                                </ng-template>

                                <td>
                                    <button class="accept_cls" (click)="viewReason($event,user)" *ngIf="!user.status">{{'contact.view' | translate}} </button>
                                    <button class="accept_cls1" (click)="viewReason($event,user)" *ngIf="user.status">{{'contact.view' | translate}} </button>
                                    <div class="modal fade" id="user-republic-mdl" tabindex="-1" role="dialog" aria-labelledby="user-republic-mdl" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                          <div class="modal-content">
                                             <div class="modal-body">
                                              
<div class="row">
    <div class="col-sm-10 mdl-hd mb-3">
      <h4>    {{'contact.reason' | translate}}</h4>
      </div>
      <div class="col-sm-2">
      <button type="button" class="close" (click)="closeModal($event,user)">
        <span aria-hidden="true">&times;</span>
      </button>
      </div>
</div>
<hr>
                                              <div class="row">
                                                  
                                                <span [innerHTML]="reason"></span>    
                                              </div>
                                            </div>
                                            
                                          </div>
                                        </div>
                                     </div>
                                </td>
                            </tr>
                        </tbody>
                        <!-- <tbody *ngIf="!users$">
                            <tr>(click)="viewReason($event,user)"
                                <td colspan="4" class="no-data-available">No data!</td>
                            </tr>
                        </tbody> -->
                    </table></div>
                    <div class="no_data"  *ngIf="users$ && !users$.length">{{'contact.no_data_found' | translate}}</div>
                   
                    <div class="row paginate">
                        <div class="col-sm-8">
                        <div class="shloka_result" *ngIf="total != 0">
                            {{'login.total' | translate}} {{'contact.contact' | translate}} : {{total}} &nbsp;   {{'faq.showing' | translate}}  {{pageCounter}}-{{topage}} &nbsp;
                            {{'faq.page' | translate}} {{curPage}} / {{last_page}} 
                       </div>
                    </div>
                       <div class="col-sm-4" *ngIf="users$ && users$.length">
                        <div class="temple_pagination pg_cls">
                            <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)" style="cursor: pointer;"><i
                                    class="fas fa-step-backward"></i></a> {{'contact.page' | translate}}
                            <span>{{curPage}}</span> / {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                class="forward" (click)="pageChange(curPage+1)" style="cursor: pointer;"><i class="fas fa-step-forward"></i></a>
                        </div>
                    </div>
                    </div>
            
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>