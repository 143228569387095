<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'chat.history' | translate}}</h1>
                      
                    </div>
                </div>
            </div>
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                     
                       <div class="card">
                        <table cellspacing="0" id="table" class="table table-hover"
                       >
                      

                        <thead>
                            <tr>
                                <th width="20%">{{'news.sl_no' | translate}}</th>
                                <th width="20%">{{'user_details.name' | translate}}</th>
                                <th width="30%">{{'login.login' | translate}}</th>
                                <th width="30%">{{'logout.logout' | translate}}</th>
                             
                               
                            </tr>
                        </thead>
                        <tbody *ngIf="history?.length != 0">
                            <tr *ngFor="let user of history; let i = index">
                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                              
                          <td *ngIf="user.userId.name;else mapId" class="cap_cls"><span class="desc_cls">{{user.userId.name}}</span></td>
                                <ng-template #mapId>
                                    <td>-</td>
                                </ng-template>
                               
                                
                           

                                <td> {{user.login_time}} </td>
                              
                              <td> 
                                {{user.logout_time}}
                                  
                            </td>
                              
                            </tr>
                        </tbody>
                       
                    </table>
                    </div>
                    <div class="no_data"  *ngIf="history && !history.length">{{'news.no_data_found' | translate}}</div>
                   
                   


                    <div class="row paginate">
                        <div class="col-sm-8">
                        <div class="shloka_result" *ngIf="total != 0">
                                                 
{{'login.total' | translate}} {{'users.users' | translate}} :  {{total}}  &nbsp;  {{'news.showing' | translate}} {{pageCounter}}-{{topage}} &nbsp;  {{'news.page' | translate}} {{curPage}} / {{last_page}}                     
                       </div>
                          </div>
                       <div class="col-sm-4" *ngIf="history && history.length">
                        <div class="temple_pagination pg_cls">
                            <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)"><i
                                    class="fas fa-step-backward"></i></a>  {{'news.page' | translate}}
                            <span>{{curPage}}</span> {{'news.of' | translate}} {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                class="forward" (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                        </div>
                    </div>
                    </div>

                    </div>
                </div>
            </div>
        </section>

      
    </div>
</div>