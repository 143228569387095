<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }
</style>


<div class="wrapper">
    <div class="content-wrapper">

        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{'user_details.user_details' | translate}}</h1>
                    </div>
                    <div class="col-sm-6" *ngIf="users$ && users$.status == 0">
                        <span class="verif_cls"><span class="usertxt_cls">{{'user_details.user_verification_status' | translate}} :</span>
                            <a class="accept_cls" (click)="changeStatus($event,users$,1)" style="cursor: pointer;"><i
                                    class="far fa-check-circle"></i>&nbsp;{{'user_details.accept' | translate}}
                            </a>
                            <a class="reject_cls" (click)="changeStatus($event,users$,-1)" style="cursor: pointer;"><i
                                    class="far fa-times-circle"></i> &nbsp;{{'user_details.reject' | translate}}
                            </a>
                        </span>
                    </div>
                    <div class="col-sm-6 verification_cls"
                        *ngIf="users$ && users$.status == 1 || users$ && users$.status == -1">

                        <span class="verif_cls"><span class="usertxt_cls">{{'user_details.user_verification_status' | translate}} :</span>
                            <span *ngIf="users$ && users$.status == 1" class="usertxt_cls">{{'user_details.accepted' | translate}}</span>
                            <span *ngIf="users$ && users$.status == -1" class="usertxt_cls">{{'user_details.rejected' | translate}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">


                        <div class="panel fixed_height">
                            <div class="panel-body">

                                <form [formGroup]="userForm" #myForm="ngForm" class="">
                                    <fieldset [disabled]="fldset">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.name' | translate}} : </div>

                                                    <input autocomplete="off" type="text" class="form-control det_cls"
                                                        formControlName="name"
                                                        [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">

                                                </div>
                                                <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.name.errors?.required">{{'user_details.name' | translate}} {{'faq_form.is_required' | translate}}</div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="details_cls ">
                                                    <div class="title_cls">{{'user_details.email' | translate}} : </div>

                                                    <input autocomplete="off" type="email" class="form-control det_cls"
                                                        formControlName="email"
                                                        [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }">

                                                </div>
                                                <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.email.errors?.required">{{'user_details.email' | translate}} {{'faq_form.is_required' | translate}}</div>
                                                </div>
                                                <div *ngIf="(f.email.touched || submitted) && f.email.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.email.errors?.pattern">{{'users.enter_valid_email_pattern' | translate}}</div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.band_name' | translate}} : </div>

                                                    <input autocomplete="off" type="text" class="form-control det_cls"
                                                        formControlName="band_name"
                                                        [ngClass]="{ 'is-invalid': (f.band_name.touched || submitted) && f.band_name.errors }">
                                                </div>
                                                <div *ngIf="(f.band_name.touched || submitted) && f.band_name.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.band_name.errors?.required">{{'user_details.band_name' | translate}} {{'user_details.is_required' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.user_id' | translate}}: </div>

                                                    <input autocomplete="off" type="text" class="form-control det_cls"
                                                        formControlName="userID"
                                                        [ngClass]="{ 'is-invalid': (f.userID.touched || submitted) && f.userID.errors }">
                                                </div>
                                                <div *ngIf="(f.userID.touched || submitted) && f.userID.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.userID.errors?.required">{{'user_details.user_id' | translate}} {{'user_details.is_required' | translate}}</div>
                                                </div>
                                            </div>




                                        </div>
                                    </fieldset>



                                    <fieldset [disabled]="fldset">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.affiliation_organization' | translate}} : </div>

                                                    <input autocomplete="off" type="text" class="form-control det_cls"
                                                        formControlName="affiliation_organization"
                                                        [ngClass]="{ 'is-invalid': (f.affiliation_organization.touched || submitted) && f.affiliation_organization.errors }">
                                                </div>
                                                <div *ngIf="(f.affiliation_organization.touched || submitted) && f.affiliation_organization.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.affiliation_organization.errors?.required">{{'user_details.affiliation_organization' | translate}} {{'user_details.is_required' | translate}}</div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.members_name' | translate}} : </div>

                                                    <input autocomplete="off" type="text" class="form-control det_cls"
                                                        formControlName="member_name"
                                                        [ngClass]="{ 'is-invalid': (f.member_name.touched || submitted) && f.member_name.errors }">
                                                </div>
                                                <div *ngIf="(f.member_name.touched || submitted) && f.member_name.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.member_name.errors?.required">{{'user_details.members_name' | translate}} {{'user_details.is_required' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.urls_of_youtube_and_various_sns' | translate}} : </div>

                                                    <input autocomplete="off" type="text" class="form-control det_cls"
                                                        formControlName="url_youtube_sns"
                                                        [ngClass]="{ 'is-invalid': (f.url_youtube_sns.touched || submitted) && f.url_youtube_sns.errors }">
                                                </div>
                                                <div *ngIf="(f.url_youtube_sns.touched || submitted) && f.url_youtube_sns.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.url_youtube_sns.errors?.required">{{'user_details.urls_of_youtube_and_various_sns' | translate}} {{'user_details.is_required' | translate}}</div>
                                                </div>
                                                <div *ngIf="(f.url_youtube_sns.touched || submitted) && f.url_youtube_sns.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.url_youtube_sns.errors?.pattern">{{'users.enter_valid_url_pattern' | translate}}</div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.url_of_performance_video_and_sound_source' | translate}} :
                                                    </div>

                                                    <input autocomplete="off" type="text" class="form-control det_cls"
                                                        formControlName="video_audio_url"
                                                        [ngClass]="{ 'is-invalid': (f.video_audio_url.touched || submitted) && f.video_audio_url.errors }">
                                                </div>
                                                <div *ngIf="(f.video_audio_url.touched || submitted) && f.video_audio_url.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.video_audio_url.errors?.required">{{'user_details.url_of_performance_video_and_sound_source' | translate}} {{'user_details.is_required' | translate}}</div>
                                                </div>
                                                <div *ngIf="(f.video_audio_url.touched || submitted) && f.video_audio_url.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.video_audio_url.errors?.pattern">
                                                        {{'users.enter_valid_url_pattern' | translate}}</div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.band_introduction' | translate}} : </div>

                                                    <textarea autocomplete="off" type="text" class="det_cls"
                                                        formControlName="band_introduction"
                                                        [ngClass]="{ 'is-invalid': (f.band_introduction.touched || submitted) && f.band_introduction.errors }"></textarea>
                                                </div>
                                                <div *ngIf="(f.band_introduction.touched || submitted) && f.band_introduction.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.band_introduction.errors?.required">{{'user_details.band_introduction' | translate}} {{'user_details.is_required' | translate}}</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.remarks' | translate}} : </div>

                                                    <textarea autocomplete="off" type="text" class="det_cls"
                                                        formControlName="remarks"
                                                        [ngClass]="{ 'is-invalid': (f.remarks.touched || submitted) && f.remarks.errors }"></textarea>
                                                </div>
                                                <div *ngIf="(f.remarks.touched || submitted) && f.remarks.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.remarks.errors?.required">{{'user_details.remarks' | translate}} {{'user_details.is_required' | translate}}</div>
                                                </div>
                                            </div>





                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.event_registration' | translate}} : </div>


                                                    <div class="col" *ngIf="users$ && users$.event_reg == 1">
                                                        {{'users.yes' | translate}}
                                                      
                                                    </div>
                                                    <div class="col" *ngIf="users$ && users$.event_reg != 1">
                                                        {{'users.no' | translate}}
                
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="details_cls">
                                                    <div class="title_cls">{{'user_details.room_no' | translate}} : </div>


                                                    <div class="col" *ngIf="users$ && !users$.room_no">{{'users.no' | translate}}</div>
                                                    <div class="col" *ngIf="users$ && users$.room_no != 0 ">{{users$ &&
                                                        users$.room_no}}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </fieldset>
                                    <div class="row">

                                        <div class="col-sm-6">
                                            <div class="details_cls">
                                                <div class="title_cls">{{'user_details.state' | translate}} : </div>
                                                <ng-container *ngIf="users$ && users$.status == 1">
                                                    <ng-multiselect-dropdown [placeholder]="''"
                                                        [settings]="dropdownSettings" [data]="states"
                                                        [(ngModel)]="selectedItems" formControlName="state"
                                                        (onSelect)="onItemSelect($event)"
                                                        (onSelectAll)="onSelectAll($event)"
                                                        (onDeSelect)="onDeSelect($event)" class="det_cls">
                                                    </ng-multiselect-dropdown>
                                                </ng-container>
                                                <!-- *ngIf="users$ && users$.status != 1" -->
                                                <div class="state" *ngIf="users$ && users$.status != 1">
                                                    <div *ngFor="let st of selectedItems" class="state_box">
                                                        <div class="state_in">{{st.state}}</div>
                                                    </div>

                                                </div>

                                                <!-- <select *ngIf="users$ && users$.status != 1" class="form-control det_cls">
        <option *ngFor="let st of selectedItems">{{st.state}}</option>
    </select> -->
                                            </div>
                                            <div *ngIf="(f.state.touched || submitted) && f.state.errors?.required"
                                                class="invalid-feedback">
                                                <div *ngIf="f.state.errors?.required">{{'user_details.state' | translate}} {{'user_details.is_required' | translate}}</div>
                                            </div>
                                        </div>


                                        <div class="col-sm-6">
                                            <div class="details_cls">
                                                <div class="title_cls">{{'user_details.occupation' | translate}} : </div>
                                                <ng-container *ngIf="users$ && users$.status == 1">
                                                    <ng-multiselect-dropdown [placeholder]="''"
                                                        [settings]="dropdownSettings1" [data]="occupation"
                                                        [(ngModel)]="selectedItems1" formControlName="occupation"
                                                        (onSelect)="onItemSelect1($event)"
                                                        (onSelectAll)="onSelectAll1($event)"
                                                        (onDeSelect)="onDeSelect1($event)" class="det_cls">
                                                    </ng-multiselect-dropdown>
                                                </ng-container>



                                                <label class="lbl_txt" *ngIf="users$ && users$.status != 1">
                                                    <div *ngFor="let oc of selectedItems1">
                                                        <p>{{oc.occupation}}</p>
                                                    </div>

                                                </label>
                                            </div>
                                            <div *ngIf="(f.occupation.touched || submitted) && f.occupation.errors?.required"
                                                class="invalid-feedback">
                                                <div *ngIf="f.occupation.errors?.required">{{'user_details.occupation' | translate}} {{'user_details.is_required' | translate}}</div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="details_cls">
                                                <div class="title_cls sng_cls">{{'user_details.song_name_and_artist_name_of_the_sound_source_for_judging' | translate}} : </div>
                                                <input autocomplete="off" type="text" class="form-control"
                                                    formControlName="user_source_for_judging"
                                                    [ngClass]="{ 'is-invalid': (f.user_source_for_judging.touched || submitted) && f.user_source_for_judging.errors }">

                                            </div>
                                            <div *ngIf="(f.user_source_for_judging.touched || submitted) && f.user_source_for_judging.errors?.required"
                                                class="invalid-feedback">
                                                <div *ngIf="f.user_source_for_judging.errors?.required">{{'user_details.song_name_and_artist_name_of_the_sound_source_for_judging' | translate}} {{'user_details.is_required' | translate}}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="details_cls1">
                                                <div class="error_txtcls">
                                                <div class="title_cls">{{'user_details.band_photo' | translate}} : </div>



                                                <div class="upload-btn-wrapper">

                                                    <button class="btn">
                                                        <div class="mask">

                                                        </div>
                                                        <img class="img-responsive img_cls"
                                                            src="/assets/images/default.jpg" name="image"
                                                            *ngIf="!image_band">
                                                        <img class="img-responsive img_cls" [src]="band_preview"
                                                            name="image" *ngIf="image_band">

                                                        <div class="view_btn band_cls"
                                                            *ngIf="users$ && users$.status == 1">

                                                            <a class="viewbtn_cls" (click)="vewBtn($event,users$)"
                                                                *ngIf="image_band"><i class="fas fa-eye"></i></a>


                                                            <a (click)="uploadImage1($event)"> <a class="editbtn_cls"><i
                                                                        class="fas fa-upload"></i> </a> </a>
                                                            <a class="dltbtn_cls" (click)="clearBand($event)"
                                                                *ngIf="image_band"><i class="fas fa-trash-alt"></i></a>



                                                        </div>
                                                        <div class="view_btn band_cls"
                                                            *ngIf="users$ && users$.status != 1">

                                                            <a class="viewbtn_cls2" (click)="vewBtn($event,users$)"><i
                                                                    class="fas fa-eye"></i></a>
                                                        </div>

                                                    </button>

                                                    <input name="upload" type="file" id="exampleInputEmail"
                                                        formControlName="band_photo" accept="image/*"
                                                        (change)="fileChooseBand($event,$event.target.files)" />

                                                    <!-- <a (click)="clearBand($event)" *ngIf="image_band"><i class="fas fa-times-circle"></i></a> -->



                                                </div>
                                                
                                               

                                                <div class="modal fade" id="user-republic-mdl" tabindex="-1"
                                                    role="dialog" aria-labelledby="user-republic-mdl"
                                                    aria-hidden="true">
                                                    <div class="modal-dialog modal-lg" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-body">

                                                                <div class="row">
                                                                    <div class="col-sm-10 mdl-hd mb-3">
                                                                        <h4>{{'user_details.band_photo' | translate}}</h4>
                                                                    </div>
                                                                    <div class="col-sm-2">
                                                                        <button type="button" class="close"
                                                                            (click)="closeModal($event)">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                                <div class="row">
                                                                    <img class="img-responsive img_cls"
                                                                        [src]="band_preview" name="image"
                                                                        *ngIf="image_band">

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="error_cls">写真サイズは1280×960でお願いします。</div>
                                        </div>
                                        </div>
                                       
                                     
                                    </div>


                                    <hr>




                                    <!-- <hr> -->




                                    <div *ngIf="(users$ && users$.event_reg == 1) && (users$ && users$.status == 1)">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <h4>{{'user_details.event_registration' | translate}}</h4>
                                            </div>
                                            <div class="col-sm-6" *ngIf="users$ && users$.event_accept == 0">
                                                <span class="verif_cls"><span class="usertxt_cls">{{'user_details.event_verification_status' | translate}}
                                                         :</span>
                                                    <a class="accept_cls" (click)="changeEvent($event,users$,1)" style="cursor: pointer;"><i
                                                            class="far fa-check-circle"></i>&nbsp;{{'user_details.accept' | translate}}
                                                    </a>
                                                    <a class="reject_cls" (click)="changeEvent($event,users$,-1)" style="cursor: pointer;"><i
                                                            class="far fa-times-circle"></i> &nbsp;{{'user_details.reject' | translate}}
                                                    </a>
                                                </span>

                                            </div>
                                            <div class="col-sm-6 verification_cls"
                                                *ngIf="users$ && users$.event_accept == 1 || users$ && users$.event_accept == -1">

                                                <span class="verif_cls"><span class="usertxt_cls">{{'user_details.event_verification_status' | translate}}
                                                         :</span>
                                                    <span *ngIf="users$ && users$.event_accept == 1"
                                                        class="usertxt_cls">{{'user_details.accepted' | translate}}</span>
                                                    <span *ngIf="users$ && users$.event_accept == -1"
                                                        class="usertxt_cls">{{'user_details.rejected' | translate}}</span>
                                                </span>
                                            </div>

                                        </div>

                                        <br>

                                        <div class="row">
                                            <div class="col-sm-4">
                                                <div class="details_cls">

                                                    <div class="video_cls">
                                                        <label>{{'user_details.url_1_of_sound_source' | translate}}</label>

                                        <fieldset [disabled]="fldset1">
                                                        <input autocomplete="off" type="text"
                                                            class="form-control url_cls" formControlName="url_sound1"
                                                            (change)="valuechange1($event,users$)">
</fieldset>
                                                        <div *ngIf="users$ && users$.youtubeid1">

                                                            <!-- <iframe src="{{ytube1}}"></iframe> -->
                                                            <div class="vdo_player">
                                                                <img class="url_img" [src]="ytube1">
                                                                <a (click)="urlClick($event,users$,1)">
                                                                    <i class="far fa-play-circle"></i>
                                                                </a>
                                                            </div>


                                                        </div>



                                                        <div *ngIf="users$ && users$.vimeoid1">
                                                            <!-- <iframe [src]="vimeo1"></iframe> -->

                                                            <div class="vdo_player">
                                                                <img class="url_img" [src]="vimeo1">
                                                                <a (click)="urlClick($event,users$,4)">
                                                                    <i class="far fa-play-circle"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="(f.url_sound1.touched || submitted) && f.url_sound1.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.url_sound1.errors?.required">{{'user_details.url_1_of_sound_source' | translate}} {{'user_details.is_required' | translate}}</div>
                                                </div>
                                                <div *ngIf="(f.url_sound1.touched || submitted) && f.url_sound1.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.url_sound1.errors?.pattern">{{'room.pattern' | translate}}</div>
                                                </div>
                                            </div>


                                            <div class="col-sm-4">
                                                <div class="details_cls">

                                                    <div class="video_cls">
                                                        <label>{{'user_details.url_2_of_sound_source' | translate}}</label>
                                                        <fieldset [disabled]="fldset1">
                                                            <input autocomplete="off" type="text"
                                                                class="form-control url_cls"
                                                                formControlName="url_sound2"
                                                                (change)="valuechange2($event,users$)">
                                                        </fieldset>
                                                        <div *ngIf="users$ && users$.youtubeid2">
                                                            <!-- <iframe [src]="ytube2"></iframe> -->

                                                            <div class="vdo_player">
                                                                <img class="url_img" [src]="ytube2">
                                                                <a (click)="urlClick($event,users$,2)">
                                                                    <i class="far fa-play-circle"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="users$ && users$.vimeoid2">
                                                            <!-- <iframe [src]="vimeo2"></iframe> -->

                                                            <div class="vdo_player">
                                                                <img class="url_img" [src]="vimeo2">
                                                                <a (click)="urlClick($event,users$,5)">
                                                                    <i class="far fa-play-circle"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div *ngIf="(f.url_sound2.touched || submitted) && f.url_sound2.errors?.required"
        class="invalid-feedback">
        <div *ngIf="f.url_sound2.errors?.required">URL of the sound source for examination 2 is required</div>
      </div> -->
                                                <div *ngIf="(f.url_sound2.touched || submitted) && f.url_sound2.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.url_sound2.errors?.pattern">{{'room.pattern' | translate}}</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="details_cls">

                                                    <div class="video_cls">
                                                        <label>{{'user_details.url_3_of_sound_source' | translate}}</label>
                                                        <fieldset [disabled]="fldset1">
                                                            <input autocomplete="off" type="text"
                                                                class="form-control url_cls"
                                                                formControlName="url_sound3"
                                                                (change)="valuechange3($event,users$)">
                                                        </fieldset>
                                                        <div *ngIf="users$ && users$.youtubeid3">
                                                            <!-- <iframe [src]="ytube2"></iframe> -->

                                                            <div class="vdo_player">
                                                                <img class="url_img" [src]="ytube3">
                                                                <a (click)="urlClick($event,users$,3)">
                                                                    <i class="far fa-play-circle"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="users$ && users$.vimeoid3">
                                                            <!-- <iframe [src]="vimeo2"></iframe> -->

                                                            <div class="vdo_player">
                                                                <img class="url_img" [src]="vimeo3">
                                                                <a (click)="urlClick($event,users$,6)">
                                                                    <i class="far fa-play-circle"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div *ngIf="(f.url_sound3.touched || submitted) && f.url_sound3.errors?.required"
        class="invalid-feedback">
        <div *ngIf="f.url_sound3.errors?.required">URL of the sound source for examination 3 is required</div>
      </div> -->
                                                <div *ngIf="(f.url_sound3.touched || submitted) && f.url_sound3.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.url_sound3.errors?.pattern">{{'room.pattern' | translate}}</div>
                                                </div>

                                            </div>


                                        </div>

                                        <fieldset [disabled]="fldset1">
                                            <div class="row">



                                              
                                               

                                                <div class="col-sm-6">
                                                    <div class="details_cls">
                                                        <div class="title_cls sng_cls">{{'user_details.audio_file_duration' | translate}} : </div>
                                                        <select class="form-control"
                                                        formControlName="audio_file_duration"
                                                        [ngClass]="{ 'is-invalid': (f.audio_file_duration.touched || submitted) && f.audio_file_duration.errors }">
                                                            <option value="5分">5 {{'user_details.minutes' | translate}}</option>
                                                            <option value="10分">10 {{'user_details.minutes' | translate}}</option>
                                                            <option value="15分">15 {{'user_details.minutes' | translate}}</option>
                                                        </select>
                                                        <!-- <input autocomplete="off" type="text" class="form-control"
                                                            formControlName="audio_file_duration"
                                                            [ngClass]="{ 'is-invalid': (f.audio_file_duration.touched || submitted) && f.audio_file_duration.errors }"> -->

                                                    </div>
                                                    <div *ngIf="(f.audio_file_duration.touched || submitted) && f.audio_file_duration.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.audio_file_duration.errors?.required">{{'user_details.audio_file_duration' | translate}} {{'user_details.is_required' | translate}}
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-sm-6">
                                                    <div class="details_cls">
                                                        <div class="title_cls sng_cls">{{'user_details.other_url' | translate}} : </div>
                                                        <input autocomplete="off" type="text" class="form-control"
                                                            formControlName="other_url"
                                                            [ngClass]="{ 'is-invalid': (f.other_url.touched || submitted) && f.other_url.errors }">

                                                    </div>
                                                    <div *ngIf="(f.other_url.touched || submitted) && f.other_url.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.other_url.errors?.required">{{'user_details.other_url' | translate}} {{'user_details.is_required' | translate}}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="details_cls">
                                                        <div class="title_cls sng_cls sng_cls1">{{'user_details.event_remarks' | translate}} : </div>
                                                        <textarea autocomplete="off" type="text" class="form-control"
                                                            formControlName="event_remarks"
                                                            [ngClass]="{ 'is-invalid': (f.event_remarks.touched || submitted) && f.event_remarks.errors }"></textarea>

                                                    </div>
                                                    <div *ngIf="(f.event_remarks.touched || submitted) && f.event_remarks.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.event_remarks.errors?.required">{{'user_details.event_remarks' | translate}} {{'user_details.is_required' | translate}}</div>
                                                    </div>
                                                    <div *ngIf="(f.event_remarks.touched || submitted) && f.event_remarks.errors?.pattern"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.event_remarks.errors?.pattern">Enter valid audio
                                                            url pattern</div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="details_cls">
                                                        <div class="title_cls sng_cls">{{'user_details.song_name_and_artist_name_of_the_sound_source_for_judging' | translate}}: </div>
                                                        <input autocomplete="off" type="text" class="form-control"
                                                            formControlName="event_source_for_judging"
                                                            [ngClass]="{ 'is-invalid': (f.event_source_for_judging.touched || submitted) && f.event_source_for_judging.errors }">

                                                    </div>
                                                    <div *ngIf="(f.event_source_for_judging.touched || submitted) && f.event_source_for_judging.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.event_source_for_judging.errors?.required">{{'user_details.song_name_and_artist_name_of_the_sound_source_for_judging' | translate}} {{'user_details.is_required' | translate}}
                                                        </div>
                                                    </div>

                                                </div>
                                             
                                              
                                            </div>
                                        </fieldset>
                                        <hr>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <h5>{{'user_details.members' | translate}} </h5>
                                            </div>

                                            <div class="col-sm-6 addimg_cls" *ngIf="users$ && users$.event_accept == 1">

                                                <label for="exampleInputEmail1">+ {{'user_details.add_members' | translate}}</label>


                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4" style="display: none;"
                                                [ngClass]="{ 'hidefile': first_preview }"
                                                *ngFor="let images of first_preview;let i = index">
                                                <div class="row">
                                                    <div class="upload-btn-wrapper">
                                                        <img class="img-responsive img_cls" [src]="images" name="image">
                                                    </div>

                                                </div>
                                                <br>
                                            </div>
                                          
                                            <input name="upload" type="file" id="exampleInputEmail1"
                                                formControlName="profile1" accept="image/*"
                                                (change)="fileChoose($event,1)" class="d-none" />
                                            <div class="col-sm-4" *ngFor="let user of users$.members;let i = index">
                                                <div class="row">
                                                    <div class="upload-btn-wrapper">
                                                        <div class="mask">

                                                        </div>

                                                        <img class="img-responsive img_cls"
                                                            [src]="media_domain+user.profile" name="image">

                                                        <div class="view_btn band_cls1"
                                                            *ngIf="users$ && users$.event_accept == 1">
                                                            <a class="viewbtn_cls1" (click)="vewBtn1($event,user)"><i
                                                                    class="fas fa-eye"></i></a>

                                                            <a class="dltbtn_cls1" (click)="clear($event,1,user)"><i
                                                                    class="fas fa-trash-alt"></i></a>

                                                        </div>
                                                        <div class="view_btn band_cls1"
                                                            *ngIf="users$ && users$.event_accept != 1">
                                                            <a class="viewbtn_cls3" (click)="vewBtn1($event,user)"><i
                                                                    class="fas fa-eye"></i></a>



                                                        </div>


                                                    </div>

                                                </div>
                                               <br>
                                            </div>
                                           

                                            <div class="modal fade" id="user-republic-mdl1" tabindex="-1" role="dialog"
                                                aria-labelledby="user-republic-mdl" aria-hidden="true">
                                                <div class="modal-dialog modal-lg" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-body">

                                                            <div class="row">
                                                                <div class="col-sm-10 mdl-hd mb-3">
                                                                    <h4>{{'user_details.member_image' | translate}}</h4>
                                                                </div>
                                                                <div class="col-sm-2">
                                                                    <button type="button" class="close"
                                                                        (click)="closeModal1($event)">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div class="row">
                                                                <img class="img-responsive img_cls" [src]="mem_image"
                                                                    name="image">

                                                            </div>
                                                          
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-2" *ngIf="users$ && users$.status == 1">

                                            <button class="btn_cls" *ngIf="id"
                                                (click)="onSubmit($event,userForm, myForm)">{{'user_details.update' | translate}}</button>

                                        </div>
                                        <div class="col-md-2">
                                            <button type="submit" class="btn_white" (click)="cancel()">{{'user_details.cancel' | translate}}</button>
                                        </div>
                                    </div>
                                </form>


                            </div>





                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>