import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class RoomService {
    constructor(private http: HttpClient) { }



    update(id: any,band_name:any,member_name:any,address:any,genre: any,bio:any,
        band_photo,song_name_artist_name1:any,song_name_artist_name2:any,
        song_name_artist_name3:any,twitter:any, instagram:any,youtube:any,room_details:any){
        const formData: FormData = new FormData();
        formData.append('band_name', band_name);
        formData.append('address', address);
        formData.append('genre', genre);
        formData.append('bio', bio);
        formData.append('member_name', member_name);
        if (band_photo && band_photo.name) {
            formData.append('band_photo',band_photo, band_photo.name);
        }
        formData.append('twitter', twitter);
        formData.append('instagram', instagram);
        formData.append('youtube', youtube);

        formData.append('song_name_artist_name1', song_name_artist_name1);
        formData.append('song_name_artist_name2', song_name_artist_name2);
        formData.append('song_name_artist_name3', song_name_artist_name3);
       
        formData.append('room_details', room_details);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/room_update/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/room/deleteroom/` + id).pipe(map(user => {
            return user;
        }));

    }


    getSingleRoom(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/room/getroom/` + id).pipe(map(user => user));

    }
}
