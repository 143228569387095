import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BannerService {
    constructor(private http: HttpClient) { }



    save(link:any,image:File){
        const formData: FormData = new FormData();
        formData.append('link', link);
        if (image) {
            formData.append('image',image, image.name);
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banner`, formData).pipe(map(user => {
            return user;
        }));
    }

    update(id:any,link:any,image:File){
        const formData: FormData = new FormData();
        formData.append('link', link);
        if (image && image.name) {
            formData.append('image',image, image.name);
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banner/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banner/` + id).pipe(map(user => {
            return user;
        }));

    }
    getBanner(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banner?search=`+search+'&page='+page).pipe(map(user => user));

    }

    getSingleBanner(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banner/` + id).pipe(map(user => user));

    }


// rigtside banner


saveadd(link:any,image:File){
    const formData: FormData = new FormData();
    formData.append('link', link);
    if (image) {
        formData.append('image',image, image.name);
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banneradds`, formData).pipe(map(user => {
        return user;
    }));
}

updateadd(id:any,link:any,image:File){
    const formData: FormData = new FormData();
    formData.append('link', link);
    if (image && image.name) {
        formData.append('image',image, image.name);
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banneradds/` + id, formData).pipe(map(user => {
        return user;
    }));
}

deleteadd(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banneradds/` + id).pipe(map(user => {
        return user;
    }));

}
getBanneradd(search:any,page:any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banneradds?search=`+search+'&page='+page).pipe(map(user => user));

}

getSingleBanneradd(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/banneradds/` + id).pipe(map(user => user));

}


}
