import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { SettingsService,HomeService } from '../_services';
import { ToastrService } from 'ngx-toastr';
import { trim } from 'jquery';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit {
  settingForm: FormGroup;
  loading = false;
  submitted = false;
  isResetFlag: boolean = false;
  constructor(
    private setting: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private home: HomeService
  ) { }

  static areEqual(c: AbstractControl): ValidationErrors | null {
    const keys: string[] = Object.keys(c.value);
    for (const i in keys) {
      if (i !== '0' && c.value['new_password'] !== c.value['confirm_password']) {
      return { areEqual: true };
        
      }
     // return false;
    }
    
  }

  ngOnInit() {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    this.settingForm = this.formBuilder.group({
      upd_username: ['', [Validators.required, Validators.maxLength(100), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      is_reset_password: '',
      upd_password: ['', Validators.required,Validators.pattern(nonWhitespaceRegExp)],
      passwords: new FormGroup({
        new_password: new FormControl(),
        confirm_password: new FormControl()
      }, SettingsComponent.areEqual)
    });
  }
  get f(): any {
    return this.settingForm.controls;
  }

  handleChange(event): void {
    const newPassCtrl = this.settingForm.controls['passwords'].get('new_password');
    const reTypePassCtrl = this.settingForm.controls['passwords'].get('confirm_password');
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    if (event.target.checked) {
      newPassCtrl.setValidators([Validators.required, Validators.maxLength(100),Validators.pattern(nonWhitespaceRegExp)]);
      newPassCtrl.updateValueAndValidity();
      reTypePassCtrl.setValidators([Validators.required, Validators.maxLength(100),Validators.pattern(nonWhitespaceRegExp)]);
      reTypePassCtrl.updateValueAndValidity();

      this.isResetFlag = true;
      newPassCtrl.enable();
      reTypePassCtrl.enable();
    } else {
      newPassCtrl.clearValidators();
      newPassCtrl.updateValueAndValidity();
      reTypePassCtrl.clearValidators();
      reTypePassCtrl.updateValueAndValidity();

      this.isResetFlag = false;
      newPassCtrl.disable();
      reTypePassCtrl.disable();
    }
  }
  onSubmit(form, formWrap) {
    this.submitted = true;
    if (this.settingForm.invalid) {
      return;
    }

    document.body.classList.add('jw-modal-open');
    var that = this;

    if(that.f.passwords['controls'].new_password.value != that.f.passwords['controls'].confirm_password.value){
     that.toastr.error(that.home.getTranslate('settings.passwords_do_not_match'));
      return false;
    }

if(trim(that.f.passwords['controls'].new_password.value) == ""){
  that.toastr.error(that.home.getTranslate('faq.white_space'));
      return false;
}

    if(that.f.upd_username.value == ""){
      that.toastr.error(that.home.getTranslate('settings.email') +' '+ that.home.getTranslate('faq_form.is_required'));
      return false;
    }

    alertify.confirm(that.home.getTranslate('settings.are_you_sure_you_want_to_update_this_record?'), function () {
      that.setting.save(
      that.f.upd_username.value, that.f.is_reset_password.value, that.f.upd_password.value, that.f.passwords['controls'].new_password.value)
      .subscribe(
        data => {
          if (data.status.code == 0) {
            that.submitted = false;
            that.isResetFlag = false;
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
            formWrap.reset();
            that.toastr.success(that.home.getTranslate('app_settings.profile_updated_successfully.........'))

            // alertify.success(data.status.message)
          } else {
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
            that.toastr.error(that.home.getTranslate('app_settings.invalid_old_password...'))
            // alertify.error(data.status.message)
          }
        },
        error => {
          that.toastr.error(error)
          // alertify.error(error)
          document.body.classList.remove('jw-modal-open');
          that.loading = false;
        });
      }, function () { 
     //   that.toastr.error('Update action cancelled')
      
      
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      $('.username, .password').val('');
    }, 500);
  }
}

