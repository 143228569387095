import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { AlertService,HomeService,FaqService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { data } from 'jquery';
import { TranslateService } from '@ngx-translate/core';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  faq$:any;
  loading = false;
  curPage = 1;
  perPage = 10;
  last_page: any;
  status_text = '';
  search_data= '';
  search_status='';
  total: any;
  from:any;
  datefrom:any;
  to:any;
  from_date:any;
  to_date:any;
  searchUser: any;
  pro:any;
items: any;
provider:any;
users$: any;
dateto:any;
pro$: any;
limit:any;
pageCounter:any;
topage:any;
page:any;
current_date :any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtTrigger2: Subject<any> = new Subject();

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private faq: FaqService,
    private home: HomeService,
    public translate: TranslateService
  ) { }
    type = '1';




  ngOnInit() {

    this.faq.getFaq('','').subscribe(data => {
      this.faq$ = data.data.faq.docs;
      this.curPage = data.data.faq.page;
      this.total= data.data.faq.totalDocs;
      this.from = data.data.faq.prevPage;
      this.to = data.data.faq.nextPage;
      this.last_page = data.data.faq.totalPages;
      this.limit = data.data.faq.limit;
      this.page = data.data.faq.page;
      this.pageCounter = data.data.faq.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.faq$.length;
      
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  

  }


  view(e,i){
   
    e.stopPropagation();
    $('.temple_drp_menu').css('display','none');
    $('.temple_drp_menu' + i).toggle();
    $(document).click(function(e){
     if(!$(e.target).closest('.temple_drp_btn, .temple_drp_menu').length){
     $('.temple_drp_menu').hide();
     }
   })
}
  

editFaq(e,user){
    this.router.navigate(['/create-faq'], { queryParams: {id: user._id}})
  }

  add(e){
    this.router.navigate(['/create-faq'])
  }

 

  ngAfterViewInit() {
   
  }

  deleteFaq(e,user){
    var that = this;
    alertify.confirm(that.home.getTranslate('faq.are_you_sure_you_want_to_delete_this_faq?'), function () {
      that.faq.delete(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success(that.home.getTranslate('faq.deleted_successfully'))
            
              that.faq.getFaq('',that.curPage).subscribe(data => {
  

                that.faq$ = data.data.faq.docs;
                that.curPage = data.data.faq.page;
                that.total= data.data.faq.totalDocs;
                that.from = data.data.faq.prevPage;
                that.to = data.data.faq.nextPage;
                that.last_page = data.data.faq.totalPages;
                that.limit = data.data.faq.limit;
                that.page = data.data.faq.page;
                that.pageCounter = data.data.faq.pagingCounter;
                that.topage = (that.limit * (that.page - 1)) + that.faq$.length;
               
                that.dtTrigger.next();
              }, error => {
                console.error(error);
              });
          
           
            } else {
              that.toastr.error(data.status.message)
            
            }
          },
          error => {
            that.toastr.error(error)
           
          });
    }
      , function () {
        // that.toastr.error('Delete action cancelled')
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });

  }



  search(e){
    
    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }

    this.faq.getFaq(e.target.value,1).subscribe(data => {
      this.faq$ = data.data.faq.docs;
      this.curPage = data.data.faq.page;
      this.total= data.data.faq.totalDocs;
      this.from = data.data.faq.prevPage;
      this.to = data.data.faq.nextPage;
      this.last_page = data.data.faq.totalPages;
      this.limit = data.data.faq.limit;
      this.page = data.data.faq.page;
      this.pageCounter = data.data.faq.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.faq$.length;
  })

}

pageChange(e){

  this.faq.getFaq('',e).subscribe(data => {
    this.faq$ = data.data.faq.docs;
   
      this.curPage = data.data.faq.page;
      this.total= data.data.faq.totalDocs;
      this.from = data.data.faq.prevPage;
      this.to = data.data.faq.nextPage;
      this.last_page = data.data.faq.totalPages;
      this.limit = data.data.faq.limit;
      this.page = data.data.faq.page;
      this.pageCounter = data.data.faq.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.faq$.length;
     
    this.loading = false;
  }, error => {
    this.loading = false;
  });
}


}



