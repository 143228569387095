<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'banner_form.banner' | translate}}</h1>
                      
                    </div>
                </div>
            </div>
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                     
                     
                        <div class="row">
                            
                            <div class="col-sm-6">
                                <div class="page_search">
                                <input (keyup)="search($event)" class="form-control" type="text" name="custid" placeholder="{{'banner.search' | translate}}" autocomplete="off">
                                </div>
                            </div> 
                            <div class="col-sm-6">
                                <button type="submit" class="btn_cls" (click)="add($event)">{{'banner.add_banner' | translate}}</button>
                              
                            </div>    
                            
                           
                            
                           
                        

                             
                        </div>
                       <br>
                       <div class="card">
                        <table cellspacing="0" id="table" class="table table-hover"
                       >
                      

                        <thead>
                            <tr>
                                <th width="10%">{{'news.sl_no' | translate}}</th>
                             
                              
                                <th width="30%">{{'news.image' | translate}}</th>
                                <th width="40%">{{'banner.link' | translate}}</th>
                                <th width="20%">{{'news.action' | translate}}</th>
                               
                            </tr>
                        </thead>
                        <tbody *ngIf="banner$?.length != 0">
                            <tr *ngFor="let user of banner$; let i = index">
                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                <td>   <span class="mp-img"><img src="{{media_domain}}{{user.image}}" class="img-responsive"/></span></td>
                          <td *ngIf="user.link;else mapId" class="link_cls">{{user.link}}</td>
                                <ng-template #mapId>
                                    <td>-</td>
                                </ng-template>
                               
                                
                             

                              
                              
                              <td> 
                                <div class="row">
                                   
                                      <!-- <div class="col-sm-5"> -->
                                        <button type="submit" class="edit_cls" (click)="editbanner($event,user)"><i class="fas fa-edit"></i>&nbsp;&nbsp;{{'news.edit' | translate}}</button>
                                      <!-- </div>
                                      
                                   <div class="col-sm-5"> -->
                                          
                                     
                                        <button type="submit" class="dlt_cls" (click)="deletebanner($event,user)"><i class="fas fa-trash-alt"></i>&nbsp;&nbsp;{{'news.delete' | translate}}</button>
                                    <!-- </div> -->
                                </div>
                                  
                            </td>
                              
                            </tr>
                        </tbody>
                       
                    </table>
                    </div>
                    <div class="no_data"  *ngIf="banner$ && !banner$.length">{{'news.no_data_found' | translate}}</div>
                   
                
                    <div class="row paginate">
                        <div class="col-sm-8">
                        <div class="shloka_result" *ngIf="total != 0">
                                                 
{{'login.total' | translate}} {{'banner.banner' | translate}} :  {{total}}  &nbsp;  {{'news.showing' | translate}} {{pageCounter}}-{{topage}} &nbsp;  {{'news.page' | translate}} {{curPage}} / {{last_page}}                     
                       </div>
                          </div>
                       <div class="col-sm-4" *ngIf="banner$ && banner$.length">
                        <div class="temple_pagination pg_cls">
                            <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)" style="cursor: pointer;"><i
                                    class="fas fa-step-backward"></i></a>  {{'news.page' | translate}}
                            <span>{{curPage}}</span> {{'news.of' | translate}} {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                class="forward" (click)="pageChange(curPage+1)"  style="cursor: pointer;"><i class="fas fa-step-forward"></i></a>
                        </div>
                    </div>
                    </div>

                    </div>
                </div>
            </div>
        </section>

      
    </div>
</div>