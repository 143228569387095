import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { RoleService, AlertService, MessageService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { IAngularMyDpOptions, IMyDateModel, AngularMyDatePickerDirective } from 'angular-mydatepicker';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';


declare var jquery: any;
declare var $: any;
declare var alertify: any;



@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class RoomsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dp', { static: false }) myDp: AngularMyDatePickerDirective;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'
  };

  userForm: FormGroup;
  dobdate: any;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;
  fileToUpload2: File = null;
  submitted = false;
  //table = false;
  cancelButton = false;
  users$: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  // width_limit = `${environment.bannerWidth}`;
  // height_limit = `${environment.bannerHeight}`;
  media_domain = `${environment.media_domain}`;
  curPage = 1;
  perPage = 10;
  profile: any;
  id_proof: any;

  id: any;
  first_preview: any;
  second_preview: any;
  $singleUser: any;
  imagearray = [];
  images: File;
  band_preview: any;
  image_band: File;
  messages$: any[];
  selectedItems1 = [];
  states: any;
  occupation: any;
  mem_image: any;
  iframe_html1: any;
  iframe_html2: any;
  iframe_html3: any;
  ytube1: any;
  ytube2: any;
  ytube3: any;
  vimeo1: any;
  vimeo2: any;
  vimeo3: any;
  chatId: any;
  width_limit:any;
  height_limit:any;
  userSingle: any;
  $counts: any;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private roleSer: RoleService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private homeService: HomeService,
    private msgSer: MessageService,
  ) { }

  ngOnInit() {
    this.width_limit =`${environment.imgWidth}`;
    this.height_limit=  `${environment.imgHeight}`;
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.userForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      band_name: ['', [Validators.required]],
      member_name: ['', [Validators.required]],
      band_photo: [''],
      address: ['',[Validators.required]],
      genre: ['',[Validators.required]],
      bio: ['', [Validators.required]],
      twitter: ['', [Validators.required, Validators.pattern(/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/)]],
      instagram: ['', [Validators.required, Validators.pattern(/(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/)]],
      youtube: ['', [Validators.required, Validators.pattern(/^https?:\/\/(www\.)?youtube\.com\/(channel\/UC[\w-]{21}[AQgw]|(c\/|user\/)?[\w-]+)$/)]],
      song_name_artist_name1: ['', [Validators.required, Validators.pattern(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/)]],
      song_name_artist_name2: ['', [Validators.pattern(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/)]],
      song_name_artist_name3: ['', [Validators.pattern(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/)]],

    });



    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.band_preview = this.media_domain + '' +this.id;
    
      // if (this.id) {
      //   this.msgSer.getImage(this.id).subscribe(data => {
         
      //     this.userSingle = data.data;
      //     this.band_preview = this.media_domain + '' +this.userSingle.file_path;
      //   })
      
      // }
    })
  }





  






  // convenience getter for easy access to form fields
  get f() { return this.userForm.controls; }




  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
