// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: "Shironuki",
  production: true,

  // apiUrl: 'http://localhost:5/',
  //  apiUrl: 'http://35.154.88.184:5001/',
  apiUrl: 'https://api.recursive-design.com/',
  // apiUrl:'https://api.shirusi.com/',

  oneSignalAppId: 'b48d5f14-a656-4383-b34e-725affb0180f', //dev
  media_domain: 'https://d32fg19io9z7j1.cloudfront.net/production', //dev
  //  media_domain: 'https://d32fg19io9z7j1.cloudfront.net/production', //dev
  apiPrefix: 'api/v1',
  imgWidth: '574',
  imgHeight: '389',
  imgbannerWidth: '260',
  imgbannerHeight: '569',
  imgblogWidth: '1920',
  imgblogHeight: '1080',
  //  chat_server: 'https://chat.shirusi.com'
  chat_server: 'https://chat.recursive-design.com'
  // chat_server: 'http://localhost:8888'
  // chat_server: 'http://192.168.0.20:4444'
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
