<site-header [childMessage]="parentMessage" *ngIf="!showImage && !showVideo"></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }
</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<div class="wrapper" *ngIf="!showImage && !showVideo">
    <div class="content-wrapper">

        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark hd_cls">{{'chat.chat' | translate}}</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <section class="content dashboard_box" *ngIf="!showImage && !showVideo">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="page_search_box ">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="page_search1">
                                        <input (keyup)="search1($event)" class="search_place" type="text" name="custid"
                                            placeholder="{{'users.search_for_a_user_name'| translate}}"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div infiniteScroll [infiniteScrollDistance]="5000" [infiniteScrollThrottle]="10"
                            [scrollWindow]="false" [infiniteScrollUpDistance]="2" (scrolled)="onScrollUp1()"
                            id="scroll_card1">
                            <div class="username_cls" *ngFor="let user of userlist;let i = index"
                                style="cursor: pointer;">
                                <a (click)="chatUser($event,user)">
                                    <div class="user_card flt" *ngIf="user.final_count != 0">
                                        <div class="userview flt">
                                            <div class="user_text"><span [innerHtml]="user.name ? user.name:''" class="desc_cls"></span>
                                            </div>
                                            <div class="filler"></div>
                                            <div class="user_count">{{user.final_count}}</div>
                                        </div>
                                    </div>

                                    <div class="user_card flt" *ngIf="user.final_count == 0">
                                        <div class="userview flt">
                                            <div class="user_text2"><span [innerHtml]="user.name ? user.name:''" class="desc_cls"></span>
                                            </div>
                                            <div class="filler"></div>
                                            <!-- <div class="user_count">{{user.finalcount}}</div> -->
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="no_data" *ngIf="userlist && !userlist.length">{{'faq.no_data_found'| translate}}
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="chat_default flt" *ngIf="!userSingle">
                            <div class="chat_default_box">
                                <div class="chat_default_img flt">

                                    <img src="assets/images/start.png">
                                </div>
                                <div class="chat_default_txt flt">
                                    <span>
                                        {{'chat.chat_here'| translate}}
                                    </span>
                                    <br>
                                    {{'chat.txt'| translate}}
                                </div>
                            </div>
                        </div>
                        <div class="chat_main flt" *ngIf="userSingle">
                            <div class="chat_left">

                                <div class="chat_body flt scroll_card" id="scroll_card">

                                    <div class="chat_main flt main_cls">

                                        <div class="chat_top flt">
                                            <div class="chat_userprofile">
                                                <!-- <div class="user_left">
                                                    <img src="assets/images/user_profile.png" class="img-responsive" alt="image">
                                                </div> -->
                                                <div class="user_right">
                                                    <div class="user_name"><span  [innerHtml]="userSingle ? userSingle :''" class="desc_cls1"></span>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>

                                        <div class="chat_box flt" infiniteScroll [infiniteScrollDistance]="5000"
                                            [infiniteScrollThrottle]="10" [scrollWindow]="false"
                                            [infiniteScrollUpDistance]="5" (scrolledUp)="onScrollUp()">
                                            <div *ngFor="let m of messages$;let j=index" class="chat_card flt"
                                                [ngClass]="userId==m.userId._id?'chat_media chat_media_right':'chat_media'"
                                                id="other">
                                                <div *ngIf="m.file_path && m.file_type=='video'">
                                                    <div class="video_main flt" *ngIf="userId==m.userId._id">
                                                        <div class="video_in flt">
                                                            <a data-fancybox="gallery"
                                                                [href]="media_domain+m.file_path"> <button
                                                                    class="video_download">
                                                                    <i
                                                                        class="fas fa-download fadownload"></i>Download</button></a>
                                                            <button class="playbtnvideo" style="cursor: pointer;"><i
                                                                    class="fas fa-play playinvideo"></i></button>
                                                            <a data-fancybox="gallery" *ngIf="userId==m.userId._id">

                                                                <video class="chat_img" id="video"
                                                                    *ngIf="m.file_path && m.file_type=='video'"
                                                                    src="{{media_domain}}{{m.file_path}}"
                                                                    type="video/mp4" (click)="openVideo($event,m)">
                                                                </video>
                                                                <canvas id="canvas"
                                                                    *ngIf="m.file_path && m.file_type=='video'">

                                                                </canvas>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="video_main_user flt" *ngIf="userId!=m.userId._id">
                                                        <div class="video_in_user flt">
                                                            <a data-fancybox="gallery"
                                                                [href]="media_domain+m.file_path"> <button
                                                                    class="video_download_user"><i
                                                                        class="fas fa-download fadownload"></i>Download</button></a>
                                                            <button class="playbtnvideo_user"
                                                                style="cursor: pointer;"><i
                                                                    class="fas fa-play playinvideo"></i></button>
                                                            <a *ngIf="userId!=m.userId._id">
                                                                <video class="chat_img" id="video"
                                                                    *ngIf="m.file_path && m.file_type=='video'"
                                                                    src="{{media_domain}}{{m.file_path}}"
                                                                    type="video/mp4" (click)="openVideo($event,m)">
                                                                </video>
                                                                <canvas id="canvas"
                                                                    *ngIf="m.file_path && m.file_type=='video'">
                                                                </canvas>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="m.file_path && m.file_type=='image'">
                                                    <div class="video_main flt" *ngIf="userId==m.userId._id">
                                                        <div class="video_in flt">
                                                            <a data-fancybox="gallery"
                                                                [href]="media_domain+m.file_path"><button
                                                                    class="video_download"> <i
                                                                        class="fas fa-download fadownload"></i>Download</button></a>
                                                            <a (click)="openImage($event,m)"
                                                                *ngIf="userId==m.userId._id" target="_blank" style="cursor: pointer;"><img
                                                                    src="{{media_domain}}{{m.file_path}}" alt="image"
                                                                    class="img-responsive chat_img"
                                                                    *ngIf="m.file_path && m.file_type=='image'">

                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="video_main_user flt" *ngIf="userId!=m.userId._id">
                                                        <div class="video_in_user flt">
                                                            <a data-fancybox="gallery"
                                                                [href]="media_domain+m.file_path">
                                                                <button class="video_download_user"><i
                                                                        class="fas fa-download fadownload"></i>Download</button>
                                                            </a>
                                                            <a (click)="openImage($event,m)" *ngIf="userId!=m.userId._id" target="_blank" style="cursor: pointer;">

                                                                <img src="{{media_domain}}{{m.file_path}}" alt="image"
                                                                    class="img-responsive chat_img"
                                                                    *ngIf="m.file_path && m.file_type=='image'">
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a (click)="fileDownload(m)" style="cursor: pointer;">

                                                    <div [ngClass]="userId==m.userId._id?'chat_file_right':'chat_file1'"
                                                        *ngIf="((m.file_path && m.file_type!='video') && (m.file_path && m.file_type!='image'))">
                                                        <div class="fileup_box">
                                                            <div class="fileup__icon"><i class="fas fa-file"></i></div>
                                                            <div class="fileup_txt">{{file_names(m)}}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </a>
                                                <div class="flt">
                                                    <div *ngIf="m.message" class="chat_msg flt" [id]="m._id"
                                                        [ngClass]="userId==m.userId._id?'self_chat':' '">
                                                        <div class="chat_cont flt" *ngIf="m.message">
                                                            <div class="self_txt"
                                                                [ngClass]="userId!=m.userId._id?'chat_desc flt':''">
                                                                <span [innerHTML]="m.message | linkify"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="chat_tag flt"
                                                        [ngClass]="userId==m.userId._id?'chat_tag1 flt':' '">
                                                    <span class="acceptedtxt" *ngIf="m.chat_status == 1">{{'chat.accept' | translate}}</span>  {{ m.time }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat_footer flt">
                                    <div class="flt" [ngClass]="selectedArray.length?' upload_section':''">
                                        <div class="media_upload" *ngIf="selectedArray.length">
                                            <div class="media_upload_box"
                                                *ngFor="let file of selectedArray; let j=index">

                                                <ng-container
                                                    *ngIf="file.type.type!='image' && file.type.type!='video'">
                                                    <span (click)="removeFile($event)"><i
                                                            class="fas fa-times-circle rm_icon"></i></span>

                                                    <img src="/assets/images/file.png" alt="image"
                                                        class="img-responsive chat_img">
                                                </ng-container>

                                                <ng-container *ngIf="file.type.type=='image'">
                                                    <span (click)="removeFile($event)"><i
                                                            class="fas fa-times-circle rm_icon"></i></span>
                                                    <img src="{{file.type.image}}" alt="image"
                                                        class="img-responsive chat_img">
                                                </ng-container>
                                                <ng-container *ngIf="file.type.type=='video'">
                                                    <span (click)="removeFile($event)"><i
                                                            class="fas fa-times-circle rm_icon vdo"></i></span>
                                                    <video class="video_info">
                                                        <source src="{{file.type.video}}" type="video/mp4">
                                                    </video>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <form [formGroup]="emojiForm">
                                            <div class="input-group">
                                                <div class="fchat_menu">
                                                    <div class="option_more" (click)="openView($event)"><img src="assets/images/icon-add.png"
                                                            alt="image">
                                                        <ul class="option_view" style="display: none;"
                                                            *ngIf="!selectedArray.length">
                                                            <li><img (click)="uploadFile2($event,3)"
                                                                    src="assets/images/icon-file.png" alt="image">
                                                                <input type="file"
                                                                    accept=".doc,.docx,.pdf,.xlsx,.zip,.json"
                                                                    #fileInput2 id="files"
                                                                    (change)="selectFile3($event,3)">
                                                            </li>

                                                            <li><img (click)="uploadFile($event,1)"
                                                                    src="assets/images/icon-video.png" alt="image">
                                                                <input type="file" accept="video/*" #fileInput
                                                                    id="videos" (change)="selectFile($event,1)">
                                                            </li>
                                                            <li><img (click)="uploadFile1($event,2)"
                                                                    src="assets/images/icon-photo.png"
                                                                    alt="image"><input type="file" accept="image/*"
                                                                    #fileInput1 id="image"
                                                                    (change)="selectFile2($event,2)">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <textarea data-autoresize rows="1" type="text" name="message" id="grow"
                                                    maxlength="1000" [(ngModel)]="messageContent"
                                                    placeholder="{{'chat.send_message' | translate}}"
                                                    formControlName="inputField" autocomplete="off"></textarea>
                                            </div>
                                            <div class="showbox_cls" *ngIf="showbox">
                                                <input autocomplete="off" id="project_upload" type="text"
                                                    (click)="dataChec($event)" class="form-control"
                                                    (keyup.enter)="dataSend($event)"
                                                    placeholder="{{'chat.upload' | translate}}">
                                            </div>
                                        </form>
                                    </div>
                                    <div class="chat_send_btn" style="cursor: pointer;">
                                        <span class="tooltiptext">{{'chat.upload' | translate}}</span>
                                        <a (click)="show($event)">
                                            <i class="fas fa-file-upload"></i>
                                        </a>
                                    </div>
                                    <div class="chat_send_btn" style="cursor: pointer;"
                                        (click)="sendMessage(messageContent)">
                                        <img src="assets/images/icon-send.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    </div>
</div>
<div class="img_preview" id="img_show" *ngIf="showImage">
    <i class="fas fa-times-circle" (click)="close($event,singleUserDetails)"></i>
    <img src="{{showImage}}">
</div>
<div class="img_preview" id="img_show1" *ngIf="showVideo">
    <i class="fas fa-times-circle" (click)="close1($event,singleUserDetails)"></i>
    <video controls id="video" autoplay>
        <source src="{{showVideo}}">
    </video>
</div>
<script src="/assets/js/bootstrap-table.js"></script>



<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>