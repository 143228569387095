<site-header></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }

</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark hd_cls">{{'faq_form.faq' | translate}}</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="adminForm" #myForm="ngForm" (ngSubmit)="onSubmit(adminForm, myForm)"
                        class="">
                        <div class="row">
                          
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">{{'faq_form.title' | translate}}</label>
                                    <input autocomplete="off" type="text" class="form-control"
                                    formControlName="title"
                                    [ngClass]="{ 'is-invalid': (f.title.touched || submitted) && f.title.errors }"
                                    >
                                <div *ngIf="(f.title.touched || submitted) && f.title.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.title.errors?.required">{{'faq_form.title' | translate}} {{'faq_form.is_required' | translate}}</div>
                                </div>
                                <div *ngIf="(f.title.touched || submitted) && f.title.errors?.pattern"
                                class="invalid-feedback">
                                <div *ngIf="f.title.errors?.pattern">{{'faq.white_space'| translate}}
                                </div>
                            </div>
                                </div>
                            </div>
                          
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">{{'faq_form.description' | translate}}</label>
                                    <textarea type="text" class="form-control"  formControlName="description" [ngClass]="{ 'is-invalid': (f.description.touched || submitted) && f.description.errors }"></textarea>
                                    <div *ngIf="(f.description.touched || submitted) && f.description.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.description.errors?.required">{{'faq_form.description' | translate}} {{'faq_form.is_required' | translate}}</div>
                                  </div>
                                
                                  <div *ngIf="(f.description.touched || submitted) && f.description.errors?.pattern"
                                  class="invalid-feedback">
                                  <div *ngIf="f.description.errors?.pattern">{{'faq.white_space'| translate}}
                                  </div>
                              </div>
                                </div>
                            </div>
                           
                          
                         
                        </div>
                       
                        <div class="row mt-4">
                            <div class="col-md-4">
                                <button type="submit" class="btn_cls" *ngIf="!id">{{'faq_form.create' | translate}}</button>
                                <button type="submit" class="btn_cls" *ngIf="id">{{'faq_form.update' | translate}}</button>
                            </div>
                            <div class="col-md-4">
                                <button type="submit" class="btn_white" (click)="cancel()">{{'faq_form.cancel' | translate}}</button>
                            </div>
                        </div>
  

        </form>
        </div>
        </div>
    </div>
</div>

</section>
<script src="/assets/js/bootstrap-table.js"></script>

<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }

</style>
