import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SiteHeaderComponent } from '../_layouts/site-header';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<number>();
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  sel_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob: any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  users:any;
  $count:any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  user_details: any;
  curPage = 1;
  perPage = 10;
  reason: any;
  last_page: any;
  total: any;
  from: any;
  to: any;
  searchUser: any;
  apiUrl = "";
  disp_type: any;
  limit: any;
  pageCounter: any;
  topage: any;
  page: any;
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private home: HomeService,
    public translate: TranslateService

  ) {
  }
  get f() { return this.creditForm.controls; }


  parentMessage= {contactcount: 0};

  ngOnInit() {

    const that = this;


    this.apiUrl = `${environment.apiUrl}`;


    this.home.getContact('', '').subscribe(data => {

      this.users$ = data.data.contact.docs;
      this.curPage = data.data.contact.page;
      this.total = data.data.contact.totalDocs;
      this.from = data.data.contact.prevPage;
      this.to = data.data.contact.nextPage;
      this.last_page = data.data.contact.totalPages;
      this.limit = data.data.contact.limit;
      this.page = data.data.contact.page;
      this.pageCounter = data.data.contact.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.users$.length;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });


    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: '1'
    });
  }


  search(e) {
    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }

    this.home.getContact(e.target.value, 1).subscribe(data => {
      this.users$ = data.data.contact.docs;
      this.curPage = data.data.contact.page;
      this.total = data.data.contact.totalDocs;
      this.from = data.data.contact.prevPage;
      this.to = data.data.contact.nextPage;
      this.last_page = data.data.contact.totalPages;
      this.limit = data.data.contact.limit;
      this.page = data.data.contact.page;
      this.pageCounter = data.data.contact.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.users$.length;
    })

  }

  closeModal(e,user) {
    $('#user-republic-mdl').modal('hide');
    this.home.getContact('', this.curPage).subscribe(data => {
      this.users$ = data.data.contact.docs;
     
      this.curPage = data.data.contact.page;
      this.total = data.data.contact.totalDocs;
      this.from = data.data.contact.prevPage;
      this.to = data.data.contact.nextPage;
      this.last_page = data.data.contact.totalPages;
      this.limit = data.data.contact.limit;
      this.page = data.data.contact.page;
      this.pageCounter = data.data.contact.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.users$.length;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }

  pageChange(e) {

    this.home.getContact('', e).subscribe(data => {
      this.users$ = data.data.contact.docs;
      this.curPage = data.data.contact.page;
      this.total = data.data.contact.totalDocs;
      this.from = data.data.contact.prevPage;
      this.to = data.data.contact.nextPage;
      this.last_page = data.data.contact.totalPages;
      this.limit = data.data.contact.limit;
      this.page = data.data.contact.page;
      this.pageCounter = data.data.contact.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.users$.length;

      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  viewReason(e, user) {
   
    $('#user-republic-mdl').modal('show');
    this.reason = user.reason;
    this.home.status_update(user._id, 1).subscribe(data => {
      if(data.status.code == 0){
        user.status = 1;
      }
     
    })
    if(user.status == 0){
      this.parentMessage['contactcount']+=1;
    }
    this.home.getcontactNotification().subscribe(data=>{
      this.$count = data.data.contact;
    
     })
 
  }

  view(e, i) {

    e.stopPropagation();
    $('.temple_drp_menu').css('display', 'none');
    $('.temple_drp_menu' + i).toggle();
    $(document).click(function (e) {
      if (!$(e.target).closest('.temple_drp_btn, .temple_drp_menu').length) {
        $('.temple_drp_menu').hide();
      }
    })
  }




  changeStatus(e, user, type) {
    var txt = '';
    if (type == 1) {
      this.disp_type = 1;
      txt = 'accept';
    } else if (type == -1) {
      this.disp_type = -1;
      txt = 'reject';
    }

    var that = this;
    alertify.confirm('Are you sure you want to ' + txt + ' this admin?', function () {
      that.home.status_update(user._id, that.disp_type)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Updated Successfully')

              that.home.getMerachant('', '').subscribe(data => {

                that.users$ = data.data.merchant.data;
                that.curPage = data.data.merchant.current_page;
                that.total = data.data.merchant.total;
                that.from = data.data.merchant.from;
                that.to = data.data.merchant.to;
                that.last_page = data.data.merchant.last_page;
                that.dtTrigger.next();
              }, error => {
                console.error(error);
              });

            } else {
              that.toastr.error(data.status.message)

            }
          },
          error => {
            that.toastr.error(error)

          });
    }
      , function () {

      });
  }




}
