<site-header></site-header>
<div class="content-wrapper">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark">{{'app_settings.app_settings' | translate}}</h1>
                    <!-- <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href='/home'>{{'app_settings.home' | translate}}</a></li>
                        <li class="breadcrumb-item active">{{'app_settings.app_settings' | translate}}</li>
                    </ol> -->
                </div>

            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>


    <div class="page">
        <div class="page-content container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="row terms-head" >
                        <div class="col-10">
                            <div >
                            <span>{{'app_settings.terms_&_conditions' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-1 terms-edit">
                            <a href="/edit-terms" class="terms_toggle_btn">
                                <i class="fas fa-edit"></i>
                                {{'app_settings.edit' | translate}}
                            </a>
                        </div>

                       
                       
                    </div>
                    <div  class="row terms-content">
                        <div class="col-12">
                            <div >
                        <div id="print">
                        <div  id="description">
                           
                        </div>
                        <div *ngIf="!term" >
                            <span> {{'users.no_data_found' | translate}}</span>
                        </div>
                        </div>
                        
                        <a  id="more" > {{'app_settings.more' | translate}} </a>
                        <a  id="less" > {{'app_settings.less' | translate}} </a>
                   
                            </div>
                        </div>

                    </div>
                   
                   
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row privacy-head" >
                        <div class="col-10">
                            <div >
                            <span> {{'app_settings.privacy_policy' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-1 privacy-edit">
                            <a href="/edit-privacy-policy" class="privacy_toggle_btn">
                                <i class="fas fa-edit"></i>
                                {{'app_settings.edit' | translate}}
                            </a>
                        </div>

                       
                    </div>
                    <div class="row privacy-content">
                        <div class="col-12">
                       
                        <div id="privacy">
                           
                        </div>
                     

                         
                        </div>
                        <a id="more1" >{{'app_settings.more' | translate}} </a>
                        <a id="less1" >{{'app_settings.less' | translate}} </a>

                    </div>
                   
                </div>
            </div>
        
            
         
         
          
        </div>
    </div>
</div>