import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService,HomeService} from 'src/app/_services';
import { ContactComponent } from 'src/app/contact';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { data } from 'jquery';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
})
export class SiteHeaderComponent implements OnInit {

  // @ViewChild(ContactComponent,{static: false}) child
  routeParam: any;

  $count: any;
  $counts:any;
  selected_langauge = "English";
  langauge_sel = '';
  CurrentTime:any;
  count_true = false;
  constructor(public router: Router,
    public translate: TranslateService,
    private authentication: AuthenticationService,
    private home: HomeService,
    private toastr: ToastrService
    ) { }

    @Input() childMessage: any;
    // @Input() childMessage1: number;

  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  role = localStorage.getItem(`${environment.appName}` + '_role');
logouttime :any;
userId = localStorage.getItem(`${environment.appName}` + '_adminId');
logintime = localStorage.getItem(`${environment.appName}` + '_time');
  ngOnInit() {

    //   setInterval(() => {
  
    //   this.home.getmsgNotification().subscribe(data => {
    //      this.$counts = data.data && data.data.message;
    //   })
    //  }, 1000);


    this.routeParam = this.router.url;
    this.langauge_sel = localStorage.getItem('selected_language') ? localStorage.getItem('selected_language') : 'English';
		this.selected_langauge = this.langauge_sel;
    this.home.getcontactNotification().subscribe(data=>{
     this.$count = data.data.contact;
    //  this.childMessage.contactcount = this.$count;
    //  console.log(this.childMessage.contactcount);
     if(this.$count > 0){
      this.count_true = true;
      this.home.getcontactNotification().subscribe(data=>{
        this.$count = data.data.contact;
      });
     }else{
       this.count_true = false;
     }
    })
    

    this.home.getmsgNotification().subscribe(data=>{
      this.$counts = data.data.message;
      console.log(this.$counts)
     })


    //  this.childMessage
  }
  language(e){

this.selected_langauge = e.target.value;
this.continueWithLanguage();
  }

  continueWithLanguage() {
		localStorage.setItem('selected_language', this.selected_langauge);
		location.reload();
	}

 
  logout(e) {
    e.stopPropagation();
    var that = this;
    var currdate = new Date().getHours() + ':' + new Date().getMinutes();
    var dd = String(new Date().getDate()).padStart(2, '0');
    var mm = String(new Date().getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = new Date().getFullYear();
   
  
    
      var CurrentTime = yyyy + '年' + mm + '月' + dd + '日' + ' ' + new Date().getHours() + '時' + new Date().getMinutes() + '分';
     localStorage.setItem(`${environment.appName}` + '_logouttime', CurrentTime);
      that.logouttime = localStorage.getItem(`${environment.appName}` + '_logouttime');
     
      that.home.loginStatus(that.userId,that.logintime,that.logouttime).subscribe(data=>{
        if(data.status.code == 0){
          that.authentication.logout();
          location.href = '/login';
        }
      })
  
   
    
    // alertify.confirm('Logout','Are you sure want to logout?', function () {
  
//   }, function () { 
//    that.toastr.error('Logout action cancelled')
// });
  }

  ngAfterViewInit(){
    timer(2000).subscribe(tm=>{
      // console.log(this.child.childMessage)
    })
  }
}