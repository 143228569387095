import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class AppsettingsService {
  constructor(private http: HttpClient) { }


        saveTerms(content:any,type:any)
        {
            var formData = {
            'content':content,
            'type':type
            }
            return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/static-page`, formData).pipe(map(user => {
                      return user;
                  }));

              }


        getAppSettings(): Observable <any>{
 
          return this.http.get<any> (`${environment.apiUrl}${environment.apiPrefix}/admin/static-page` ).pipe(map(user => user)); 
          }     

          update(status: any,type:any)
          {
              var formData = {
               
              'status':status ? 1 : 0,
              'type':type
  
              }
              return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/appsetting/update-social-settings`, formData).pipe(map(user => {
                        return user;
                    }));
  
                }
            

             

}
