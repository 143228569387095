import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BlogService {
    constructor(private http: HttpClient) { }


    save(title:any,description:any,image:File,featured:any){
        const formData: FormData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('featured', featured);
        if (image) {
            formData.append('image',image, image.name);
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blog`, formData).pipe(map(user => {
            return user;
        }));
    }

    update(id:any,title:any,description:any,image:File,featured:any){
        const formData: FormData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('featured', featured);
        if (image && image.name) {
            formData.append('image',image, image.name);
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blog/` + id, formData).pipe(map(user => {
            return user;
        }));
    }


    updatefeatured(id:any,featured:any){
        const formData: FormData = new FormData();
     
        formData.append('featured', featured);
      
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blog/featured/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blog/` + id).pipe(map(user => {
            return user;
        }));

    }
    getBlog(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blog?search=`+search+'&page='+page).pipe(map(user => user));

    }

    getSingleBlog(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blog/` + id).pipe(map(user => user));

    }
}