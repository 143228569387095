import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Message } from '../model/message';

import { Event } from '../model/event';
import { Observer } from 'rxjs';
import * as socketIo from 'socket.io-client';

import { data } from 'jquery';

 

@Injectable()
export class SocketService {
    private socket;
    private socketadmin;
    public initSocketadmin(chat_server): void {
        this.socketadmin = socketIo(chat_server);
    }

    public onMessageadmin(): Observable<Message> {
        return new Observable<Message>(observer => {
        
             this.socketadmin.on('new messages', (data: Message) =>
              observer.next(data));
        });
    }


    public onEventadmin(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            this.socketadmin.on(event, () => observer.next());
        });
    }

    public initSocket(chat_server,chatId,userId): void {
        this.socket = socketIo(chat_server+'/chat-'+chatId,{ query: "userId="+userId});
    }

    public send(message: any): void {
        this.socket && this.socket.emit('new message', message);
    }

    public onMessage(): Observable<Message> {
        return new Observable<Message>(observer => {
             this.socket.on('new message', (data: Message) =>
              observer.next(data));
        });
    }

    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            this.socket.on(event, () => observer.next());
        });
    } 
}
