import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,AbstractControl,ValidationErrors,FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService,SettingsService } from '../_services';
declare var jquery: any;
declare var $: any;
@Component({

  templateUrl: './forgot-password.component.html',

})
export class ForgotPasswordComponent implements OnInit {
    passwordreset=true;
  passwordForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
       hide=1;
    mobile_no = '';
    constructor(
            private setting: SettingsService,
            private formBuilder: FormBuilder,
            private authenticationService: AuthenticationService,
            private modalService: ModalService,
            private toastr: ToastrService,
            private route: ActivatedRoute,
            private router: Router) { }
static areEqual(c: AbstractControl): ValidationErrors | null {
        const keys: string[] = Object.keys(c.value);
        for (const i in keys) {
            if (i !== '0' && c.value['new_password'] !== c.value['confirm_password']) {
                return { areEqual: true };
            }
        }
    }
    ngOnInit() {
        this.passwordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.maxLength(50), Validators.email]],

        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.passwordForm.controls; }
    onSubmit(formWrap) {
        this.submitted = true;
        // stop here if form is invalid
        if (this.passwordForm.invalid) {
            return;
        }

        document.body.classList.add('jw-modal-open');
        this.loading = true;

        this.setting.requestToPassword(
            this.f.email.value)
            .subscribe(
                data => {
                    if (data.status.code == 0) {
                        this.submitted = false;
                        this.passwordreset=false;
                        document.body.classList.remove('jw-modal-open');
                        this.loading = false;
                        // this.toastr.success(data.status.message);
                        formWrap.reset();

                          //  location.href = '/reset-password';

                         } else {
                        document.body.classList.remove('jw-modal-open');
                        this.loading = false;
                        this.toastr.error(data.status.message)
                        }
                },
                error => {
                    this.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    this.loading = false;
                });

    }
    cancel(){
        location.href="/login";
    }
}
