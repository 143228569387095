import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, HomeService,RoleService,BannerService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;
@Component({
  selector: 'app-rightsidebanner',
  templateUrl: './rightsidebanner.component.html',
  styleUrls: ['./rightsidebanner.component.css']
})
export class RightsidebannerComponent implements OnInit {

  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  sel_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob: any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  user_details: any;
  curPage = 1;
  perPage = 10;
  last_page: any;

  total: any;
  from:any;
  to:any;
  searchUser: any;
  apiUrl="";
  banner$ : any;
  limit:any;
  pageCounter:any;
  topage:any;
  page:any;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private home: HomeService,
    private role: RoleService,
    private bannerSer: BannerService
  ) {
  }
  get f() { return this.creditForm.controls; }


  

  ngOnInit() {

    const that = this;


  this.apiUrl = `${environment.apiUrl}`;
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name/Email/Mobile No."
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };


    this.bannerSer.getBanneradd('','').subscribe(data => {
  
      this.banner$ = data.data.banner.docs;
      this.curPage = data.data.banner.page;
      this.total= data.data.banner.totalDocs;
      this.from = data.data.banner.prevPage;
      this.to = data.data.banner.nextPage;
      this.last_page = data.data.banner.totalPages;
      this.limit = data.data.banner.limit;
      this.page = data.data.banner.page;
      this.pageCounter = data.data.banner.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.banner$.length;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });


    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: '1'
    });


  }

  search(e){
    
      if (e.target.value) {
        this.searchUser = true;
      } else {
        this.searchUser = false;
      }

    this.bannerSer.getBanneradd(e.target.value,1).subscribe(data =>{
      this.banner$ = data.data.banner.docs;
      this.curPage = data.data.banner.page;
      this.total= data.data.banner.totalDocs;
      this.from = data.data.banner.prevPage;
      this.to = data.data.banner.nextPage;
      this.last_page = data.data.banner.totalPages;
      this.limit = data.data.banner.limit;
      this.page = data.data.banner.page;
      this.pageCounter = data.data.banner.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.banner$.length;
    })
 
  }

  pageChange(e){

    this.bannerSer.getBanneradd('',e).subscribe(data =>{
      this.banner$ = data.data.banner.docs;
      this.curPage = data.data.banner.page;
      this.total= data.data.banner.totalDocs;
      this.from = data.data.banner.prevPage;
      this.to = data.data.banner.nextPage;
      this.last_page = data.data.banner.totalPages;
      this.limit = data.data.banner.limit;
      this.page = data.data.banner.page;
      this.pageCounter = data.data.banner.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.banner$.length;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  view(e,i){
   
      e.stopPropagation();
      $('.temple_drp_menu').css('display','none');
      $('.temple_drp_menu' + i).toggle();
      $(document).click(function(e){
       if(!$(e.target).closest('.temple_drp_btn, .temple_drp_menu').length){
       $('.temple_drp_menu').hide();
       }
     })
  }
    
 
  editbanner(e,user){
    this.router.navigate(['/create-rightsidebanner'], { queryParams: {id: user._id}})
  }

  add(e){
    this.router.navigate(['/create-rightsidebanner'])
  }

  deletebanner(e,user){
    var that = this;
    alertify.confirm(that.home.getTranslate('banner.are_you_sure_you_want_to_delete_this_banner?'), function () {
      that.bannerSer.deleteadd(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success(that.home.getTranslate('faq.deleted_successfully'))
            
              that.bannerSer.getBanneradd('',that.curPage).subscribe(data => {
  
                that.banner$ = data.data.banner.docs;
                that.curPage = data.data.banner.page;
                that.total= data.data.banner.totalDocs;
                that.from = data.data.banner.prevPage;
                that.to = data.data.banner.nextPage;
                that.last_page = data.data.banner.totalPages;
                that.limit = data.data.banner.limit;
                that.page = data.data.banner.page;
                that.pageCounter = data.data.banner.pagingCounter;
                that.topage = (that.limit * (that.page - 1)) + that.banner$.length;
                that.dtTrigger.next();
              }, error => {
                console.error(error);
              });
          
           
            } else {
              that.toastr.error(data.status.message)
            
            }
          },
          error => {
            that.toastr.error(error)
           
          });
    }
      , function () {
        // that.toastr.error('Delete action cancelled')
      }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });

  }

  
  
}
