import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { formatDate } from '@fullcalendar/core';
import { download, Download } from './download'
import { SAVER, Saver } from './saver.provider'

@Injectable({
    providedIn: 'root'
})
export class MessageService {


    constructor(private http: HttpClient
        ) { }
    get_message(chat_id, page, search = "") {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/message/` + chat_id + `?page=` + page + `&search=` + search).pipe(map(data => {
            return data;
        }));
    }

    fileUpload(file_path: File) {
        const formData = new FormData();
        formData.append('file_path', file_path, file_path.name);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/messages-fileupload`, formData).pipe(map(data => {
            return data;
        }));
    }

    saveChat(adminId:any, userId:any) {
        const formData = new FormData();
        formData.append('adminId', adminId);
        formData.append('userId', userId);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/storeChat`, formData).pipe(map(data => {
            return data;
        }));
    }


    get_single_message(id, page) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/temples-groups/single-chat/` + id + `?page=` + page).pipe(map(data => {
            return data;
        }));
    }


    getchat(id:any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getchatId/` + id).pipe(map(data => {
            return data;
        }));
    }

    getchatUser(search:any,page:any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getchatuser?search=`+ search+ '&page=' +page).pipe(map(data => {
            return data;
        }));
    }

    getUserSingle(id:any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/user/` + id).pipe(map(data => {
            return data;
        }));
    }



    getImage(id:any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/image/` + id).pipe(map(data => {
            return data;
        }));
    }



    get_user_chats(chat_id, page) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/temples-groups/chat/` + chat_id + `?page=` + page).pipe(map(data => {
            return data;
        }));
    }

    getSearchMessages(message, id, skip, message_id = "") {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/temples-groups/search-messages/` + id + `?search=` + message + `&skip=` + skip + `&messageId=` + message_id).pipe(map(data => {
            return data;
        }));
    }


    postSingleChat(id, message) {
        var formData = {};

        formData = {
            'message': message
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/temples-groups/post-single-chat/` + id, formData).pipe(map(data => {
            return data;
        }));
    }


    getSingleSearchMessages(search, chat_id, skip) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/temples-groups/search-single/` + chat_id + `?search=` + search + `&skip=` + skip).pipe(map(data => {
            return data;
        }));
    }


    status_update(id: string, status: any) {
        const formData: FormData = new FormData();
        formData.append('status', status);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/status/`+ id, formData).pipe(map(data => {
            return data;
        }));
    }

    chatRead(msgId: any) {
        const formData: FormData = new FormData();
        formData.append('msgId', msgId);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/chatread`, formData).pipe(map(data => {
            return data;
        }));
    }

    deleteMessage(id, type = "", mId = "") {
        var formData = {};
        // + `&message=` + message
        formData = {
            'mId': mId ? mId : '',
            'type': type
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/delete-message/` + id, formData).pipe(map(data => {
            return data;
        }));
    }

}
