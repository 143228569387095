<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'settings.settings' | translate}}</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                     
                            <form [formGroup]="settingForm" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm, myForm)"
                                class="">
                                <div class="row">
                                    <h5 class="example-title">{{'settings.change_email/_password' | translate}}</h5>
                                    <div class="col-md-12">
                                        <label class="form-control-label" for="inputUserName">{{'settings.email' | translate}}</label>
                                        <input autocomplete="off" type="text" class="form-control username"
                                            id="inputUserName" formControlName="upd_username" onload="this.value=''"
                                            placeholder="{{'settings.email' | translate}}" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.upd_username.touched || submitted) && f.upd_username.errors }" />
                                        <div *ngIf="(f.upd_username.touched || submitted) && f.upd_username.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.upd_username.errors?.required">{{'settings.email' | translate}} {{'faq_form.is_required' | translate}}</div>
                                        </div>
                                        <div *ngIf="(f.upd_username.touched || submitted) && f.upd_username.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.upd_username.errors?.maxlength">{{'settings.maximum_100_characters_are_allowed' | translate}}
                                            </div>
                                        </div>
                                        <div *ngIf="(f.upd_username.touched || submitted) && f.upd_username.errors" class="invalid-feedback">
                                            <div *ngIf="f.upd_username.errors.pattern">{{'users.enter_valid_email_pattern' | translate}}</div>
                                        </div>
                                        <br>
                                        <div class="checkbox-custom checkbox-default">
                                            <input type="checkbox" id="inputIssReset"
                                                formControlName="is_reset_password" (change)="handleChange($event)"
                                                autocomplete="off" />
                                            <label for="inputIssReset">{{'settings.change_password' | translate}}</label>
                                        </div>
                                        <div class="example-wrap">
                                            <label class="form-control-label" for="inputCurrent">{{'settings.current_password' | translate}}</label>
                                            <input autocomplete="off" onload="this.value=''" type="password"
                                                class="form-control password" id="inputCurrent"
                                                formControlName="upd_password" placeholder="{{'settings.current_password' | translate}}"
                                                autocomplete="off"
                                                [ngClass]="{ 'is-invalid': (f.upd_password.touched || submitted) && f.upd_password.errors }" />
                                            <div *ngIf="(f.upd_password.touched || submitted) && f.upd_password.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.upd_password.errors.required">{{'settings.current_password' | translate}} {{'faq_form.is_required' | translate}}
                                                </div>
                                            </div>
                                            <div *ngIf="(f.upd_password.touched || submitted) && f.upd_password.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.upd_password.errors.pattern">{{'faq.white_space'| translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="example-wrap" formGroupName="passwords" *ngIf="isResetFlag">
                                            <div class="example-wrap">
                                                <label class="form-control-label" for="inputNew">{{'settings.new_password' | translate}}</label>
                                                <input autocomplete="off" type="password" class="form-control password"
                                                    id="inputNew" formControlName="new_password" placeholder="{{'settings.new_password' | translate}}"
                                                    autocomplete="off"
                                                    [ngClass]="{ 'is-invalid': (f.passwords.controls.new_password.touched || submitted) && f.passwords.controls.new_password.errors }" />
                                                <div *ngIf="(f.passwords.controls.new_password.touched || submitted) && f.passwords.controls.new_password.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.passwords.controls.new_password.errors?.required">{{'settings.new_password' | translate}} {{'faq_form.is_required' | translate}}</div>
                                                </div>
                                                <div *ngIf="(f.passwords.controls.new_password.touched || submitted) && f.passwords.controls.new_password.errors?.maxlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.passwords.controls.new_password.errors?.maxlength">{{'settings.maximum_100_characters_are_allowed' | translate}}</div>
                                                </div>
                                                <div *ngIf="(f.passwords.controls.new_password.touched || submitted) && f.passwords.controls.new_password.errors?.pattern"
                                                class="invalid-feedback">
                                                <div *ngIf="f.passwords.controls.new_password.errors?.pattern">{{'faq.white_space'| translate}}</div>
                                            </div>
                                            </div>
                                            <div class="example-wrap">
                                                <label class="form-control-label" for="inputConfirm">{{'settings.confirm_password' | translate}}</label>
                                                <input autocomplete="off" type="password" class="form-control"
                                                    id="inputConfirm" formControlName="confirm_password"
                                                    placeholder="{{'settings.confirm_password' | translate}}" autocomplete="off" [ngClass]="{ 'is-invalid': (f.passwords.controls.confirm_password.touched || submitted) && (f.passwords.controls.confirm_password.errors?.required
                                                || f.passwords.errors?.areEqual) }" />
                                                <div *ngIf="(f.passwords.controls.confirm_password.touched || submitted) && f.passwords.controls.confirm_password.errors?.required "
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.passwords.controls.confirm_password.errors?.required">
                                                        {{'settings.confirm_password' | translate}} {{'faq_form.is_required' | translate}}</div>
                                                </div>
                                                <div *ngIf="(f.passwords.controls.confirm_password.touched || submitted) && f.passwords.controls.confirm_password.errors?.maxlength "
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.passwords.controls.confirm_password.errors?.maxlength">
                                                        {{'settings.maximum_100_characters_are_allowed' | translate}}</div>
                                                </div>

                                                <div *ngIf="(f.passwords.controls.confirm_password.touched || submitted) && f.passwords.controls.confirm_password.errors?.pattern "
                                                class="invalid-feedback">
                                                <div *ngIf="f.passwords.controls.confirm_password.errors?.pattern">
                                                    {{'faq.white_space'| translate}}</div>
                                            </div>

                                                <!-- <div *ngIf="(f.passwords.controls.confirm_password.touched || submitted) && (f.passwords.errors?.areEqual)"
                                                    class="invalid-feedback">{{'settings.passwords_do_not_match' | translate}}
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-3">
                                            <button type="submit" class="btn_cls">{{'settings.submit' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                              
                            
                            </form>
                         
                        
                   
               </div>
               </div>
                </div>
               
            </div>
        </section>

        </div>
        </div>