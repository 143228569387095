import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HomeService {
    translate_value: any;
    constructor(private http: HttpClient,
        private translate: TranslateService) { }

    
        save(event_date:any){
            const formData: FormData = new FormData();
            formData.append('event_date', event_date);
            return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/createevent`, formData).pipe(map(user => {
                return user;
            }));
        }
    
        update(id:any,event_date:any){
            const formData: FormData = new FormData();
            formData.append('event_date', event_date);
            return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/updateevent/` + id, formData).pipe(map(user => {
                return user;
            }));
        }

        getEvent(): Observable<any> {
            return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/getevent`).pipe(map(user => user));
    
        }


    get(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard`).pipe(map(user => user));
    }

    getMerachant(search:any,page:any): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/get_merchant?search=`+search+'&page='+page).pipe(map(user => user));
    }
    getmsgNotification(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getChatcount`).pipe(map(user => user));
    }

    getcontactNotification(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getContactcount`).pipe(map(user => user));
    }

    status_update(id: string, status: any) {
        const formData: FormData = new FormData();
       
       
        formData.append('status', status);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/contact-status/`+ id, formData).pipe(map(data => {
            return data;
        }));

    }


    getContact(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getcontact?search=`+search+'&page='+page).pipe(map(user => user));

    }

    getDashboard(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard`).pipe(map(user => user));
    }

    getTranslate(key) {
        this.translate.get(key).subscribe(tra => {
            this.translate_value = tra;
        });

        return this.translate_value;
    }

    
    loginStatus(userId: string, login_time: any,logout_time:any) {
        const formData: FormData = new FormData();
        formData.append('login_time', login_time);
        formData.append('logout_time', logout_time);
        formData.append('userId', userId);
        console.log(formData)
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/time`, formData).pipe(map(data => {
            return data;
        }));
    }

    loginupdateStatus(userId: string, login_time: any,logout_time:any) {
        const formData: FormData = new FormData();
        formData.append('login_time', login_time);
        formData.append('logout_time', logout_time);
        formData.append('userId', userId);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/updatetime/` + userId, formData).pipe(map(data => {
            return data;
        }));
    }


    getLogin(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/gettime?search=`+search+'&page='+page).pipe(map(user => user));
    }

}
