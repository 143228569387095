import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class FaqService {
    constructor(private http: HttpClient) { }



    save(title:any,description:any){
        const formData: FormData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
       
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/faq`, formData).pipe(map(user => {
            return user;
        }));
    }

    update(id:any,title:any,description:any){
        const formData: FormData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/faq/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/faq/` + id).pipe(map(user => {
            return user;
        }));

    }
    getFaq(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/faq?search=`+search+'&page='+page).pipe(map(user => user));

    }

    getSingleFaq(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/faq/` + id).pipe(map(user => user));

    }

    getUsers(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-user?page=`+ page + '&search=' + search).pipe(map(user => user));

    }




}
