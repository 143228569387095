﻿import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SiteHeaderComponent } from './_layouts/site-header/site-header.component';
import { routing } from './app.routing';

import { RouterModule } from '@angular/router';
// import { HttpModule } from '@angular/http';
import { ModalComponent } from './_directives';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
// tslint:disable-next-line: max-line-length
import { AlertService, AuthenticationService, HomeService, ModalService, RoleService, SettingsService, FaqService, NewsService, AppsettingsService, RoomService, BannerService, BlogService, MessageService, DownloadService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { DataTablesModule } from 'angular-datatables';
import { UserComponent } from './users'
import { BannerComponent } from './banner/banner.component';

import { LSelect2Module } from 'ngx-select2';
// import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPrintModule } from 'ngx-print';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';


import { NgxPaginationModule } from 'ngx-pagination';;

import { ContactComponent } from './contact/contact.component';
import { CreatefaqComponent } from './createfaq/createfaq.component';
import { CreatenewsComponent } from './createnews/createnews.component';
import { SiteFooterComponent } from './_layouts/site-footer/site-footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';;

import { SettingsComponent } from './settings/settings.component';
import { FaqComponent } from './faq/faq.component';;
import { NewsComponent } from './news/news.component';
import { UserverificationComponent } from './user-verification/user-verification.component';;
import { UserDetailsComponent } from './user-details/user-details.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';;
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EditTermsComponent } from './edit-terms/edit-terms.component';
import { EditPrivacypolicyComponent } from './edit-privacypolicy/edit-privacypolicy.component';
import { RoomsComponent } from './rooms/rooms.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EmbedVideo } from 'ngx-embed-video';
import { I18nModule } from './i18n/i18n.module';
import { EventconfigComponent } from './eventconfig/eventconfig.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { BlogcreateComponent } from './blogcreate/blogcreate.component';
import { BannercreateComponent } from './bannercreate/bannercreate.component';
import { SocketService } from './chat/shared/services/socket.service';
import { RightsidebannercreateComponent } from './rightsidebannercreate/rightsidebannercreate.component';
import { RightsidebannerComponent } from './rightsidebanner/rightsidebanner.component';
import { SAVER, getSaver } from './_services/saver.provider'
// import 'hammerjs';
import { BlogComponent } from './blog/blog.component';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { ChatComponent } from './chat/chat.component';
// import { NgxEmojModule } from 'ngx-emoj';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
// import {TimeAgoPipe} from 'time-ago-pipe';
import { TimeAgoPipe } from '../app/pipes/custom.pipe';
import { VideoProcessingService } from './_services/video-processing-service';
import { HistoryComponent } from './history/history.component';
@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        routing,
        //TimeAgoPipe,
        DataTablesModule,
        LSelect2Module,
        // SelectDropDownModule,
        NgxPrintModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        AngularEditorModule,
        I18nModule,
        PickerModule,
        InfiniteScrollModule,
        NgxLinkifyjsModule.forRoot(),
        //  NgxEmojModule,
        AngularMyDatePickerModule,
        ScrollToModule.forRoot(),
        //  TranslateHttpLoader,
        //  TranslateModule.forRoot(),
        EmbedVideo.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circleSwish,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        })
    ],
    declarations: [
        AppComponent,
        ModalComponent,
        HomeComponent,
        LoginComponent,
        SiteHeaderComponent,
        SettingsComponent,
        FaqComponent,
        UserComponent,
        EventconfigComponent,
        SiteFooterComponent,
        ForgotPasswordComponent,
        UserverificationComponent,
        CreatefaqComponent,
        CreatenewsComponent,
        NewsComponent,
        ContactComponent,
        UserDetailsComponent,
        RoomsComponent,
        AppSettingsComponent,
        EditTermsComponent,
        EditPrivacypolicyComponent,
        BlogcreateComponent,
        BannercreateComponent,
        BannerComponent,
        RightsidebannercreateComponent,
        RightsidebannerComponent,
        BlogComponent,
        ChatComponent,
        TimeAgoPipe,
        HistoryComponent,
    ],

    providers: [
        AuthSuperadminGuard,
        AuthVendorGuard,
        AlertService,
        AuthenticationService,
        SettingsService,
        HomeService,
        ModalService,
        RoleService,
        FaqService,
        NewsService,
        AppsettingsService,
        RoomService,
        BannerService,
        BlogService,
        SocketService,
        MessageService,
        VideoProcessingService,
        DownloadService,
        { provide: SAVER, useFactory: getSaver },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
