import { Component, OnInit, OnDestroy,ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { RoleService, AlertService,BannerService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import {IAngularMyDpOptions, IMyDateModel, AngularMyDatePickerDirective} from 'angular-mydatepicker';

import { TranslateService } from '@ngx-translate/core';

declare var jquery: any;
declare var $: any;
declare var alertify: any;



@Component({
  selector: 'app-rightsidebannercreate',
  templateUrl: './rightsidebannercreate.component.html',
  styleUrls: ['./rightsidebannercreate.component.css']
})
export class RightsidebannercreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dp', { static: false })myDp: AngularMyDatePickerDirective;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'
  };
 
  adminForm: FormGroup;
  dobdate:any;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;
  fileToUpload2: File = null;
  submitted = false;
  //table = false;
  cancelButton = false;
  users$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  // width_limit = `${environment.bannerWidth}`;
  // height_limit = `${environment.bannerHeight}`;
  media_domain = `${environment.media_domain}`;
  curPage = 1;
  perPage = 10;
profile: any;
id_proof: any;

id: any;
first_preview: any;
second_preview: any;
$singleUser:any;
width_limit:any;
height_limit:any;


image1: any;
image2: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannerSer: BannerService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    public home: HomeService
  ) { }

  ngOnInit() {

    this.width_limit =`${environment.imgbannerWidth}`;
    this.height_limit=  `${environment.imgbannerHeight}`;
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.adminForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      link: ['', [Validators.required,Validators.pattern(urlRegex)]],
      profile: [''],
     
    });


   
  this.route.queryParams.subscribe(params => {
    this.id = params['id'];
if(this.id){
    this.bannerSer.getSingleBanneradd(this.id).subscribe(data => {
     this.$singleUser = data.data;

     this.image1 = this.first_preview = this.$singleUser.image ? this.$singleUser.image : '';
     console.log(this.image1)
 console.log(this.image2)

     if(this.image1!=''){
      this.first_preview = this.media_domain + '' +this.$singleUser.image;
   }



 this.adminForm.patchValue({
  index: 1,
  _id: this.$singleUser._id,
  link: this.$singleUser.link
});
    })
  }
})
  }
  clear(e){
    
      this.image1 = null;
  
   
  }

 
  cancel(){
    this.router.navigate(['/rightsidebanner']);
  }


  fileChoose(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();
   
    reader.onload = function (e) {
      var img = new Image();
      img.onload = function () {
        console.log(img.width);

        var imgWidth = that.width_limit;
        var imgHeight = that.height_limit;
        console.log(imgWidth)
        console.log(that.width_limit)
        if (img.width == imgWidth && img.height == imgHeight) {
         

          that.first_preview=null;
          that.first_preview=img.src;
          that.image1 = files.item(0);
    
        } else {
        
          that.toastr.error(
            "写真サイズは " + that.width_limit + " × " + that.height_limit+" でお願いします。"
          );
          that.first_preview=null;
         that.first_preview="";
         that.image1 = null;
        
          $("#exampleInputEmail").val("");
         
        
        }
      };
      img.src = reader.result as string;
      that.first_preview = files.item(0);
    };

    reader.readAsDataURL(files.item(0));
    that.first_preview = files.item(0);
  }





  // convenience getter for easy access to form fields
  get f() { return this.adminForm.controls; }

  onSubmit(form, formWrap) {
    this.submitted = true;
    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;

  
   
    if (this.adminForm.invalid) {
      var that = this;
      that.toastr.error(that.home.getTranslate('faq_form.please_enter_all_fields'))
      return;
    }

 
  
    var that = this;
    if (!that.image1) {
     
      that.toastr.error(that.home.getTranslate('news_form.image_require'))
      return false;
      // that.adminForm.get('profile').setValidators([Validators.required]);
    }
    if (this.id != null) {
 
      
    alertify.confirm(that.home.getTranslate('banner_form.are_you_sure_you_want_to_update_this_banner?'), function () {
      that.bannerSer.updateadd(that.id,that.f.link.value,that.image1)
        .subscribe(
          data => {
            if (data.status.code == 0) {
           
              formWrap.reset();
           

              that.image1 = null;
            

            that.router.navigate(['/rightsidebanner']);

            } else {
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.toastr.error(data.status.message)
         
            }
          },
          error => {
            that.toastr.error(error);
            
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
          });

        }, function () { 
         // that.toastr.error(that.home.getTranslate('news_form.update')+''+ that.home.getTranslate('news_form.action_cancelled'))
        
        }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });
    } else {
  
    console.log(that.image1)
    alertify.confirm(that.home.getTranslate('banner_form.are_you_sure_you_want_to_create_this_banner?'), function () {
      that.bannerSer.saveadd(that.f.link.value,that.image1)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success(that.home.getTranslate('faq_form.successfully_added'));
               that.router.navigate(['/rightsidebanner']);
            
          

              that.submitted = false;
            

            } else {
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.toastr.error(data.status.message)
             
            }
          },
          error => {
            that.toastr.error(error)
           
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
          });
        }, function () { 
         // that.toastr.error(that.home.getTranslate('faq_form.create_action_cancelled'))
        
        
        }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });
    }
  

  }

  cancelSubmit(event) {
    this.imageExist = '';
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.adminForm.setValue({
      index: null,
      _id: null,
      title: '',
      profile: ''
    })
    this.submitted = false;
  }
 
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
