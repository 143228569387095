import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    CurrentTime: any;
    constructor(private http: HttpClient) { }

    login(email: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/login`, { email: email, password: password }).pipe(map(user => {
            // login successful if there's a jwt token in the response

            if (user.status.code == 0) {
                var currdate = new Date().getHours() + ':' + new Date().getMinutes();
                var dd = String(new Date().getDate()).padStart(2, '0');
                var mm = String(new Date().getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = new Date().getFullYear();
                var CurrentTime = yyyy + '年' + mm + '月' + dd + '日' + ' ' + new Date().getHours() + '時' + new Date().getMinutes() + '分';
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.username);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.status);
                localStorage.setItem(`${environment.appName}` + '_userInfo', JSON.stringify(user.data.user));
                localStorage.setItem(`${environment.appName}` + '_time', CurrentTime);
            }
            return user;
        }));
    }




    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        localStorage.removeItem(`${environment.appName}` + '_time');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_adminName');
        localStorage.removeItem(`${environment.appName}` + '_adminId');
        localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_status');
        localStorage.removeItem(`${environment.appName}` + '_userInfo');
       
    }
}
