<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'user_verification.user_verification' | translate}} </h1>
                      
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                      
                        <div class="row">
                            <div class="col-sm-4"><h4>{{'user_verification.list_of_users' | translate}}</h4></div>
                            <div class="col-sm-4"></div>
                          <div class="col-sm-4">
                                <div class="page_search">
                                <input (keyup)="search($event)"  class="form-control" type="text" name="custid" placeholder="{{'user_verification.search_for_a_name/email_id' | translate}}">
                                </div>
                            </div>  

                             
                        </div>
                       <br>
                       <div class="card">
                        <table cellspacing="0" id="table" class="table table-hover"
                      >
                    
                        <thead>
                            <tr>
                                <th>{{'user_verification.sl_no' | translate}}</th>
                                <th>{{'user_verification.name' | translate}}</th>
                                <th>{{'user_verification.email_id' | translate}}</th>
                                <th>{{'user_verification.user_verification' | translate}} </th>
                                <th width="10%">{{'user_verification.view' | translate}} </th>
                                <th>{{'faq.delete' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="users$?.length != 0">
                            <tr *ngFor="let user of users$; let i = index">
                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                               
                                <td *ngIf="user.name;else name">{{user.name}}</td>
                                <ng-template #name>
                                    <td>-</td>
                                </ng-template>
                             
                                <td *ngIf="user.email;else email">{{user.email}} </td>
                                <ng-template #email>
                                    <td>-</td>
                                </ng-template>
                                <td *ngIf="user.status == 0">
                                   
                                    <button class="accept_cls" (click)="changeStatus($event,user,1)" style="cursor: pointer;"><i class="far fa-check-circle"></i>

              &nbsp;  {{'user_verification.accept' | translate}} </button>
                                    <button class="reject_cls" (click)="changeStatus($event,user,-1)" style="cursor: pointer;"><i class="far fa-times-circle"></i> &nbsp;{{'user_verification.reject' | translate}} </button>
                                </td>
                                <td *ngIf="user.status == 1 || user.status == -1">
                                    <span *ngIf="user.status == 1">{{'user_verification.accepted' | translate}}</span>
                                    <span *ngIf="user.status == -1">{{'user_verification.rejected' | translate}}</span>
                                    <!-- <button class="accept_cls" *ngIf="user.status == 1">Accepted</button>
                                    <button class="reject_cls" *ngIf="user.status == -1">Rejected</button> -->
                                 </td> 
                                 <td style="cursor: pointer;"><a (click)="userDetails($event,user)"><i class="fas fa-eye"></i></a></td>
                                 <td> <div class="row">   
                                      <button type="submit" class="dlt_cls" (click)="deleteUser($event,user)"><i class="fas fa-trash-alt"></i>&nbsp;&nbsp;{{'faq.delete' | translate}}</button>
                              </div></td>
                                </tr>
                        </tbody>
                      
                    </table>
                    </div>
                    <div class="no_data"  *ngIf="users$ && !users$.length">{{'news.no_data_found' | translate}}</div>
                   
                    <div class="row paginate">
                        <div class="col-sm-8">
                        <div class="shloka_result" *ngIf="total != 0">
                            {{'login.total' | translate}} {{'users.users' | translate}} : {{total}} &nbsp;   {{'faq.showing' | translate}}  {{from}}-{{to}} &nbsp;
                            {{'faq.page' | translate}} {{curPage}} / {{last_page}}
        
                       </div>
                    </div>
                       <div class="col-sm-4" *ngIf="users$ && users$.length">
                        <div class="temple_pagination pg_cls">
                            <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)"><i
                                    class="fas fa-step-backward"></i></a> {{'user_verification.page' | translate}}
                            <span>{{curPage}}</span> {{'user_verification.of' | translate}} {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                class="forward" (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                        </div>
                    </div>
                    </div>
            
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>