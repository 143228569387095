<!-- <body class="hold-transition sidebar-mini layout-fixed sidebar-collapse">
    <div class="wrapper"> -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
    </ul>
<div class="filler"></div>
<select class="lang_cls" (change)="language($event)" style="cursor: pointer;">
    <option [disabled]="true">Select Language</option>
    <option value="English" [selected]="selected_langauge=='English'">English</option>
    <option  value="Japanese" [selected]="selected_langauge == 'Japanese'">Japanese</option>
</select>
</nav>

<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <div class="logo">
        <a href="/home"><img src="assets/images/ic_logo.png" class="img-responsive" alt="logo"></a>
    </div>
    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                    <a href="/home" class="nav-link" [ngClass]="{'active':routeParam=='/home' || routeParam=='/'}">
                        <i class="nav-icon fas fa-home"></i>
                        <p>
                            {{'dashboard.dashboard' | translate}}
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/faq" class="nav-link" [ngClass]="{'active':routeParam=='/create-faq' || routeParam=='/faq' || routeParam.includes('create-faq')}">
                        <i class="nav-icon fas fa-question-circle"></i>
                        <p>
                            {{'faq.faq' | translate}}
                        </p>
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a href="/news" class="nav-link" [ngClass]="{'active':routeParam=='/create-news' || routeParam=='/news' || routeParam.includes('create-news')}">
                        <i class="nav-icon fas fa-newspaper"></i>
                        <p>
                            {{'news.news' | translate}}
                        </p>
                    </a>
                </li> -->

                <li class="nav-item">
                    <a href="/contact" class="nav-link" [ngClass]="{'active':routeParam=='/contact'}">
                       
                        <i class="nav-icon fas fa-address-book"></i>
                        <span class="count_cls1" *ngIf="($count ? $count-(childMessage && childMessage['contactcount']>0 ? childMessage['contactcount'] : 0) : 0)>=0?($count ? $count-(childMessage && childMessage['contactcount']>0 ? childMessage['contactcount'] : 0) : 0):0">{{($count ? $count-(childMessage && childMessage['contactcount']>0 ? childMessage['contactcount'] : 0) : 0)>=0?($count ? $count-(childMessage && childMessage['contactcount']>0 ? childMessage['contactcount'] : 0) : 0):0}}</span> 
                        <p>
                            {{'contact.contact' | translate}}
                            <span class="count_cls" *ngIf="($count ? $count-(childMessage && childMessage['contactcount']>0 ? childMessage['contactcount'] : 0) : 0)>=0?($count ? $count-(childMessage && childMessage['contactcount']>0 ? childMessage['contactcount'] : 0) : 0):0">{{($count ? $count-(childMessage && childMessage['contactcount']>0 ? childMessage['contactcount'] : 0) : 0)>=0?($count ? $count-(childMessage && childMessage['contactcount']>0 ? childMessage['contactcount'] : 0) : 0):0}}</span> 
                        </p>
                    </a>
                </li>
            
                <li class="nav-item">
                    <a href="/banner" class="nav-link" [ngClass]="{'active':routeParam=='/create-banner' || routeParam=='/banner' || routeParam.includes('create-banner')}">
                        <i class="nav-icon fas fa-file"></i>
                        <p>
                           {{'banner.banner' |translate}}
                        </p>
                    </a>
                </li>

                <li class="nav-item">
                    <a href="/rightsidebanner" class="nav-link" [ngClass]="{'active':routeParam=='/create-rightsidebanner' || routeParam=='/rightsidebanner' || routeParam.includes('create-rightsidebanner')}">
                        <i class="nav-icon far fa-file"></i>
                        <p>
                           {{'banner.right_side' |translate}}
                        </p>
                    </a>
                </li>
             
                <li class="nav-item">
                    <a href="/blog" class="nav-link" [ngClass]="{'active':routeParam=='/create-blog' || routeParam=='/blog' || routeParam.includes('create-blog')}">
                        <i class="nav-icon fas fa-edit"></i>
                        <p>
                           {{'blog.blog' |translate}}
                        </p>
                    </a>
                </li>

               
                <li class="nav-item">
                    <a href="/users" class="nav-link" [ngClass]="{'active':routeParam=='/users'}">
                        <i class="nav-icon fas fa-users"></i>
                        <p>
                           {{'users.users' |translate}}
                        </p>
                    </a>
                </li> 

                <li class="nav-item">
                    <a href="/chat" class="nav-link" [ngClass]="{'active':routeParam=='/chat'}">
                        <i class="nav-icon fas fa-comment-alt"></i>
                        <span class="count_cls1" *ngIf="$counts">{{$counts}}</span> 
                        <!-- <span class="count_cls1" *ngIf="$counts">{{($counts ? $counts-(childMessage && childMessage['chatcount']>0 ? childMessage['chatcount'] : 0) : 0)>=0?($counts ?  $counts-(childMessage && childMessage['chatcount']>0 ? childMessage['chatcount'] : 0) : 0):0}}</span>  -->
                        <p>
                           {{'chat.chat' |translate}} 
                           <span class="count_cls" *ngIf="$counts">{{$counts}}</span> 
                           <!-- <span class="count_cls" *ngIf="$counts">{{($counts ? $counts-(childMessage && childMessage['chatcount']>0 ? childMessage['chatcount'] : 0) : 0)>=0?($counts ?  $counts-(childMessage && childMessage['chatcount']>0 ? childMessage['chatcount'] : 0) : 0):0}}</span>  -->
                        </p>
                    </a>
                </li> 

                <li class="nav-item" >
                    <a href="/app-settings" class="nav-link" [ngClass]="{'active':routeParam=='/app-settings' || routeParam=='/edit-terms' || routeParam=='/edit-privacy-policy' }">
                        <i class="nav-icon fas fa-cogs"></i> 
                        <p>
                     {{'app_settings.app_settings' | translate}}
                        </p>
                    </a>
                </li>
               
<!-- 
                <li class="nav-item">
                    <a href="/event-config" class="nav-link" [ngClass]="{'active':routeParam=='/event-config'}">
                        <i class="nav-icon fas fa-calendar-check"></i>
                        <p>
                            {{'event_configuration.config' | translate}}
                        </p>
                    </a>
                </li> -->

                <li class="nav-item">
                    <a href="/settings" class="nav-link" [ngClass]="{'active':routeParam=='/settings'}">
                        <i class="nav-icon fas fa-cog"></i>
                        <p>
                            {{'settings.settings' | translate}}
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/history" class="nav-link" [ngClass]="{'active':routeParam=='/history'}">
                        <i class="nav-icon fas fa-history"></i>
                        <p>
                            {{'chat.history' | translate}}
                        </p>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" (click)="logout($event)">
                        <i class="nav-icon fas fa-sign-out-alt"></i>
                        <p>
                            {{'logout.logout' | translate}}
                        </p>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>
<!-- </div></body> -->
