<site-header></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }

</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark hd_cls">{{'event_configuration.event_configuration' | translate}}</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="adminForm" #myForm="ngForm" (ngSubmit)="onSubmit(adminForm, myForm)"
                        class="">
                        <div class="row">
                          
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">{{'event_configuration.date' | translate}}</label>
                                  
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy/mm/dd" angular-mydatepicker name="reactiveFormsDate" formControlName="event_date"   (dateChanged)="onDateChanged($event)"
                                        [options]="myDatePickerOptions" #dp="angular-mydatepicker" autocomplete="off" (click)="toggleCalendar()" readonly/>
                                        <div class="input-group-append">
                                          <button type="button" class="btn btn-secondary cal" *ngIf="dobdate" (click)="dp.clearDate()">
                                            <i class="far fa-window-close"></i>
                                          </button>
                                        </div>
                                        <div class="input-group-append">
                                          <button type="button" class="btn btn-secondary cal" (click)="dp.toggleCalendar()">
                                            <i class="fas fa-calendar-week"></i>
                                          </button>
                                        </div> 
                                      </div>
                                  
                                <div *ngIf="(f.event_date.touched || submitted) && f.event_date.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.event_date.errors?.required">{{'event_configuration.date' | translate}} {{'faq_form.is_required' | translate}}</div>
                                </div>
                              
                                </div>
                            </div>
                          
                        </div>
                      
                       
                        <div class="row mt-4">
                            <div class="col-md-4">
                                <button type="submit" class="btn_cls" *ngIf="$singleUser && !$singleUser._id">{{'faq_form.create' | translate}}</button>
                                <button type="submit" class="btn_cls" *ngIf="$singleUser && $singleUser._id">{{'faq_form.update' | translate}}</button>
                            </div>
                            <!-- <div class="col-md-4">
                                <button type="submit" class="btn_white" (click)="cancel()">{{'faq_form.cancel' | translate}}</button>
                            </div> -->
                        </div>
  

        </form>
        </div>
        </div>
    </div>
</div>

</section>
<script src="/assets/js/bootstrap-table.js"></script>

<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }

</style>
