import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
@Injectable()
export class AuthSuperadminGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            // logged in so return true
          
            return true;
        }

        // not logged in so redirect to login page with the return url
     
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        location.href = "/login?returnUrl=" + state.url;
        return false;
    }
}


