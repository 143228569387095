import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, HomeService,RoleService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UserComponent implements OnInit, AfterViewInit {

  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  sel_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob: any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  user_details: any;
  curPage = 1;
  perPage = 10;
  disp_type:any;
  last_page: any;
  total: any;
  from:any;
  to:any;
  title:any;
  searchUser: any;
  apiUrl="";
  limit:any;
  pageCounter:any;
  topage:any;
  page:any;


  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private home: HomeService,
    private role: RoleService
  ) {
  }





  ngOnInit() {

    const that = this;

// that.loading = true;

  this.apiUrl = `${environment.apiUrl}`;
    this.dtOptions = {
    
      pagingType: 'full_numbers',
      processing: true,
       pageLength: 10,
      language: {
        searchPlaceholder: "Name/Email/Mobile No."
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
    };


    this.role.getUsersDetails('','').subscribe(data => {
   
      this.users$ = data.data.users.docs;
      this.curPage = data.data.users.page;
      this.total= data.data.users.totalDocs;
      this.from = data.data.users.prevPage;
      this.to = data.data.users.nextPage;
      this.last_page = data.data.users.totalPages;
      this.limit = data.data.users.limit;
      this.page = data.data.users.page;
      this.pageCounter = data.data.users.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.users$.length;
      
      return false;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

    // if(this.users$ && this.users$.room_details == 1){
    //   this.title = "";
    //   this.router.navigate(['/rooms'], { queryParams: {room_no: this.users$ && this.users$.room_no , id: this.users$ && this.users$._id}})
    // }else{
     
    //   this.title = this.home.getTranslate('login.room_details_not_available');
    // }
   

  }
 
  chatUser(e,user){
    this.router.navigate(['/chat'], { queryParams: {id: user._id}});
  }
  search(e){
    
    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }
 
    this.role.getUsersDetails(e.target.value,1).subscribe(data => {
      this.users$ = data.data.users.docs;
      this.curPage = data.data.users.page;
      this.total= data.data.users.totalDocs;
      this.from = data.data.users.prevPage;
      this.to = data.data.users.nextPage;
      this.last_page = data.data.users.totalPages;
      this.limit = data.data.users.limit;
      this.page = data.data.users.page;
      this.pageCounter = data.data.users.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.users$.length;
  })

}

pageChange(e){

  this.role.getUsersDetails('',e).subscribe(data => {
    this.users$ = data.data.users.docs;
    this.curPage = data.data.users.page;
    this.total= data.data.users.totalDocs;
    this.from = data.data.users.prevPage;
    this.to = data.data.users.nextPage;
    this.last_page = data.data.users.totalPages;
    this.limit = data.data.users.limit;
    this.page = data.data.users.page;
    this.pageCounter = data.data.users.pagingCounter;
    this.topage = (this.limit * (this.page - 1)) + this.users$.length;
    this.loading = false;
  }, error => {
    this.loading = false;
  });
}

roomDetails(e,user){
  e.stopPropagation();
  if(user.room_details == 1){
    this.title = "";
    this.router.navigate(['/rooms'], { queryParams: {room_no: user.room_no , id: user._id}})
  }else{
    var that = this;
    that.title = that.home.getTranslate('login.room_details_not_available');
  }
  
}


userDetails(e,user){
   this.router.navigate(['/user-details'], { queryParams: {id: user._id}});
}

deleteUser(e,user){
  var that = this;
  alertify.confirm(that.home.getTranslate('users.are_you_sure_you_want_to_delete_this_user?'), function () {
    that.role.delete(user._id)
      .subscribe(
        data => {
          if (data.status.code == 0) {
            that.toastr.success(that.home.getTranslate('faq.deleted_successfully'))
          
            that.role.getUsersDetails('',that.curPage).subscribe(data => {
              that.users$ = data.data.users.docs;
              that.curPage = data.data.users.page;
              that.total= data.data.users.totalDocs;
              that.from = data.data.users.prevPage;
              that.to = data.data.users.nextPage;
              that.last_page = data.data.users.totalPages;
              that.limit = data.data.users.limit;
              that.page = data.data.users.page;
              that.pageCounter = data.data.users.pagingCounter;
              that.topage = (that.limit * (that.page - 1)) + that.users$.length;
            
            },  error => {
              console.error(error);
            });
       
         
          } else {
            that.toastr.error(data.status.message)
          
          }
        },
        error => {
          that.toastr.error(error)
         
        });
  }
    , function () {
      // that.toastr.error('Delete action cancelled')
    }).set('labels', { cancel: that.home.getTranslate('faq.cancel'), ok: that.home.getTranslate('faq.ok') });

}
  
changeStatus(e,user,type){
  var txt = '';
    if(type == 1){
      this.disp_type = 1;
      txt = 'accept';
    }else if(type == -1){
      this.disp_type = -1;
      txt = 'reject';
    }

      var that = this;
    alertify.confirm('Are you sure you want to ' + txt + ' this user request?', function () {
      that.role.status_update(user._id,that.disp_type)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Updated Successfully')
    
              that.role.getUsersDetails('','').subscribe(data => {
  
                that.users$ = data.data.users.data;
                that.curPage = data.data.users.current_page;
                that.total= data.data.users.total;
                that.from = data.data.users.from;
                that.to = data.data.users.to;
                that.last_page = data.data.users.last_page;
                that.dtTrigger.next();
          }, error => {
            console.error(error);
          });
           
            } else {
              that.toastr.error(data.status.message)
            
            }
          },
          error => {
            that.toastr.error(error)
           
          });
    }
      , function () {
      
      });
  }

 

  ngAfterViewInit() {}
   
}
