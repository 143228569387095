<site-header></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }

</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'banner_form.banner' | translate}}</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="adminForm" #myForm="ngForm" (ngSubmit)="onSubmit(adminForm, myForm)"
                        class="">   
                        <div class="row">
                          
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">{{'banner_form.link' | translate}}</label>
                                    <input autocomplete="off" type="text" class="form-control"
                                    formControlName="link"
                                    [ngClass]="{ 'is-invalid': (f.link.touched || submitted) && f.link.errors }"
                                    >
                                <div *ngIf="(f.link.touched || submitted) && f.link.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.link.errors?.required">{{'banner_form.link_is_required' | translate}}</div>
                                </div>
                                <div *ngIf="(f.link.touched || submitted) && f.link.errors?.pattern"
                                class="invalid-feedback">
                                <div *ngIf="f.link.errors?.pattern">{{'banner_form.link_pattern' | translate}}
                                </div>
                            </div>
                                </div>
                            </div>
                          
                        </div>
                       
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">{{'news_form.image' | translate}}</label>
                                    <div class="upload-btn-wrapper">
                                        <button class="btn">
                                        
                    
                                          <img class="img-responsive" src="{{first_preview}}" 
                                           name="image" *ngIf="image1"> 
                                           <span class="txt_cls" *ngIf="!image1">{{'news_form.upload' | translate}}</span>
                                        </button>
                                     
                                        <input name="upload" type="file" id="exampleInputEmail1" formControlName="profile" accept="image/*"
                                        (change)="fileChoose($event,$event.target.files)" />
                                      
                                        <a (click)="clear($event)" *ngIf="image1"><i class="fas fa-times-circle"></i></a>
                                        
                                    </div>
                                </div>
                            </div>
                           
                           
                        </div>

                        <div class="row">
                            <div class="error_cls">ノート：写真サイズは260×569でお願いします。</div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-3">
                                <button type="submit" class="btn_cls" *ngIf="!id">{{'news_form.create' | translate}}</button>
                                <button type="submit" class="btn_cls" *ngIf="id">{{'news_form.update' | translate}}</button>
                            </div>
                            <div class="col-md-3">
                                <button type="submit" class="btn_white" (click)="cancel()">{{'news_form.cancel' | translate}}</button>
                            </div>
                        </div>
           </form>
        </div>
        </div>
    </div>
</div>
</section>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }

</style>
