<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="wrapper">
    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'faq.faq' | translate}}</h1>
                    </div>
                </div>
            </div>
        </div>
        

        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                     
                     
                        <div class="row">
                            
                            <div class="col-sm-6">
                                <div class="page_search">
                                <input (keyup)="search($event)"  class="form-control" type="text" name="custid" placeholder="{{'faq.search_for_a_faq' | translate}}" autocomplete="off">
                                </div>
                            </div> 
                            <div class="col-sm-6">
                                <button type="submit" class="btn_cls" (click)="add($event)">{{'faq.add_faq' | translate}}</button>
                              
                            </div>    
                            
                           
                            
                           
                        

                             
                        </div>
                       <br>
                       <div class="card">
                        <table cellspacing="0" id="table" class="table table-hover"
                       >
                      
                        <thead>
                            <tr>
                                <th width="10%">{{'faq.sl_no' | translate}}</th>
                                <th width="20%">{{'faq.title' | translate}}</th>
                                <th width="45%">{{'faq.description' | translate}}</th>
                                <th width="25%">{{'faq.action' | translate}}</th>
                               
                            </tr>
                        </thead>
                        <tbody *ngIf="faq$?.length != 0">
                            <tr *ngFor="let user of faq$; let i = index">
                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                              
                          <td *ngIf="user.title;else mapId" class="cap_cls"><span  class="desc_cls">{{user.title}}</span></td>
                                <ng-template #mapId>
                                    <td>-</td>
                                </ng-template>
                                
                                <td *ngIf="user.description;else email" class="cap_cls"><span class="desc_cls">{{user.description}}</span> </td>
                                <ng-template #email>
                                    <td>-</td>
                                </ng-template>
                              
                              <td> 
                                <div class="row">
                                   
                                   
                                        <button type="submit" class="edit_cls" (click)="editFaq($event,user)"><i class="fas fa-edit"></i>&nbsp;&nbsp;{{'faq.edit' | translate}}</button>
                                 
                                      
                                
                                          
                                     
                                        <button type="submit" class="dlt_cls" (click)="deleteFaq($event,user)"><i class="fas fa-trash-alt"></i>&nbsp;&nbsp;{{'faq.delete' | translate}}</button>
                                   
                                  
                                </div>
                                
                            </td>
                              
                            </tr>
                        </tbody>
                       
                    </table>
                    </div>
                    <div class="no_data"  *ngIf="faq$ && !faq$.length">{{'faq.no_data_found' | translate}}</div>
                             
                    <div class="row paginate">
                        <div class="col-sm-8">
                        <div class="shloka_result" *ngIf="total != 0">
                        {{'login.total' | translate}} {{'faq.faq' | translate}} : {{total}} &nbsp;   {{'faq.showing' | translate}}  {{pageCounter}}-{{topage}} &nbsp;
 {{'faq.page' | translate}} {{curPage}} / {{last_page}}
                    

</div>
                    </div>
                       <div class="col-sm-4" *ngIf="faq$ && faq$.length">
                        <div class="temple_pagination pg_cls">
                            <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)" style="cursor: pointer;"><i
                                    class="fas fa-step-backward"></i></a> {{'faq.page' | translate}}
                            <span>{{curPage}}</span> {{'faq.of' | translate}} {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                class="forward" (click)="pageChange(curPage+1)" style="cursor: pointer;"><i class="fas fa-step-forward"></i></a>
                        </div>
                    </div>
                    </div>

                    </div>
                </div>
            </div>
        </section>

        
    </div>
</div>


