<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }
</style>
<div class="wrapper">
    <div class="content-wrapper">

        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark">{{'users.users' | translate}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="content dashboard_box">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">


                        <div class="row">


                            <div class="col-sm-5">
                                <h4>{{'users.list_of_users' | translate}}</h4>
                            </div>
                            <div class="col-sm-3"></div>
                            <div class="col-sm-4">
                                <div class="page_search">
                                    <input (keyup)="search($event)" class="form-control" type="text" name="custid"
                                        placeholder="{{'user_verification.search_for_a_name' | translate}}">
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="card">
                            <table cellspacing="0" id="table" class="table table-hover">
                                <thead>
                                    <tr>
                                        <th width="10%">{{'user_verification.sl_no' | translate}}</th>
                                        <th width="15%">{{'users.name' | translate}}</th>
                                        <th width="30%">{{'room.address' | translate}}</th>
                                        <th width="15%">{{'user_details.email' | translate}}</th>
                                        <th width="10%">{{'faq.delete' | translate}} </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="users$?.length != 0">
                                    <tr *ngFor="let user of users$; let i = index">
                                        <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                        <td class="cap_cls"><span [innerHtml]="user.name ? user.name:''" class="desc_cls1"></span></td>
                                        <td><span class="desc_cls1">{{user.postal_code}} {{user.prefectures}}
                                                {{user.city}} {{user.country}}</span></td>
                                        <td>{{user.email ? user.email : '-'}}</td>
                                        <td>
                                            <div class="row">
                                                <button type="submit" class="dlt_cls"
                                                    (click)="deleteUser($event,user)"><i
                                                        class="fas fa-trash-alt"></i>&nbsp;&nbsp;{{'faq.delete' |
                                                    translate}}</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div class="no_data" *ngIf="users$ && !users$.length">{{'news.no_data_found' | translate}}</div>

                        <div class="row paginate">
                            <div class="col-sm-8">
                                <div class="shloka_result" *ngIf="total != 0">
                                    {{'login.total' | translate}} {{'users.users' | translate}} : {{total}} &nbsp;
                                    {{'news.showing' | translate}} {{pageCounter}}-{{topage}} &nbsp; {{'news.page' |
                                    translate}} {{curPage}} / {{last_page}}
                                </div>
                            </div>
                            <div class="col-sm-4" *ngIf="users$ && users$.length">
                                <div class="temple_pagination pg_cls">
                                    <a class="backward" [ngClass]="{'disable':curPage==1}"
                                        (click)="pageChange(curPage-1)" style="cursor: pointer;"><i class="fas fa-step-backward"></i></a>
                                    {{'news.page' | translate}}
                                    <span>{{curPage}}</span> {{'news.of' | translate}} {{last_page}}<a
                                        [ngClass]="{'disable':curPage==last_page}" class="forward"
                                        (click)="pageChange(curPage+1)" style="cursor: pointer;"><i class="fas fa-step-forward"></i></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</div>