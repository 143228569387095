import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, HomeService,AppsettingsService } from '../_services';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
// import {VisheshamService} from '../_services/vishesham.service';
import { DatePipe, formatDate } from '@angular/common';
//import * as alertify from 'alertify.js';//import
import { ViewChild } from '@angular/core';
// import { GoogleMap } from '@angular/google-maps'

import { AngularEditorConfig } from '@kolkov/angular-editor';
// import {LanguageService} from '../_services/language.service';
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// import sanitizeHtml from 'sanitize-html';
// import htmlToPdf from 'html2pdf.js';
// import * as _html2canvas from "html2canvas";
// const html2canvas: any = _html2canvas;

// import  jspdf from 'jspdf';  
  
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jquery: any;
declare var $: any;
declare var alertify: any;
@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.css']
})
export class AppSettingsComponent implements OnInit {
 terms:any;
 privacy:any;
 Language$:any;
 for_temple: any;
 social:any;
 status1:any;
 status2:any;
//  website = `${environment.website}`;
policy:any;
m=false;


 term:any;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private homeService: HomeService,
    // private VisheshamService: VisheshamService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private AppsettingsService:AppsettingsService,
    // private LanguageService:LanguageService,
  ) { }

  ngOnInit() {

    this.AppsettingsService.getAppSettings().subscribe(data => {
      console.log(data.data.terms);
      this.terms=data.data[0];
      this.privacy=data.data[1];

      document.getElementById("description").innerHTML = this.terms && this.terms.content;
      document.getElementById("privacy").innerHTML = this.privacy && this.privacy.content;

      this.term= this.terms && this.terms.content;
      this.policy=this.privacy && this.privacy.content;


      if(!this.term){
        $('#more').hide();
        $('#less').hide();
      }
     
      

  })


  // this.LanguageService.getLanguage().subscribe(data => {
  //   console.log(data);
  //   this.Language$ = data.data.languages;

  // });

  }

  view(e){
 
    $('.temple_drp_menu').css('display','none');
    $('.temple_drp').toggle();
    $(document).click(function(e){
     if(!$(e.target).closest('.temple_drp_btn, .temple_drp_menu').length){
     $('.temple_drp_menu').hide();
     }
   })
  }



  

  ngAfterViewInit() {

    // $(".tempgrp_drp_btn").click(function (e) {
    //   $(".tempgrp_drp_menu").toggle();
    //   e.preventDefault();
    // });
    // $(document).click(function (e) {
    //   if (!$(e.target).closest('.tempgrp_drp_btn, .tempgrp_drp_menu').length) {
    //     $(".tempgrp_drp_menu").hide();
    //   }
    // });


    $(".bhakt_drp_btn").click(function (e) {
      $(".bhakt_drp_menu").toggle();
      e.preventDefault();
    });
    $(document).click(function (e) {
      if (!$(e.target).closest('.bhakt_drp_btn, .bhakt_drp_menu').length) {
        $(".bhakt_drp_menu").hide();
      }
    });


    // $(".share_btn").click(function (e) {
    //   $(".share_menu").toggle();
    //   e.preventDefault();
    // });
    // $(document).click(function (e) {
    //   if (!$(e.target).closest('.share_btn, .share_menu').length) {
    //     $(".share_menu").hide();
    //   }
    // });


    $(".user_info_btn").click(function (e) {
      $(".user_info_menu").toggle();
      e.preventDefault();
    });
    $(document).click(function (e) {
      if (!$(e.target).closest('.user_info_btn, .user_info_menu').length) {
        $(".user_info_menu").hide();
      }
    });


    $(".user1_info_btn").click(function (e) {
      $(".user1_info_menu").toggle();
      e.preventDefault();
    });
    $(document).click(function (e) {
      if (!$(e.target).closest('.user1_info_btn, .user1_info_menu').length) {
        $(".user1_info_menu").hide();
      }
    });


    // var h = $('div#description')[0].scrollHeight;


$('#more').click(function(e) {
   e.stopPropagation();
   $('div#description').animate({
      'height': '100%'
   })
   $('#more').hide();
   $('#less').show();
});

$('#less').click(function(e) {

    $('#more').show();
    $('#less').hide();
});

$('#less').click(function() {
    $('div#description').animate({
        'height': '50px'
    })
})
$('#less').ready(function(){
     $('#less').hide();
})



// var h = $('div#privacy')[0].scrollHeight;


$('#more1').click(function(e) {
   e.stopPropagation();
   $('div#privacy').animate({
      'height': '100%'
   })
   $('#more1').hide();
   $('#less1').show();
});

$('#less1').click(function(e) {

    $('#more1').show();
    $('#less1').hide();
});

$('#less1').click(function() {
    $('div#privacy').animate({
        'height': '50px'
    })
})
$('#less1').ready(function(){
     $('#less1').hide();
})


    


  }
  changeStatus(event,user,type){

    var that = this;
  
    var status = this.status1 == 1 ? 0 : 1
    console.log(this.status1)
    var status1 = this.status2 == 1 ? 0 : 1
    console.log(this.status2)
   //that.users$[i] = 1
   //console.log(user._id)
  // that.loading = true;
   if(type == 'facebook'){
   var status_txt ='';
   status==0?status_txt='off':status_txt='on';
  
   alertify.confirm('Status Change','Are you sure you want to '+ status_txt +' this record?', function () {
    that.AppsettingsService.update(status,type).subscribe(data => {
    
      location.reload();
    //  that.loading = true;
      }, error => {
        console.error(error);
      //  that.loading = true;
      });
    
   }, function () {
          that.toastr.error('Action cancelled')
          location.reload();
         // that.loading = true;
        });
    }
  
    if(type == 'twitter'){
   var status_txt ='';
   status1==0?status_txt='off':status_txt='on';
  
   alertify.confirm('Status Change','Are you sure you want to '+ status_txt +' this record?', function () {
    that.AppsettingsService.update(status1,type).subscribe(data => {
    
     location.reload();
    //  that.loading = true;
      }, error => {
        console.error(error);
      //  that.loading = true;
      });
    
   }, function () {
          that.toastr.error('Action cancelled')
          location.reload();
         // that.loading = true;
        });
    }
  
  }
  shareStoryToggle(e) {
    $('.extra_share').css('display', 'none');
    e.stopPropagation();
    
    $('.share_menu').toggle();
  
  }


 


}
